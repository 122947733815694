import React, { useCallback, useEffect, useMemo, useState, useReducer } from 'react';
import './DevProjectScope.scss'
import DevProjectScopeVersion from "./devProjectScopeVersion/DevProjectScopeVersion";
import DevProjectIssue from "../devProjectIssue";
import { Popover, Tabs } from 'antd';
import DevProjectReq from "../devProjectReq";
import Icon from "../../../../../components/Icon";
import _ from 'lodash'
import clsx from "clsx";
import convertOptions from "../../../../common/ConvertOptions";
import { isNil } from "../../../../appraise/components/method";
import DevProjectDefect from "../devProjectDefect";
import { useGet, usePost } from 'rootnet-biz/lib/hooks';
import DevProjectScopeAllInfo from './devProjectScopeAllInfo';
import { strParams } from '../../../../../utils/publicFun';

const PROJECT_CHANGE_REQUEST_URL = '/projectAuditLog/getProjectAuditLogs'

const tabOptions = [
    {
        value: 'all',
        label: '所有',
        text: '所有',
    },
    {
        value: 'req',
        label: '需求',
        text: '需求',
    },
    {
        value: 'issue',
        label: 'ISSUE',
        text: 'ISSUE',
    },
    {
        value: 'baseLineDefect',
        label: 'baseLineDefect',
        text: '缺陷',
    },
]

// 0 进行中，1 关闭， 2 基线设定，3 暂缓，4 需求基线，5 ISSUE基线，6 需求基线和ISSUE基线，7 作废，
// ['0', '2', '4', '5', '6']  // 有导入 有移除
// ['3', '7'] // 无导入 有移除
// ['1'] // 无导入 无移除

function DevProjectScope(props) {
    const { currentId, updateDynamic, versionStatusOptions, addVersionAuth, selectedVersionItem, setSelectedVersionItem, refreshVersionList,
        projectMap, versionListRes, versionLoading, typeOpt, natureOpt, scaleOpt, customerSystemOpt, allUserRes,
        isPo, isPMM, isCM, isShow, versionListRef, isRelevance, isDisassociation, isImportReq, isQA, activeModule, setActiveModule, initVersionDate } = props
    const [isTabPop, setIsTabPop] = useState(false)
    const [untreatedNum, setUntreatedNum] = useState(0)
    const { doFetch: getAllList, data: allList } = useGet()
    const { data, doFetch } = usePost()
    const [countNum, updateNum] = useReducer((x) => x + 1, 0)

    const refreshProject = useCallback(() => {
        if (_.isNil(currentId)) return
        doFetch(PROJECT_CHANGE_REQUEST_URL, { projectId: currentId })
            .then(res => {
                setUntreatedNum(_.size(_.get(res, 'unaudited', [])))
            })
    }, [doFetch, currentId])

    const refreshAllList = useCallback(() => {
        const searchAll = { factorViewSql: `(View_Project_AllTask.projectId = '${_.get(projectMap, 'projectId')}' or View_Project_AllTask.projectId2 = '${_.get(projectMap, 'projectId')}') and ISNULL(View_Project_AllTask.defectMark,'N')='Y'` }
        if (!_.isNil(_.get(projectMap, 'projectId'))) {
            getAllList(`/userProject/getAllTaskId?${strParams({ factorViewSql: _.get(searchAll, 'factorViewSql') })}`)
        }
    }, [getAllList, projectMap])

    useEffect(() => {
        refreshAllList()
    }, [refreshAllList])

    useEffect(() => {
        refreshProject()
    }, [refreshProject])

    const [selectedVersion, state] = useMemo(() => {
        return [_.get(selectedVersionItem, 'releaseID'), _.get(selectedVersionItem, 'state')]
    }, [selectedVersionItem])

    const isVersionState = useMemo(() => _.includes(['T', 'C'], state), [state])

    const isAll = useMemo(() => isNil(selectedVersion), [selectedVersion])
    const isNoPlanVersion = useMemo(() => selectedVersion === 'no-plan-version', [selectedVersion])
    const versionCheck = useMemo(() => (!isAll && !isNoPlanVersion), [isAll, isNoPlanVersion])

    const canRelate = useMemo(() => {
        return isPMM || isPo
    }, [isPMM, isPo])

    const tabItems = useMemo(() => {
        const versionList = versionListRef.current || []
        let allFilterSql = ''
        let versionFilterSql = ''
        const searchAll = { factorViewSql: `(View_Project_AllTask.projectId = '${_.get(projectMap, 'projectId')}' or View_Project_AllTask.projectId2 = '${_.get(projectMap, 'projectId')}') and (ISNULL(View_Project_AllTask.defectMark,'N')='Y' or ISNULL( View_Project_AllTask.defectMark2, 'N' ) = 'Y')` }
        let issueVersionFilterSql = `View_releaseInfo_projectid.projectid='${_.get(projectMap, 'projectId')}' or ISNULL(issue_devProject.userProjectId,'') = '${_.get(projectMap, 'projectId')}'`
        let defectFilterSql = ''
        if (_.isNil(selectedVersion)) {
            allFilterSql = _.get(searchAll, 'factorViewSql')
            defectFilterSql = `(View_defect_custSystems.fixProjectIds like '%${_.get(projectMap, 'projectId')}%' and ISNULL(testDefect_project.defectMark,'N')='Y')`
            // if (_.isEmpty(versionList)) {
            //     defectFilterSql = defectFilterSql + ')'
            // } else {
            //     let defectVersionSql = _.join(_.map(versionList, x => `(release_story.releaseId = '${x.releaseID}' and ISNULL(release_story.defectMark,'N')='Y')`), ' or ')
            //     defectFilterSql = defectFilterSql + ` or ` + defectVersionSql + ')'
            // }
        } else if (selectedVersion === 'no-plan-version') {
            const reqVersionListSql = _.join(_.map(versionList, x => ` View_Release_Story.releaseIds not like '%${x.releaseID}%'`), ' and ')
            const allVersionListSql = _.join(_.map(versionList, x => ` View_Project_AllTask.releaseIds not like '%${x.releaseID}%'`), ' and ')

            allFilterSql = `(((View_Project_AllTask.projectId = '${_.get(projectMap, 'projectId')}' or View_Project_AllTask.projectId2 = '${_.get(projectMap, 'projectId')}' ) ${_.isEmpty(versionList) ? '' : `and (${allVersionListSql} or View_Project_AllTask.releaseIds is null)`}) and (ISNULL(View_Project_AllTask.defectMark,'N')='Y' or ISNULL( View_Project_AllTask.defectMark2, 'N' ) = 'Y'))`

            versionFilterSql = `(View_Release_Story.releaseIds is null ` + (_.isEmpty(versionList) ? '' : `or (${reqVersionListSql})`) + ')'
            issueVersionFilterSql = `(ISNULL(issue_devProject.userProjectId,'') = '${_.get(projectMap, 'projectId')}'`
            defectFilterSql = `((testDefect_project.fixProjectId ='${_.get(projectMap, 'projectId')}'  and ISNULL(testDefect_project.defectMark,'N')='Y')`
            if (_.isEmpty(versionList)) {
                defectFilterSql = defectFilterSql + ')'
                versionFilterSql = ''
                issueVersionFilterSql = issueVersionFilterSql + ')'
            } else {
                const defectVersionSql = _.join(_.map(versionList, x => `( isnull(test_defect.fixVersion,'') not like  '%${x.releaseID}%' )`), ' AND ')
                defectFilterSql = defectFilterSql + ' AND ' + defectVersionSql + ')'
                const issueVersionListSql = _.join(_.map(versionList, x => `(','+ISNULL(View_Release_Story_Issue.releaseIds,'')+',' not like '%,${x.releaseID},%')`), ' and ')
                issueVersionFilterSql = issueVersionFilterSql + ` and ${issueVersionListSql} )`
            }
        } else if (!_.isNil(selectedVersion)) {
            versionFilterSql = `View_Release_Story.releaseIds like '%${selectedVersion}%'`
            issueVersionFilterSql = `View_Release_Story_Issue.releaseId = '${selectedVersion}'`
            defectFilterSql = `release_story.releaseId = '${selectedVersion}' and ISNULL(testDefect_project.defectMark,'N')='Y'`
            allFilterSql = `((View_Project_AllTask.projectId = '${_.get(projectMap, 'projectId')}' or View_Project_AllTask.projectId2 = '${_.get(projectMap, 'projectId')}' ) and View_Project_AllTask.releaseIds like '%${selectedVersion}%') and (ISNULL(View_Project_AllTask.defectMark,'N')='Y' or ISNULL( View_Project_AllTask.defectMark2, 'N' ) = 'Y')`
        }

        let issueRelateFactorViewSql = `((','+ISNULL(View_issue_custSystems.userProjectIds,'')+',' not like '%,${_.get(projectMap, 'projectId')},%'`
        if (_.isEmpty(versionList)) {
            issueRelateFactorViewSql = issueRelateFactorViewSql + `))`
        } else {
            const versionListSql = _.join(_.map(versionList, x => `(','+ISNULL(View_Release_Story.releaseIds,'')+',' not like '%,${x.releaseID},%')`), ' and ')
            issueRelateFactorViewSql = issueRelateFactorViewSql + ` and ${versionListSql} ))`
        }

        let defectRelateFactorViewSql = `(testDefect_project.fixProjectId ='${_.get(projectMap, 'projectId')}' and ISNULL(testDefect_project.defectMark,'N')='N')`
        // if (_.isEmpty(versionList)) {
        //     defectRelateFactorViewSql += ')'
        // } else {
        //     let versionSql = _.join(_.map(versionList, x => `('^'+ISNULL(test_defect.fixVersion,'')+'^' not like '%^${x.releaseID}^%')`), ' and ')
        //     defectRelateFactorViewSql = defectRelateFactorViewSql + ' and ' + versionSql + ')'
        // }
        if (!_.isNil(selectedVersion) && selectedVersion !== 'no-plan-version') {
            defectRelateFactorViewSql = `( ISNULL(test_defect.historyQuestion,'N')='Y' and isnull(test_defect.custId ,'') !=''  and release_story.releaseId ='${selectedVersion}' and ISNULL(release_story.defectMark,'N')='N')`
        }
        return [
            {
                key: 'all',
                label: '所有',
                children: <React.Fragment key={countNum}>
                    <DevProjectScopeAllInfo
                        id={_.get(projectMap, 'currentId')}
                        projectId={_.get(projectMap, 'projectId')}
                        canRelate={isShow && canRelate}
                        versionCanRelate={canRelate || isCM}
                        module={'baseLineDefect'}
                        versionList={_.get(versionListRes, 'releaseInfoList') || []}
                        isRelateProject={_.isNil(selectedVersion) || selectedVersion === 'no-plan-version' || selectedVersion === 'history-defect'}
                        {...{ isPo, isPMM, isCM, untreatedNum, refreshProject, projectChangeRequestData: data, currentId, isRelevance, isDisassociation, selectedVersion, selectedVersionItem, updateDynamic, issueRelateFactorViewSql, defectRelateFactorViewSql, allFilterSql, searchAll, allList, isImportReq, isQA, refreshAllList }}
                    />
                </React.Fragment>
            },
            {
                key: 'req',
                label: '需求',
                children: <React.Fragment key={countNum}>
                    <DevProjectReq projectId={_.get(projectMap, 'projectId')}
                        projectType='DEV' id={_.get(projectMap, 'currentId')}
                        FUNC_CODE={'3688'}
                        RELATION_FUNC_CODE={'36'}
                        versionCanRelate={canRelate || isCM}
                        isInvoke={true} canRelate={isShow && canRelate}
                        replaceParams={{ 'View_ReleaseInfo_story_project.LeafProjectId': isNil(_.get(projectMap, 'projectId')) ? 'empty' : _.get(projectMap, 'projectId') }}
                        {...{ versionFilterSql, isPo, isPMM, updateDynamic, isShow, isRelevance, isDisassociation, versionCheck, selectedVersion, isVersionState, searchAll, allList, isAll, isNoPlanVersion, versionList, refreshAllList }} />
                </React.Fragment>
            },
            {
                key: 'issue',
                label: 'ISSUE',
                children: <React.Fragment key={countNum}>
                    <DevProjectIssue projectId={_.get(projectMap, 'projectId')}
                        versionCanRelate={canRelate || isCM}
                        versionFilterSql={issueVersionFilterSql}
                        canRelate={isShow && canRelate}
                        {...{ projectMap, issueRelateFactorViewSql, isRelevance, isDisassociation, versionCheck, selectedVersion, isVersionState, searchAll, allList, isAll, isNoPlanVersion, versionList, refreshAllList }} />
                </React.Fragment>
            },
            {
                key: 'baseLineDefect',
                label: '缺陷',
                children: <React.Fragment key={countNum}>
                    <DevProjectDefect FUNC_CODE={'050403'} factorViewSql={defectFilterSql} isShowAdd={false} canRelate={isShow && canRelate} relateViewCode={'3612'} versionCanRelate={canRelate || isCM}
                        isRelateProject={_.isNil(selectedVersion) || selectedVersion === 'no-plan-version' || selectedVersion === 'history-defect'} module={'baseLineDefect'} projectId={currentId}
                        {...{ defectRelateFactorViewSql, selectedVersion, projectMap, selectedVersionItem, refreshVersionList, isRelevance, isDisassociation, versionCheck, isVersionState, searchAll, allList, isAll, isNoPlanVersion, versionList, refreshAllList }} />
                </React.Fragment>
            },
        ]
    }, [updateDynamic, selectedVersion, projectMap, versionListRef, isPo, isPMM, canRelate, isShow, selectedVersionItem, refreshVersionList, untreatedNum, refreshProject, data, currentId, isRelevance, isDisassociation, countNum, versionCheck, isVersionState, allList, isAll, isNoPlanVersion, versionListRes, isImportReq, isQA, isCM, refreshAllList])

    const TabSelect = useCallback(() => {
        return <div className={'dev-project-scope-view-selector-pop'}>
            {
                _.map(tabOptions, item => (
                    <div key={item.value} className={clsx('selector-item flex center-y', { 'active': activeModule === item.value })}
                        onClick={() => {
                            setActiveModule(item.value)
                            setIsTabPop(false)
                        }}
                    >
                        {item.text}
                    </div>
                ))
            }
        </div>
    }, [activeModule, setActiveModule])

    return <div className={'dev-project-scope flex'}>
        <DevProjectScopeVersion projectId={_.get(projectMap, 'projectId')} {...{
            versionLoading, selectedVersionItem, setSelectedVersionItem,
            refreshVersionList, versionListRes, versionStatusOptions, addVersionAuth, activeModule, typeOpt, natureOpt, scaleOpt, customerSystemOpt, allUserRes, isShow, isPo, isPMM, isCM, initVersionDate
        }} />
        <div className="dev-project-scope-view-area flex">
            <div className="dev-project-scope-view-selector-wrap flex center-y">
                <div className="selector-label">
                    类型：
                </div>
                <Popover content={<TabSelect />} trigger="click" placement="bottomLeft" open={isTabPop} onOpenChange={setIsTabPop}>
                    <div className={'dev-project-scope-view-selector flex center-y'}>
                        <div className="selector-text">
                            {
                                convertOptions(activeModule, tabOptions)
                            }
                        </div>
                        <Icon className={'more-icon'} name={'arrow_drop_down'} />
                    </div>
                </Popover>
            </div>
            <Tabs activeKey={activeModule} items={tabItems} onChange={v => {
                updateNum()
                setActiveModule(v)
            }} />
        </div>
    </div>
}

export default DevProjectScope;