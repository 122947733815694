import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Dateformat, GetTime } from 'rootnet-core/format'
import { hasData } from 'rootnet-core/system'
import { Messager } from 'rootnet-ui'
import Api from '../../../base/task'
import { useGet } from '../../../utils/hook'
import { strParams } from '../../../utils/publicFun';
import gd from '../../../base/global'
import Option from './controls/Option'
import List from './controls/List'
import _ from 'lodash'
import './WorkTime.scss'
import { dateFormat } from "rootnet-core/dateFormat";

const limitList = {
	serviceList: [2017, 3001],
	otherList: [10, 11, 17, 27, 76, 2011, 7600]
}

function WorkTime() {
	const userAccount = _.get(JSON.parse(sessionStorage.getItem('current_user')), 'operator_id')
	const [loading, setLoading] = useState(false)
	const [workWeek, setWorkWeek] = useState({ userId: _.get(gd, 'User.userMainId') })
	const [workCont, setWorkCont] = useState([])
	const [active, setActive] = useState(false)
	// eslint-disable-next-line
	const getWeekDate = useCallback(getWeekTime, [])
	const { data: userList, doFetch: getUserList } = useGet();
	const { data: globalParams, doFetch: getGlobalParams } = useGet();
	const { data: jobTypeListRes, doFetch: getJobTypeList } = useGet();
	const [isSelf, setIsSelf] = useState(true)
	const [searchUser, setSearchUser] = useState(userAccount)
	const { data: cutOffDateListRes } = useGet('/worktime/cutOffDate/query?cutOffDateFlag=Y')

	const jobTypeList = useMemo(() => {
		if (_.isEmpty(jobTypeListRes)) return []
		return _.filter(jobTypeListRes, item => item.sort !== '事务new')
	}, [jobTypeListRes])

	const [lastCutOffDate, nextCutOffDate, limitCutOffDate, beforeCutOffDate] = useMemo(() => {
		if (_.isEmpty(cutOffDateListRes)) return []
		const now = dateFormat('YYYY-MM-DD', new Date())
		const cutOffDateList = _.sortBy(_.map(cutOffDateListRes, x => x.jobdate))
		const lastCutOffDate = _.find(_.reverse([...cutOffDateList]), x => x < now)
		const beforeCutOffDate = _.find(_.reverse([...cutOffDateList]), x => x < lastCutOffDate)
		const nextCutOffDate = _.find(cutOffDateList, x => x >= now)
		const limitCutOffDate = _.get(_.find(cutOffDateListRes, x => x.jobdate === lastCutOffDate), 'cutoffday')
		return [lastCutOffDate, nextCutOffDate, limitCutOffDate, beforeCutOffDate]
	}, [cutOffDateListRes])

	const hasCostRate = useMemo(() => {
		const userAccount = _.get(JSON.parse(sessionStorage.getItem('current_user')), 'operator_id')
		const userItem = _.find(userList, x => _.toLower(x.userAccount) === _.toLower(userAccount))
		const userItemFactor = _.get(userItem, 'factor')
		return !(_.isNil(userItemFactor) || userItemFactor === 0)
	}, [userList])

	async function getWeekTime(options = {}) {
		let { beginDate = '', flag = '' } = options
		try {
			setLoading(true)
			const workWeek = await Api.get(`/worktime/plandate${flag && ('?' + strParams({ beginDate, flag }))}`)
			const workWeekInfo = _.get(workWeek, 'data')
			getWeekCont({ ...options, ...workWeekInfo })
		} catch (err) {
			setLoading(false)
			Messager.show(err.Message, { icon: 'error' });
		}
	}

	async function getWeekCont(options = {}) {
		const userAccount = _.get(JSON.parse(sessionStorage.getItem('current_user')), 'operator_id')
		const queryUserAccount = _.get(options, 'userId')
		const isSelfFlag = (_.isNil(queryUserAccount) || userAccount === queryUserAccount)
		setIsSelf(isSelfFlag)
		options = _.assign({}, workWeek, options)
		setWorkWeek(options)
		let { beginDate = '', endDate = '', userId = '' } = options
		const parameter = { userAccount: userId, beginDate, endDate }
		try {
			const workList = await Api.get(`/worktime/list?${strParams(parameter)}`)
			const list = _.get(workList, 'data') || []
			setWorkCont(_.sortBy(list, x => -x.id))
			setLoading(false)
		} catch (err) {
			setLoading(false)
			Messager.show(err.Message, { icon: 'error' });
		}
	}

	const allRefresh = useCallback(() => {
		getWeekDate()
		getUserList(`/common/userinfo?indexValidFlag=1`)
		getGlobalParams(`/common/globalpara?paraId=WorkTimeDateline`)
		getJobTypeList(`/worktime/jobtype/list`)
	}, [getWeekDate, getGlobalParams, getJobTypeList, getUserList])

	useEffect(() => {
		allRefresh()
	}, [allRefresh])

	// 每天凌晨3点刷新页面
	useEffect(() => {
		let timer = setInterval(() => {
			if (new Date().getHours() === 3) {
				allRefresh()
			}
		}, 1800000)
		return () => {
			clearInterval(timer)
		}
	}, [allRefresh])

	const [workContData, utFlagRate, noData] = useMemo(() => {
		const lib_week = getAllDate(workWeek)
		const lib_work = _.map(lib_week, item => {
			let itemStatus = true
			const contList = _.filter(workCont, items => item.date === Dateformat(items.workDate))
			const totalTime = _.reduce(contList, (sum, items) => {
				(items.status !== 'approve') && (itemStatus = false)
				return sum + _.toNumber(items.auditUseTime)
			}, 0)
			return { ...item, workCont: contList, totalTime, itemStatus }
		})
		const noData = _.some(lib_work, (item) => hasData(item.workCont))
		const lib_Ut = handleUtFlag(lib_work, _.get(workWeek, 'workDays'))
		return [lib_work, lib_Ut, noData]
	}, [workWeek, workCont])

	return <div className='worktime' onClick={() => setActive(false)}>
		<Option loading={loading} person={_.get(gd, 'User')} workWeek={workWeek} utFlagRate={utFlagRate} active={active}
			commonInfo={{ ...limitList, jobTypeList }} userList={userList} getWeekDate={getWeekDate} getWeekCont={getWeekCont}
			setActive={setActive} personnel={searchUser} setPersonnel={setSearchUser} allRefresh={allRefresh}
			{...{ lastCutOffDate, nextCutOffDate, limitCutOffDate }} />
		<List loading={loading} contData={workContData} isSelf={isSelf} globalParams={globalParams} noData={noData}
			commonInfo={{ ...limitList, jobTypeList }} getWeekCont={getWeekCont} refresh={getWeekDate} hasCostRate={hasCostRate}
			searchUser={searchUser} {...{ lastCutOffDate, nextCutOffDate, limitCutOffDate, beforeCutOffDate }} />
	</div>
}

function handleUtFlag(options, workDays) {
	let totalList = []
	_.forEach(options, item => totalList.push(...item.workCont))
	if (!totalList.length) {
		return 0
	}
	const annualLeaveList = _.filter(totalList, item => item.jobType === 96)
	const annualLeaveTime = _.reduce(annualLeaveList, (acc, item) => { acc += item.auditUseTime; return acc }, 0) / 60
	const utList = _.filter(totalList, item => item.utFlag === 'Y' && !_.includes(['submit', 'reject'], item.status))
	const utTime = _.reduce(utList, (acc, item) => { acc += item.auditUseTime; return acc }, 0) / 60
	return 8 * workDays - annualLeaveTime === 0 ? 100 : _.round((utTime / (8 * workDays - annualLeaveTime)) * 100)
}

function getAllDate({ beginDate = '', endDate = '' }) {
	let dateArr = []
	const start = new Date(Dateformat(beginDate))
	const end = new Date(Dateformat(endDate))
	const unixStart = start.getTime()
	const unixEnd = end.getTime()
	const oneDay = 24 * 60 * 60 * 1000;
	for (let stamp = unixStart; stamp <= unixEnd; stamp += oneDay) {
		const formatDate = new Date(parseInt(stamp))
		const whichDay = '星期' + '日一二三四五六'.charAt(formatDate.getDay())
		dateArr.push({ date: Dateformat(GetTime(formatDate)), whichDay })
	}
	return dateArr
}

export default WorkTime
