import React, { useState, useMemo, useContext, useCallback, useReducer, useEffect, useRef } from 'react'
import _ from 'lodash'
import clsx from "clsx";
import { Popover } from "antd";
import { useGet } from "rootnet-biz/es/hooks";
import { DataGrid, Pagination, Messager, Card, Dialog } from 'rootnet-ui'
import usePost from "rootnet-biz/es/hooks/usePost"
import { TextIconBtn } from "../../../../../common/TextIconBtn"
import { CustomizeFormInput } from "../../../../../common/customizeOptions/CustomizeOptions"
import useRefreshList from "../../../../../common/view/hooks/useRefreshList"
import useGetTreeList from "../../../../../common/view/hooks/useGetTreeList"
import convertTableAlign from "../../../../../common/view/convertTableAlign"
import findConvert from "../../../../../common/view/findConvert"
import DefectUpdateDialog from '../../../../../testMgt/defect/controls/DefectUpdateDialog';
import { GuideContext } from '../../../../../common/Context'
import gd from '../../../../../../base/global'
import LeftPanel from '../../../../../testMgt/defect/controls/LeftPanel';
import RightPanel from '../../../../../testMgt/defect/controls/RightPanel';
import { API1 } from '../../../../../../base/task'
import ViewArea from "../../../../../common/view/ViewArea";
import { Icon } from '../../../../../../components'
import './index.scss'
import { useFuncCode } from "../../../../../common/commonMethod";
import DefectDelayVersion from "../../../../../testMgt/defect/controls/delayVersion/DefectDelayVersion";
import { CheckBox } from "rootnet-edit";
import convertOptions from "../../../../../common/ConvertOptions";
import WorkFlowChangePop from "../../../../../workFlow/workFlowChangePop/WorkFlowChangePop";
import { isNil } from "rootnet-core/format";

const BATCH_EDIT = '/WorkflowBusiness/batchUpdateDefectNodes' // 批量修改

const PRIMARY_KEY = 'defectId'
const ENTER_DETAIL_FIELD = 'title'

const EDITABLE_FIELD = [
  // 'title', 'status', 'severity', 'priority', 'releaseId', 'productId', 'subSysId', 'source', 'type', 'originPhase', 'currentOwner', 'recurrence', 'currentUser'
]
const SEVERITY_COLOR = {
  '01': '#F5221B',
  '02': '#FF8C00',
  '03': '#FFC20E',
  '04': '#5477ff',
  '05': '#A9A9A9',
}
const PRIORITY_COLOR = {
  '01': '#F85E5E',
  '02': '#93C36B',
  '03': '#97AFD0',
}
const STATUS_COLOR = {
  'new': '#93C36B',
  'closed': '#A9A9A9',
  'suspended': '#A9A9A9',
  'rejected': '#A9A9A9',
  'postponed': '#A9A9A9',
  'in_progress': '#5477ff',
  'resolved': '#5477ff',
  'verified': '#5477ff',
  'reopened': '#5477ff',
}

const SPECIAL_FIELD_CONVERT = {
  'severity': (value, showValue) => {
    return <div className={'severity-field'} style={{ color: SEVERITY_COLOR[value], border: `1px solid ${SEVERITY_COLOR[value]}`, padding: '0 8px', borderRadius: 4 }}>
      {showValue}
    </div>
  },
  'priority': (value, showValue) => {
    return <div className={'priority-field'} style={{ background: PRIORITY_COLOR[value], padding: '1px 12px 2px', color: '#fff', borderRadius: 4 }}>
      {showValue}
    </div>
  },
  'status': (value, showValue) => {
    return <div className={'severity-field'} style={{ color: STATUS_COLOR[value], border: `1px solid ${STATUS_COLOR[value]}`, padding: '0 8px', borderRadius: 4 }}>
      {showValue}
    </div>
  },
}

const REPLACE_POST_PARAMS = {
  'currentOwner': 'current_owners'
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentId, setMode, editInfo, setEditInfo, currentTableValueRef,
    updateShowList, selectedIdList, setSelectedIdList, canDelay, list, isSelectedAll, workFlowId, setWorkFlowId, refreshList } = props
  const { editId = null, editField = null } = editInfo || {}

  const customColumns = []

  return [
    {
      header: <div className={clsx('fist-col flex center', { 'selected': isSelectedAll })}>
        <div className={'check-box'}>
          <CheckBox value={isSelectedAll} onChange={() => {
            if (isSelectedAll) {
              setSelectedIdList([])
            } else {
              setSelectedIdList(_.map(list, 'id'))
            }
          }} />
        </div>
        <div className={'index-num'}>
          #
        </div>
      </div>, width: 40, align: 'center', convert: (v, o, i) => {
        const id = _.get(o, 'id')
        return canDelay ? <div className={clsx('fist-col flex center', { 'selected': _.includes(selectedIdList, id) })}>
          <div className={'check-box'}>
            <CheckBox value={_.includes(selectedIdList, id)} onChange={() => {
              if (_.includes(selectedIdList, id)) {
                setSelectedIdList(old => _.filter(old, x => x !== id))
              } else {
                setSelectedIdList(old => _.concat(old, [id]))
              }
            }} />
          </div>
          <div className={'index-num'}>
            {i + 1}
          </div>
        </div> : i + 1
      }
    }
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      return _.find(customColumns, x => x.bind === fieldItem.columnId) || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        fieldId: fieldItem.fieldId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (!_.isNil(SPECIAL_FIELD_CONVERT[fieldItem.fieldId])) {
      showValue = SPECIAL_FIELD_CONVERT[fieldItem.fieldId](v, showValue)
    }
    if (tableField === 'workflow_business.factorId') {
      const workFlowStatusOptions = _.find(convertCollection, (v, k) => k === tableField)
      const workflowStatusColor = convertOptions(v, workFlowStatusOptions, 'color') || '#000'
      return <Popover destroyTooltipOnHide={true}
        content={<WorkFlowChangePop businessId={workFlowId} close={() => setWorkFlowId(null)} refreshList={refreshList} funcCode={'0504'} businessType={'defect'} />}
        onOpenChange={visible => {
          if (!visible) {
            setWorkFlowId(null)
          }
        }}
        trigger={'click'}
        placement="bottom"
        open={o.id === workFlowId}
      >
        <div className={'common-display-field work-flow-status-field'} onClick={() => setWorkFlowId(o.id)}
          style={{ color: workflowStatusColor, border: `1px solid ${workflowStatusColor}`, padding: '0 8px', borderRadius: 4 }}>
          {showValue || '-'}
        </div>
      </Popover>
    }
    if (o?.id === editId && editField === `${fieldItem.tableName}.${fieldItem.fieldId}`) {
      const primaryColumnId = _.get(_.find(fieldList, x => x.fieldId === PRIMARY_KEY), 'columnId')
      const handleValue = fieldItem.fieldHtmlType === '3' ? _.split(v, ',') : v
      return <CustomizeFormInput
        fieldHtmlType={fieldItem.fieldHtmlType} convertCollection={convertCollection} defaultValue={handleValue} clear={fieldItem.requiredFlag === 'N'} allowClear={fieldItem.requiredFlag === 'N'} defaultOpen={true}
        bind={`${fieldItem.tableName}.${fieldItem.fieldId}`} componentWidth={(_.toNumber(fieldItem.columnWidth) || 120) - 10}
        componentStyle={{ minWidth: (_.toNumber(fieldItem.columnWidth) || 120) - 10 }}
        viewConditionType={fieldItem.viewConditionType}
        onFocus={() => currentTableValueRef.current = handleValue}
        onChange={value => {
          if (_.includes(['3', '4', '5'], fieldItem.fieldHtmlType)) {
            updateShowList({ newValue: value, primaryId: o[primaryColumnId] })
          } else {
            currentTableValueRef.current = value
          }
        }}
        onBlur={() => updateShowList({ primaryId: o[primaryColumnId] })}
        onEnter={() => updateShowList({ primaryId: o[primaryColumnId] })}
      />
    }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          setCurrentId(o.id)
          setMode('detail')
        }}>{showValue}</div>
        <Icon name={'bianji2'} className="enter-detail-field-edit-icon" onClick={() => onEditFieldClick({ o, fieldItem })} />
      </div>
    }
    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    return <div className={'common-edit-field'} onClick={() => onEditFieldClick({ o, fieldItem })}>
      {showValue || '-'}
    </div>
  }

  function onEditFieldClick({ o, fieldItem }) {
    const titleColumnId = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'test_defect.title'), 'columnId')
    setEditInfo({
      editId: o?.id,
      title: _.get(o, titleColumnId),
      editField: `${fieldItem.tableName}.${fieldItem.fieldId}`,
      fieldId: fieldItem.fieldId,
      columnId: fieldItem.columnId,
      fieldHtmlType: fieldItem.fieldHtmlType,
      viewConditionType: fieldItem.viewConditionType,
    })
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: false,
  autoFill: true,
});

const optionsUrl = [
  '/viewCommon/getProductInfo',
  '/common/globalconst?globalConst=ProductLine',
  '/develop/product/subproduct/list',
]

export default function VersionDefects(props) {
  const { ReleaseID, isLoading, funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection, principal, poUser, productId, detailData, isShow } = props
  const [mode, setMode] = useState(null)
  const [currentId, setCurrentId] = useState(null)
  const [params, setParams] = useState()
  const [sortConfig, setSortConfig] = useState()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const [currentViewId, setCurrentViewId] = useState()
  const [factor, setFactor] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes])
  const currentTableValueRef = useRef()
  const [editInfo, setEditInfo] = useState()
  const [showDataGridList, setShowDataGridList] = useState()
  const { doFetch: editTable } = usePost()
  const [pageSize, setPageSize] = useState(100)
  const [selectedIdList, setSelectedIdList] = useState([])
  const afterViewRefresh = useCallback(() => { setSelectedIdList([]) }, [])
  const [showDialog, setShowDialog] = useState()
  const [isSelectedAll, setIsSelectedAll] = useState()
  const [workFlowId, setWorkFlowId] = useState()
  const { doFetch: batchEditFetch } = usePost()

  const [batchEditOpen, setBatchEditOpen] = useState(false)

  const [delayAuth, isStateCode] = useFuncCode(['050408', '081060'])// (081060 批量修改)

  const isState = useMemo(() => _.includes(['T', 'C'], _.get(detailData, 'state')), [detailData])

  const canDelay = useMemo(() => {
    const myVersion = gd._user?.operator_name === principal || gd._user?.operator_name === poUser
    return delayAuth || myVersion
  }, [delayAuth, principal, poUser])

  const updateShowList = useCallback(({ newValue, primaryId }) => {
    if (_.isNil(editInfo)) return
    // const { viewConditionType } = editInfo
    const updateValue = (_.includes(['3', '4', '5'], editInfo.fieldHtmlType)) ? newValue : currentTableValueRef.current
    let postParams = {
      // defectId: primaryId,
      ids: [editInfo['editId']]
    }
    const replaceParams = REPLACE_POST_PARAMS[editInfo['fieldId']]
    if (_.isNil(replaceParams)) {
      // postParams[editInfo['fieldId']] = updateValue
      postParams['fieldName'] = editInfo['fieldId']
      postParams['fieldValue'] = updateValue
    } else {
      postParams[replaceParams] = updateValue
    }
    if (editInfo['fieldId'] === 'currentUser') {
      if (isNil(updateValue)) {
        setEditInfo(null)
        currentTableValueRef.current = null
        return
      }
      const currentUserParams = {
        businessId: _.get(editInfo, 'editId'),
        businessType: 'defect',
        funcCode: '0504',
        currentUser: updateValue,
        title: _.get(editInfo, 'title')
      }
      editTable('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
        afterRefresh()
      }).catch((err) => {
        Messager.show(err._message, { icon: 'error' });
      })
    } else {
      editTable('/test_defect/updateSingle', postParams).then(() => {
        afterRefresh()
      }).catch((err) => {
        Messager.show(err._message, { icon: 'error' });
      })
    }
    function afterRefresh() {
      setShowDataGridList(oldList => {
        const cloneList = _.clone(oldList)
        _.forEach(cloneList, item => {
          if (item.id === _.get(editInfo, 'editId')) {
            item[editInfo.columnId] = updateValue
          }
        })
        return cloneList
      })
      setEditInfo(null)
      currentTableValueRef.current = null
      Messager.show('修改成功', { icon: 'success' });
    }
  }, [editInfo, editTable])

  const appendObj = useMemo(() => {
    return { factorViewSql: `View_test_defect_fixVersion.fixVersion in ('${ReleaseID}') or test_defect.releaseId in ('${ReleaseID}')` }
  }, [ReleaseID])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, afterRefresh: afterViewRefresh, appendObj })

  const refreshLists = useCallback(() => {
    refreshList()
  }, [refreshList])

  useEffect(() => {
    gd.versionDefects = refreshLists
  }, [refreshLists])

  const { options, dataGridList, TreeSwitch, isTree } = useGetTreeList({
    fieldList, list: showDataGridList, convertCollection, getOptions, getColumns,
    columnsAppendParams: {
      setCurrentId, setMode, editInfo, setEditInfo, updateShowList, selectedIdList,
      setSelectedIdList, canDelay, list, isSelectedAll, currentTableValueRef, workFlowId, setWorkFlowId, refreshList: refreshLists
    },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        return _.orderBy(data, x => x[sort.column], sort.direction)
      }
    }
  })

  useEffect(() => {
    if (_.isEmpty(list)) return setIsSelectedAll(false)
    setIsSelectedAll(_.size(selectedIdList) === _.size(list))
  }, [list, selectedIdList])

  const [isTable, setIsTable] = useState(true)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const { data: optionsRes } = useGet(optionsUrl)

  const { setGuideList } = useContext(GuideContext)
  const { doFetch: showGuide } = useGet()

  useEffect(() => {
    const guideItem = document.querySelector('.version-defects').querySelector('.two-column-switch')
    if (!_.isNil(guideItem)) {
      showGuide('/promotion/guide?funcCode=050301').then(res => {
        if (res) {
          setGuideList(x => _.concat(x, [
            {
              element: guideItem,
              intro: '点击可切换为两栏展示。'
            },
          ]))
        }
      })
    }

  }, [showGuide, setGuideList])

  useEffect(() => {
    setShowDataGridList(list)
  }, [list])

  const [productOptions, moduleOptionsRes] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2, d3] = optionsRes
    const productOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
      text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
      value: productLine,
      _disabled: true,
      children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName }))
    }))
    return [productOptions, d3]
  }, [optionsRes])

  // 两栏左右滑动
  const [canMove, setCanMove] = useState(false);
  const [htmlWidth, setHtmlWidth] = useState(350);
  const prevXRef = useRef(null);
  const panelRef = useRef(null);

  useEffect(() => {
    if (_.isEmpty(list)) return
    if (_.isNil(currentId)) {
      setCurrentId(_.get(_.head(list), 'id'))
    }
  }, [list, currentId])

  useEffect(() => {
    if (_.isNil(editInfo)) return
    const input = document.querySelector('.version-defects-data-grid').querySelector('.tooltip input')
    if (!_.isNil(input)) {
      input.focus()
      input.click()
    }
  }, [editInfo])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = _.assign({}, {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: '081000',
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor,
    }, appendObj)
    API1.downloadPost('/UserSetting/exportExcel', postParams)
  }, [currentViewId, fieldList, factor, appendObj])

  const batchEdit = useCallback(() => {
    batchEditFetch(`${BATCH_EDIT}?fixVersion=${ReleaseID}`, _.isEmpty(selectedIdList) ? [] : selectedIdList)
      .then(() => {
        Messager.show('流转成功', { icon: 'success' });
        refreshLists()
        setBatchEditOpen(false)
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [ReleaseID, batchEditFetch, refreshLists, selectedIdList])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TreeSwitch />
      <TextIconBtn icon='guanli' className={'two-column-switch'} text={isTable ? '两栏显示' : '表格展示'} onClick={() => setIsTable(x => !x)} disabled={_.isEmpty(dataGridList)} />
      {isStateCode && isState && <TextIconBtn icon='bianji2' text='CM批量流转测试' onClick={() => setBatchEditOpen(true)} />}
      {
        canDelay && false &&
        <TextIconBtn icon='shijian1' text='遗留' onClick={() => setShowDialog('delay')} disabled={_.isEmpty(selectedIdList)} />
      }
      <TextIconBtn icon='daochu' text='导出' onClick={exportViewList} />
      {isShow && <TextIconBtn icon='tianjia' text='新增' onClick={() => setMode('add')} />}
    </div>
  }, [isTable, dataGridList, exportViewList, canDelay, selectedIdList, isShow, isState, isStateCode])

  useEffect(() => {
    if (_.isEmpty(list)) return
    if (_.isNil(currentId)) {
      setCurrentId(_.get(_.head(list), 'id'))
    }
  }, [list, currentId])

  const showChildList = useMemo(() => {
    return _.filter(dataGridList, x => !_.get(x, '_children')) || []
  }, [dataGridList])

  const showList = useMemo(() => {
    let showList = showChildList || []
    if (!_.isEmpty(sortConfig) && !isTree) {
      const sortList = _.sortBy(showChildList, x => x[sortConfig.column])
      showList = sortConfig.direction === 'asc' ? sortList : _.reverse(sortList)
    }
    forceUpdate()
    return showList
  }, [isTree, showChildList, sortConfig])

  const currentIndex = useMemo(() => {
    return _.findIndex(showList, x => _.get(x, 'id') === currentId)
  }, [showList, currentId])

  const switchCurrentItem = useCallback((switchDirection = 'next') => {
    setCurrentId(switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id)
  }, [showList, currentIndex])

  return (
    <>
      <div className='version-defects'>
        <ViewArea funcCode={funcCode} allOptions={optionsConfig} search={setParams} loading={boxLoading}
          {...{
            getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
            currentViewId, setCurrentViewId, params, pageSize
          }} />
        {
          <Table {...{ boxLoading, extra, list, error, dataGridList, options, pageSize, total, pageNum, setParams, refreshList, ReleaseID, isTable, currentId, setCurrentId, setPageSize }} />
        }
        {
          !isTable && <div className={`defect-panel flex ${isFullScreen ? 'defect-panel-full-screen' : ''} ${canMove ? 'resize' : ''}`}
            onMouseMove={handleMove} ref={panelRef}
            onMouseLeave={() => setCanMove(false)}
            onMouseUp={() => setCanMove(false)}>
            {!isFullScreen && <div className={'left-panel-remove-wrap flex'}>
              <div className={'left-panel-wrap'} style={{ width: htmlWidth }}>
                <LeftPanel {...{ listRes, fieldList, setParams, currentId, setCurrentId, convertCollection, pageSize, setPageSize }} />
              </div>
              <div className={clsx("line", { resize: canMove })}
                onMouseDown={() => setCanMove(true)} />
            </div>}
            <RightPanel {...{
              isFullScreen, setIsFullScreen, list, currentId, setCurrentId, convertCollection, refreshList,
              productOptions, moduleOptionsRes
            }} />
          </div>
        }
      </div>

      {
        _.includes(['add', 'edit', 'detail'], mode) &&
        <DefectUpdateDialog
          addDefaultParams={{
            releaseId: ReleaseID,
            // fixVersion: (detailData?.projectid && _.includes(['T','C'],detailData?.state)) ? ReleaseID: null,
            productId,
            fixProjectIdList: detailData?.projectid ? [detailData?.projectid] : [],
            projectId: detailData?.projectid ? detailData?.projectid : null,
          }}
          close={() => setMode(null)} {...{
            convertCollection, mode, setMode, currentId, setCurrentId, refreshList, switchCurrentItem,
            currentIndex, showChildList, productOptions, moduleOptionsRes
          }} />
      }
      {
        showDialog === 'delay' &&
        <DefectDelayVersion close={() => setShowDialog(null)} afterRefresh={() => {
          refreshList()
          setSelectedIdList([])
        }} ids={selectedIdList} />
      }
      {
        batchEditOpen &&
        <Dialog
          header={<div className='flex'>
            <div className='title'>提示</div>
            <div
              style={{
                fontSize: '12px',
                fontWeight: 400,
                marginLeft: '8px',
                display: 'flex',
                alignItems: 'center',
                color: '#929292',
              }}
            >批量流转范围包括缺陷的修复版本包含当前版本，当前节点在【CM审核】，即可批量流转到【回归测试】！</div>
          </div>}
          confirm={batchEdit}
          cancel={() => setBatchEditOpen(false)}
          style={{
            paddingTop: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          请确认批量流转！
          {/*  */}
        </Dialog >
      }
    </>
  )

  function handleMove(e) {
    if (!canMove) return;
    e.preventDefault();
    e.stopPropagation();
    const prevX = prevXRef.current;
    prevXRef.current = e.clientX
    if (prevX === null) return null;
    const offset = e.clientX - prevX;
    setHtmlWidth(x => {
      if (x + offset < 256) {
        prevXRef.current = prevX
        return 256
      } else if ((x + offset) > (panelRef.current.offsetWidth / 2)) {
        prevXRef.current = prevX
        return x
      } else {
        return x + offset
      }
    });
  }

}

function Table(props) {
  const { boxLoading, extra, list, error, dataGridList, options, pageSize, total, pageNum, setParams, isTable, currentId, setCurrentId, setPageSize } = props
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  useEffect(() => {
    _.forEach(dataGridList, o => {
      return o._rowClass = currentId === o?.id ? 'select_row' : ''
    })
    forceUpdate()
  }, [currentId, dataGridList])

  const onRowClick = useCallback((item) => {
    setCurrentId(item?.id)
  }, [setCurrentId])

  return (
    <Card className={`flex-y x-card-singlegrid ${isTable ? '' : 'is-not-table'}`} title='缺陷列表' error={error} loading={boxLoading} extra={extra} data={list}>
      <div className={'version-defect-data-grid fill flex-y'}>
        <DataGrid className='version-defects-data-grid' data={dataGridList} option={options} onRowClick={onRowClick} />
        <Pagination jumper selector pageSize={pageSize} total={total} current={pageNum}
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </div>
    </Card>
  )
}

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 100,
  }
}
