import React, { useState, useMemo, useCallback, useEffect } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Tabs, Badge } from 'antd'
import { FormInput, TextArea } from 'rootnet-edit'
import { Dialog, DataGrid, Messager } from 'rootnet-ui'
import { getColumnsUntreated, getColumnsOther } from './getColumns'
import { Icon } from '../../../../../../components'
import RequirementDetailDialog from '../../../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog'
import IssueDetailDialog from '../../../../../issueMgt/components/issueDetailDialog/IssueDetailDialog'
import './index.scss'
import { usePost } from 'rootnet-biz/lib/hooks'

const getOption = (columns) => ({
  columns,
  autoFill: true,
  resizable: true,
  virtualized: false,
  fixedLeft: 2,
  nilText: '-',
  emptyText: '-',
});

export default function ProjectChangeRequest(props) {
  const { refreshProject, projectChangeRequestData, close } = props
  const [activeTab, setActiveTab] = useState('unaudited')
  const [open, setOpen] = useState(false)
  const [selectData, setSelectData] = useState([])
  const [showDetail, setShowDetail] = useState()

  const optionUntreated = useMemo(() => {
    return getOption(getColumnsUntreated({ setOpen, setSelectData, setShowDetail }))
  }, [])

  const optionOther = useMemo(() => {
    return getOption(getColumnsOther({ setShowDetail }))
  }, [])


  useEffect(() => {
    refreshProject()
  }, [refreshProject])

  return (
    <>
      <Dialog
        header='项目变动申请'
        cancel={close}
        style={{ width: 980, height: 500 }}
        className='project-change-request'
        confirmButtonVisible={false}
      >
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          items={[
            {
              label: <Badge
                count={_.size(_.get(projectChangeRequestData, 'unaudited', []))}
                size="small"
                offset={[8, 0]}
              >
                未处理
              </Badge>,
              key: 'unaudited',
              children: <div className='unaudited-tables'>
                {
                  _.size(selectData) > 0 && <div className='select-operation flex'>
                    <div>已选择{_.size(selectData)}项</div>
                    <div className='flex' style={{ marginLeft: 36 }}>
                      <div className='flag-agree' onClick={() => { setOpen('agree') }}>同意<Icon name='chenggong' /></div>
                      <div className='flag-reject' onClick={() => { setOpen('reject') }}>拒绝<Icon name='shibai' /></div>
                    </div>
                  </div>
                }
                <DataGrid
                  data={_.get(projectChangeRequestData, 'unaudited', [])}
                  option={{ ...optionUntreated, fixedRight: 1 }}
                  onSelectionChange={setSelectData}
                  selection={selectData}
                />
              </div>,
            },
            {
              label: <Badge
                count={_.size(_.get(projectChangeRequestData, 'notApproved', []))}
                size="small"
                offset={[8, 0]}
              >
                已拒绝
              </Badge>,
              key: 'notApproved',
              children: <div className='notApproved-tables'>
                <DataGrid data={_.get(projectChangeRequestData, 'notApproved', [])} option={optionOther} />
              </div>,
            },
            {
              label: <Badge
                count={_.size(_.get(projectChangeRequestData, 'approved', []))}
                size="small"
                offset={[8, 0]}
              >
                已同意
              </Badge>,
              key: 'approved',
              children: <div className='approved-tables'>
                <DataGrid data={_.filter(_.get(projectChangeRequestData, 'approved', []), o => o.auditFlag === 'Y')} option={optionOther} />
              </div>,
            },
          ]}
        />
      </Dialog>
      {open !== false && <SelectLog setCheck={setOpen} check={open} close={() => setOpen(false)} {...{ refreshProject, selectData }} />
      }
      {
        _.get(showDetail, 'type') === 'REQ' &&
        <RequirementDetailDialog
          close={() => setShowDetail(null)}
          currentInfo={{ id: _.get(showDetail, 'id') }}
        />
      }
      {
        _.get(showDetail, 'type') === 'ISSUE' &&
        <IssueDetailDialog
          close={() => setShowDetail(null)}
          currentInfo={{ id: _.get(showDetail, 'id') }}
        />
      }
    </>
  )
}

function SelectLog(props) { // auditFlag 动作  auditMemo
  const { close, selectData, check, setCheck, refreshProject, refreshHome = () => { } } = props
  const [value, setValue] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch } = usePost()
  const confirm = useCallback(() => {
    if (_.size(value) <= 0) return Messager.show('请填写说明', { icon: 'error' })
    if (isDisable) return
    setIsDisable(true)
    const auditFlag = check === 'agree' ? 'Y' : 'N'
    const params = _.map(selectData, o => _.assign({}, o, { auditFlag, auditMemo: value }))
    doFetch('/projectAuditLog/batchAudit', params)
      .then(() => {
        refreshHome()
        refreshProject()
        close()
        setIsDisable(false)
        Messager.show('申请成功', { icon: 'success' })
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [selectData, check, close, doFetch, refreshProject, refreshHome, value, isDisable])
  return <Dialog
    header='操作说明'
    cancel={close}
    confirm={confirm}
    className='dev-project-select-log'
    confirmButtonText='保存'
  >
    <div className='select-log flex'>
      <div className={cls('agree', { checked: check === 'agree' })} onClick={() => setCheck('agree')}>同意<Icon name='chenggong' /></div>
      <div className={cls('reject', { checked: check === 'reject' })} onClick={() => setCheck('reject')}>拒绝<Icon name='shibai' /></div>
    </div>
    <FormInput
      required
      horizontal
      label='说明'
      value={value}
      onChange={setValue}
      component={TextArea}
      labelWidth={53}
      componentWidth={360}
      maxLength={200}
      className={cls('reason', { error: _.size(value) <= 0 })}
    />
  </ Dialog >
}
