import React, { useMemo, useState } from 'react'
import cls from 'clsx'
import { DataGrid } from 'rootnet-ui'
import { Icon } from '../../../../../../../components'
import { Box } from '../../../../../../common/commonComponent'
import './index.scss'
import _ from "lodash";
import TrackingDetailDialog from "../../../../../../trackingManagementMgt/components/trackingDetailDialog";
import { Tooltip } from "antd";
import { isNil } from '../../../../../../appraise/components/method'

const getColumns = (props) => {
  const { type, delTrack, setShowDetailDialog } = props
  return [
    {
      header: '研发任务编号', bind: 'tracerId', sortable: true, align: 'left', width: 120, tooltip: true,
      convert: (v, o) => {
        if (isNil(o.id)) return v
        return <span onClick={() => {
          setShowDetailDialog({
            module: 'tracker',
            id: v
          })
        }} style={{ cursor: 'pointer', color: '#5477ff' }}>{v}</span>
      }
    },
    { header: '标题', bind: 'tracerTitle', width: 300, sortable: true, align: 'left', tooltip: true },
    {
      header: '状态', bind: 'tracerState', sortable: true, align: 'left', width: 100, tooltip: true, convert: (v, o) => {
        if (isNil(o.id)) return null
        return `${o.constDisplayName}`
      }
    },
    {
      header: '所在版本', bind: 'releaseID', sortable: true, align: 'left', width: 100, tooltip: true,
      convert: (v, o) => {
        if (isNil(o.id)) return null
        return <Tooltip title={v} placement="topLeft" overlayStyle={{ width: 400, maxWidth: 400, whiteSpace: 'pre-wrap' }}><span>{v}</span></Tooltip>
      }
    },
    {
      header: '取消关联', align: 'center', width: 65, convert: (v, o, i, d) => {
        if (isNil(o.id)) return null
        return <Icon onClick={() => delTrack([o.id], type)} className='cancel-relation' name='quxiaoguanlian' />
      }
    },
  ]

}

const gridOptsFor = (columns) => ({
  nilText: '-',
  fixedLeft: 2,
  emptyText: '-',
  resizable: true,
  autoFill: true,
  columns,
  virtualized: false,
});

export default function AssociatedTrackingList(props) {
  const { extra, type, delTrack, loading, error, data, ...rest } = props
  const [showDetailDialog, setShowDetailDialog] = useState()
  const option = useMemo(() => gridOptsFor(getColumns({ type, delTrack, setShowDetailDialog })), [delTrack, type])

  return (
    <Box
      className={cls('associated-tracking-list flex-y x-card-singlegrid x-card-height')}
      extra={extra(data, type)}
      data={[0]}
      error={error}
      loading={loading}
      {...rest}
    >
      <DataGrid data={data} option={option} />
      {
        _.get(showDetailDialog, 'module') === 'tracker' &&
        <TrackingDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
          id: _.get(showDetailDialog, 'id')
        }} />
      }
    </Box>
  )
}