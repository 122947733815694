import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { Dialog } from 'rootnet-ui'
import { Form, DatePicker, Display, Input, Select, FormInput } from 'rootnet-edit'
import TextAreaInput from '../../../../../../common/textAreaInput/TextAreaInput'
import './index.scss'

export default function SalesCatalogueAddDialog(props) {
  const { close } = props
  const [params, setParams] = useState()
  const [error, setError] = useState()

  const confirm = useCallback(() => {

  }, [])

  const formList = useMemo(() => {
    return [
      {
        bind: 'cp',
        label: '产品',
        required: true,
        search: true,
        component: Select,
        options: [],
      },
      {
        label: '产品模块',
        bind: 'cpmk',
        required: true,
        search: true,
        component: Select,
        options: [],
      },
      {
        label: '产品线',
        bind: 'cpx',
        component: Display,
        className: 'form-display',
      },
      {
        label: '模块ID',
        bind: 'mkid',
        component: Display,
        className: 'form-display',
      },
      {
        label: '可售标识',
        bind: 'ksbs',
        component: Display,
        className: 'form-display',
      },
      {
        label: '销售类别',
        bind: 'xslb',
        component: Display,
        className: 'form-display',
      },
      {
        label: '一级类别',
        bind: 'yjlb',
        component: Display,
        className: 'form-display',
      },
      {
        label: '二级类别',
        bind: 'ejlb',
        component: Display,
        className: 'form-display',
      },
      {
        label: '三级类别',
        bind: 'sjlb',
        component: Display,
        className: 'form-display',
      },
      {
        label: '当前状态',
        bind: 'dqzt',
        required: true,
        search: true,
        component: Select,
        options: [],
      },
      {
        label: '上线日期',
        bind: 'sxrq',
        component: DatePicker,
        clear: true,
      },
      {
        label: '验收日期',
        bind: 'ysrq',
        component: DatePicker,
        clear: true,
      },
      {
        label: '维护费收取日期',
        bind: 'whfsqrq',
        component: DatePicker,
        clear: true,
      },
      {
        label: '下线日期',
        bind: 'xxrq',
        component: DatePicker,
        clear: true,
      },
      {
        label: '相关合同及编号',
        bind: 'xghtjbh',
        component: Input,
      },
      {
        label: '备注',
        bind: 'bz',
        component: TextAreaInput,
        componentWidth: 486,
        className: 'text-area-input-edit'
      },
    ]
  }, [])

  return (
    <Dialog
      header='新增'
      className='sales-catalogue-add-dialog'
      cancel={close}
      confirm={confirm}
    >
      <Form value={params} onChange={setParams} error={error} onError={setError} >
        {
          _.map(formList, (item, key) => {
            return <FormInput horizontal key={key} labelWidth={110}  {...item} />
          })
        }
      </Form>
    </Dialog>
  )
}
