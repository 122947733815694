import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './ReleaseInfoDialog.scss'
import _ from 'lodash'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Select, Input, TreeSelect, DatePicker, Switch } from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import convertOptions from "../common/ConvertOptions";
import { uniqKeyFor } from "../../project_share/utils/utils";
import Icon from "../../components/Icon";
import moment from 'moment';
import { Messager, Dialog, Loader } from 'rootnet-ui'
import useGet from "rootnet-biz/es/hooks/useGet";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import { strParams } from "../../utils/publicFun";
import usePost from "rootnet-biz/es/hooks/usePost";

const { TextArea } = Input;

// 封装后的阻止冒泡功能
const stopPropagation = (e) => {
    e.nativeEvent.stopImmediatePropagation();
}

function ReleaseInfoDialog(props) {
    const { showUpdateInfo, onCancel, refresh, releaseList } = props
    const { mode, id: initId } = showUpdateInfo
    const [id, setId] = useState(initId)
    const { data: releaseOptionsRes } = useGet('/promotion/releaseline?releaseline=rims')
    const [activeId, setActiveId] = useState()
    const [activeArea, setActiveArea] = useState()
    const { data: allMenu } = useGet('/common/funcctrlAll?moduleId=BS')
    const [list, setList] = useState([])
    const [releaseId, setReleaseId] = useState()
    const [releaseDate, setReleaseDate] = useState()
    const [versionRemind, setVersionRemind] = useState(false)
    const { doFetch: getDetail, loading } = useGet()
    // const [notShow, setNotShow] = useState(true)
    const { doFetch } = usePost()

    const currentIndex = useMemo(() => {
        if (_.isNil(id)) return 1
        if (_.isEmpty(releaseList)) return 1
        return _.findIndex(releaseList, x => x.id === id)
    }, [id, releaseList])

    const switchCurrentItem = useCallback((switchDirection = 'next') => {
        setId(switchDirection === 'next' ? releaseList[currentIndex + 1].id : releaseList[currentIndex - 1].id)
    }, [releaseList, currentIndex])

    useEffect(() => {
        if (mode === 'add') return
        getDetail('/promotion/detail?' + strParams({ id: id })).then(res => {
            const { detailInfos, releaseId: initReleaseId, releaseDate: initReleaseDate } = res
            setReleaseId(initReleaseId)
            const initDate = dateFormat('YYYY-MM-DD')(toDate.str14ToDate(initReleaseDate))
            setReleaseDate(initDate)
            setList(detailInfos)
        })
    }, [id, getDetail, mode])

    const memo = useMemo(() => {
        if (_.isNil(releaseId)) return null
        return _.get(_.find(releaseOptionsRes, x => x.releaseID === releaseId), 'memo')
    }, [releaseId, releaseOptionsRes])

    useEffect(() => {
        document.addEventListener("click", () => {
            setActiveId(null)
            setActiveArea(null)
        });
    }, [])

    const releaseOptions = useMemo(() => {
        return _.map(releaseOptionsRes, x => ({ label: x.releaseName, value: x.releaseID }))
    }, [releaseOptionsRes])

    const menuTree = useMemo(() => {
        return generate_menu(null, allMenu, 1)
    }, [allMenu])

    const changeList = useCallback((value, index, bind) => {
        setList(oldList => {
            const tempList = _.clone(oldList)
            tempList[index][bind] = value
            return tempList
        })
    }, [])

    const delItem = useCallback((id) => {
        setList(oldList => {
            return _.filter(oldList, x => x.id !== id)
        })
    }, [])

    const addItem = useCallback(() => {
        const newId = uniqKeyFor()
        setList(oldList => {
            const tempList = _.clone(oldList)
            tempList.push({ id: newId, module: null, detail: null })
            return tempList
        })
        setActiveId(newId)
        setActiveArea('all')
    }, [])

    const changeReleaseId = useCallback((value) => {
        const currentReleaseDate = _.get(_.find(releaseOptionsRes, x => x.releaseID === value), 'issueDate')
        const convertDate = dateFormat('YYYY-MM-DD')(toDate.str14ToDate(currentReleaseDate))
        if (!_.isNil(convertDate)) {
            setReleaseDate(convertDate)
        }
        setReleaseId(value)
    }, [releaseOptionsRes])

    const submit = useCallback(() => {
        const notNullList = _.filter(list, x => isNotNull(x.detail) && isNotNull(x.module))
        const postParams = _.assign({}, {
            id: id,
            releaseDate: releaseDate,
            releaseId: releaseId,
            memo: memo,
            detailInfos: _.map(notNullList, x => _.omit(x, 'id'))
        }, mode === 'add' && { status: versionRemind })
        const url = mode === 'add' ? 'promotion/add' : '/promotion/modify'
        doFetch(url, postParams).then(() => {
            Messager.show(mode === 'add' ? '添加成功' : '修改成功', { icon: 'success' })
            refresh()
            onCancel()
        }).catch(err => Messager.show(err._message, { icon: 'error' }))
    }, [mode, id, releaseId, releaseDate, list, onCancel, memo, doFetch, refresh, versionRemind])

    return <Dialog className={'release-info-dialog'} cancel={onCancel} confirm={submit} headerVisible={false}
        confirmButtonVisible={mode !== 'detail'} cancelButtonText={mode === 'detail' ? '关闭' : '取消'}>
        <div className={'release-info-content flex-y'}>
            <div className={'header flex center-y'}>
                <div className="header-content flex-y">
                    <div className="system-name flex center">
                        根网管理系统（BS）
                    </div>
                    <div className="version-name">{(releaseId?.split('_')[1]) ? (releaseId?.split('_')[1]) : releaseId}版本</div>
                    <div className="version-info-group flex center-y">
                        <div className="version-id">
                            版本号：
                            {
                                mode === 'detail' ? releaseId :
                                    <Select value={releaseId} onChange={changeReleaseId} showSearch options={releaseOptions} placeholder={'请选择版本号'} />
                            }
                        </div>
                        <div className="version-date">
                            版本上线日期：
                            {
                                mode === 'detail' ? releaseDate :
                                    <DatePicker locale={locale} value={releaseDate ? moment(releaseDate) : null} onChange={(date, strDate) => setReleaseDate(strDate)} />
                            }
                        </div>
                        {
                            mode === 'add' &&
                            <div className='version-remind'>
                                发版提醒：
                                <Switch
                                    value={versionRemind}
                                    onChange={v => setVersionRemind(v)}
                                    checkedChildren="开"
                                    unCheckedChildren="关"
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className="close-icon-wrap flex center" onClick={onCancel}>
                    <Icon name={'quxiao'} className={'close-icon'} />
                </div>
            </div>
            <div className="list-group flex scroll-y" onClick={stopPropagation}>
                {
                    _.map(list, (item, index) => <div className={'list-item flex-y'} key={item.id}>
                        <div className="module flex center-y">
                            {
                                item.id === activeId && (activeArea === 'module' || activeArea === 'all') ?
                                    <TreeSelect treeData={menuTree} placeholder={'更新模块'} value={item.module} onSelect={v => changeList(v, index, 'module')} /> :
                                    <div className={'module-text'} onClick={() => {
                                        if (mode === 'detail') return
                                        setActiveId(item.id)
                                        setActiveArea('module')
                                    }}>{convertOptions(item.module, allMenu, 'funcName', 'funcCode')}</div>
                            }
                            {
                                mode !== 'detail' &&
                                <CloseCircleOutlined className={'del-icon'} onClick={() => delItem(item.id)} />
                            }
                        </div>
                        <div className="detail">
                            {
                                item.id === activeId && (activeArea === 'detail' || activeArea === 'all') ?
                                    <TextArea autoSize={{ minRows: 5, maxRows: 5 }} showCount maxLength={500} placeholder={'更新说明'} value={item.detail} onChange={e => changeList(e.target.value, index, 'detail')} /> :
                                    <div className={`detail-text scroll-y ${_.size(list) > 3 ? 'max-height' : ''}`} onClick={() => {
                                        if (mode === 'detail') return
                                        setActiveId(item.id)
                                        setActiveArea('detail')
                                    }} dangerouslySetInnerHTML={{ __html: item.detail?.replace(/\n/g, '<br>') || '请填写更新说明' }}>
                                        {/*{item.detail || '请填写更新说明'}*/}
                                    </div>
                            }
                        </div>
                    </div>)
                }
                {
                    mode !== 'detail' &&
                    <div className="add-card flex center">
                        <div className={'icon-wrap fill flex center'} onClick={addItem}>
                            <Icon name={'zengjia'} className={'add-icon'} />
                        </div>
                    </div>
                }
            </div>
            {/*{*/}
            {/*    _.isNil(id) && mode === 'detail' &&*/}
            {/*    <div className="not-show-check">*/}
            {/*        <Checkbox checked={notShow} onChange={e => setNotShow(e.target.checked)}>不再显示</Checkbox>*/}
            {/*    </div>*/}
            {/*}*/}
            {
                mode !== 'add' && loading &&
                <Loader fill />
            }
            {
                !_.isEmpty(releaseList) && mode !== 'add' &&
                <div className="footer-switch flex center">
                    <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
                        onClick={() => {
                            if (!_.includes([-1, 0], currentIndex)) {
                                switchCurrentItem('previous')
                            }
                        }}>
                        <Icon name='xiangqian' />&nbsp;上一个
                    </div>
                    <div className={`footer-switch-item center ${currentIndex === releaseList?.length - 1 ? 'disabled' : ''}`}
                        onClick={() => {
                            if (!(currentIndex === releaseList?.length - 1)) {
                                switchCurrentItem('next')
                            }
                        }}>
                        下一个&nbsp;<Icon name='xianghou' />
                    </div>
                    <div className="list-num">
                        {currentIndex + 1}/{releaseList?.length}
                    </div>
                </div>
            }
        </div>
    </Dialog>
}

function generate_menu(temp, data, level) {
    const subs = _.filter(data, o => o.funcLevel === level);
    _.forEach(subs, r => {
        r.title = r.funcName;
        r.value = r.funcCode;
        if (temp) {
            const parent = _.find(temp, o => _.startsWith(r.funcCode, o.funcCode));
            if (parent) {
                if (_.isNil(parent.children))
                    parent.children = [];
                parent.children.push(r);
            }
        }
    })
    if (subs.length > 0)
        generate_menu(subs, data, level + 1);
    return subs;
}

function isNotNull(value) {
    return !(_.isNil(value) || value === '')
}

export default ReleaseInfoDialog;