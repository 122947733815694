import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
import { Dialog, Messager } from 'rootnet-ui'
import { Form, FormInput, DatePicker, Select } from 'rootnet-edit'
import { Icon } from '../../../../../../../../components'
import { isNil } from '../../../../../../../appraise/components/method'
import { dateFormat } from 'rootnet-core/dateFormat'
import { strParams } from '../../../../../../../../utils/publicFun'
import { ScheduleManagementContext } from '../../../../../../../common/Context'
import { useGet } from 'rootnet-biz/lib/hooks'

const EXPORT_URL = '/exportModifyFile/differenceUpgradeDescAndSqlExport'
const QUERY_DATE_URL = '/exportModifyFile/queryReleaseSealingDate' //查询上次封板时间

const HEADER = {
  '0': '对比配置文件',
  '1': '对比修改脚本',
}
export default function ContrastDifference(props) {
  const { type, close, versionList } = props
  const [params, setParams] = useState({})
  const [error, setError] = useState({})
  const { downloadGetProgress } = useContext(ScheduleManagementContext)

  const { releaseId } = useMemo(() => params || {}, [params])
  const { doFetch } = useGet()

  useEffect(() => {
    if (!isNil(releaseId)) {
      doFetch(`${QUERY_DATE_URL}?releaseId=${releaseId}`)
        .then((res) => {
          if (_.isNil(res)) return setParams(x => (_.assign({}, x, { fileUpdateTime: null })))
          setParams(x => (_.assign({}, x, { fileUpdateTime: new Date(res) })))
        })
    }
  }, [doFetch, releaseId])

  const check = useCallback(() => {
    return _.every(_.values(error), isNil)
  }, [error])

  const versionOpt = useMemo(() => {
    if (_.isEmpty(versionList)) return []
    return _.map(versionList, o => ({ text: o.releaseName, value: o.releaseID }))
  }, [versionList])

  const confirm = useCallback(() => {
    if (!check()) return Messager.show('请填写必填项', { icon: 'error' })
    const fileUpdateTime = dateFormat('YYYY-MM-DD HH:MM:SS', _.get(params, 'fileUpdateTime'))
    downloadGetProgress(`${EXPORT_URL}?${strParams({ ...params, fileUpdateTime, type })}`, '导出修改文件', close)
  }, [downloadGetProgress, type, close, check, params])

  return (
    <Dialog
      header={_.get(HEADER, type)}
      className='contrast-edit-file-log'
      cancel={close}
      confirm={confirm}
    // loading={loading}
    >
      <Form value={params} onChange={setParams} error={error} onError={setError}>
        <FormInput labelWidth={187} required horizontal label='参与对比的上一个版本' bind='releaseId' component={Select} options={versionOpt} search />
        <div className='flex center-x center-y'>
          <FormInput labelWidth={179} required horizontal label='上一版本的首次发布时间' bind='fileUpdateTime' component={DatePicker} mode='datetime' clear /><Tooltip title='差异对比所使用的上一版本的研发任务信息，为所选时间当时的版本信息，默认为首次发布的时间，可改'><span><Icon name='bangzhu' style={{ fontSize: 14, color: '#5477ff' }} /></span></Tooltip>
        </div>
        <FormInput labelWidth={187} required horizontal label='参与对比的本次版本' bind='releaseIdAnother' component={Select} options={versionOpt} search />
      </Form>

    </Dialog>
  )
}
