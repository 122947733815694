import React, { useCallback, useState } from 'react'
import { Checkbox } from 'antd'
import { Dialog, Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import './index.scss'

const EDIT_URL = '/develop/product/batchUpdateSubDisableFlag'

export default function IssueDisabledDialog(props) {
  const { close, idList, setSelection, refreshList } = props
  const [disableFlag, setDisableFlag] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch } = usePost()

  const confirm = useCallback(() => {
    if (isDisable) return
    setIsDisable(true)
    doFetch(EDIT_URL, { idList, disableFlag: disableFlag ? '是' : null })
      .then(() => {
        refreshList()
        setSelection([])
        close()
        Messager.show('操作成功', { icon: 'success' })
      }).catch(err => {
        setIsDisable(false)
        Messager.show(err.Message, { icon: 'error' })
      })

  }, [close, idList, doFetch, isDisable, disableFlag, setSelection, refreshList])

  return (
    <Dialog
      header='确认'
      cancel={close}
      confirm={confirm}
      className='issue-disabled-dialog'
    >
      <Checkbox onChange={e => setDisableFlag(e.target.checked)}>ISSUE禁用</Checkbox>
      {/* ISSUE禁用 */}
    </Dialog>
  )
}
