import { Select, DatePicker, TextArea, Display } from "rootnet-edit";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import _ from "lodash";
import { N2 } from "rootnet-core/format";
import convertOptions from "../../../../common/ConvertOptions";
import UserSelect from "../../../../common/personSelectPop/UserSelect";
// import UserSelect from "../../../../common/personSelectPop/UserSelect";

export default function getFormConfig({ allUserRes, convertCollection, acceptanceDateRequired, tCustIdOptions, associatedVersionOpt, delayproblemCategoryOpt, projectSubjectOpt, editFormInfo, operationStatisticsOpt, updateFrequencyOpt, isEditQA, isNotYet }) {
    const { totalContractAmount, amountMeetingConditions } = editFormInfo || {}
    return [
        {
            bind: 'basicInfo',
            name: '基本信息',
            children: [
                {
                    label: '客户名称',
                    bind: 'tcustId',
                    disabled: true,
                    component: Display,
                    convert: v => convertOptions(v, _.get(convertCollection, 'ImplementProject.t_custId')),
                },
                {
                    label: '客户系统',
                    bind: 'customer',
                    component: Select,
                    search: true,
                    required: true,
                    options: tCustIdOptions,
                    convert: v => convertOptions(v, tCustIdOptions) || v,
                },
                {
                    label: '项目地区',
                    bind: 'area',
                    component: Select,
                    required: true,
                    options: _.get(convertCollection, 'ImplementProject.area'),
                    convert: v => convertOptions(v, _.get(convertCollection, 'ImplementProject.area')),
                },
                {
                    label: '项目标记',
                    bind: 'projectType',
                    component: Select,
                    required: true,
                    options: _.get(convertCollection, 'ImplementProject.projectType'),
                    convert: v => convertOptions(v, _.get(convertCollection, 'ImplementProject.projectType')),
                },
                {
                    label: '项目管理主体',
                    bind: 'type',
                    component: Select,
                    options: projectSubjectOpt,
                    convert: v => convertOptions(v, projectSubjectOpt),
                },
                {
                    label: '优先级',
                    bind: 'priority',
                    component: Select,
                    required: true,
                    options: _.get(convertCollection, 'ImplementProject.Priority'),
                    convert: v => convertOptions(v, _.get(convertCollection, 'ImplementProject.Priority')),
                },
                {
                    label: '项目难易分类',
                    bind: 'complexity',
                    component: Select,
                    required: true,
                    options: _.get(convertCollection, 'ImplementProject.complexity'),
                    convert: v => convertOptions(v, _.get(convertCollection, 'ImplementProject.complexity')),
                },
                {
                    label: '风险级别',
                    bind: 'riskLevel',
                    component: Select,
                    required: true,
                    options: _.get(convertCollection, 'ImplementProject.riskLevel'),
                    convert: v => convertOptions(v, _.get(convertCollection, 'ImplementProject.riskLevel')),
                    iconText: <div>
                        <div>A.无风险：指有把握在设定日期之前拿回验收签署件； </div>
                        <div>B.中低风险：有较大可能在设定日期之前拿回验收签署件，最坏情形是延期30天能拿回；</div>
                        <div>C.较高风险：有一定可能在设定日期之前拿回验收签署件，最坏情形是延期90天能拿回；</div>
                        <div>D.极高风险：因内外部原因，导致项目无法交付或交付后无法验收，延期时间无法评估。</div>
                    </div>
                },
                {
                    label: '风险细分',
                    bind: 'riskSegmentation',
                    component: Select,
                    clear: true,
                    search: true,
                    options: delayproblemCategoryOpt,
                    convert: v => convertOptions(v, delayproblemCategoryOpt),
                },
                {
                    label: '实施阶段',
                    bind: 'serviceStatus',
                    component: Select,
                    required: true,
                    options: _.get(convertCollection, 'ImplementProject.ServiceStatus'),
                    convert: v => convertOptions(v, _.get(convertCollection, 'ImplementProject.ServiceStatus')),
                },
                {
                    label: '产品线',
                    bind: 'productLine',
                    component: Select,
                    options: _.get(convertCollection, 'ImplementProject.ProductLine'),
                    convert: v => convertOptions(v, _.get(convertCollection, 'ImplementProject.ProductLine')),
                },
                {
                    label: '产研支持人',
                    bind: 'productionSupport',
                    component: UserSelect,
                    convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount')
                },
                {
                    label: '进展更新频率',
                    bind: 'updateFrequency',
                    component: Select,
                    clear: true,
                    search: true,
                    options: updateFrequencyOpt,
                    convert: v => convertOptions(v, updateFrequencyOpt),
                },
                {
                    label: '预计入场日期',
                    bind: 'planBeginDate',
                    component: DatePicker,
                    required: true,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                        return dateFormat('YYYY-MM-DD', v) || '-'
                    },
                    bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
                    bindInConvert: v => toDate.str14ToDate(v),
                },
                {
                    label: '实际入场日期',
                    bind: 'beginDate',
                    clear: true,
                    component: DatePicker,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                        return dateFormat('YYYY-MM-DD', v) || '-'
                    },
                    bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
                    bindInConvert: v => toDate.str14ToDate(v),
                },
                {
                    label: '预计上线日期',
                    bind: 'closeTime',
                    required: isNotYet ? false : true,
                    clear: true,
                    component: DatePicker,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                        return dateFormat('YYYY-MM-DD', v) || '-'
                    },
                    bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
                    bindInConvert: v => toDate.str14ToDate(v),
                },
                {
                    label: '实际上线日期',
                    bind: 'onlineDate',
                    clear: true,
                    component: DatePicker,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                        return dateFormat('YYYY-MM-DD', v) || '-'
                    },
                    bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
                    bindInConvert: v => toDate.str14ToDate(v),
                    iconText: '技术上线使用的第一个交易日，一般是周一'
                },
                {
                    label: '预计完工确认日期',
                    bind: 'planCompletionDate',
                    component: DatePicker,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                        return dateFormat('YYYY-MM-DD', v) || '-'
                    },
                    bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
                    bindInConvert: v => toDate.str14ToDate(v),
                },
                {
                    label: '实际完工确认日期',
                    bind: 'actualCompletionDate',
                    component: DatePicker,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                        return dateFormat('YYYY-MM-DD', v) || '-'
                    },
                    bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
                    bindInConvert: v => toDate.str14ToDate(v),
                },
                {
                    label: '计划验收日期',
                    bind: 'planEndDate',
                    component: DatePicker,
                    required: true,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                        return dateFormat('YYYY-MM-DD', v) || '-'
                    },
                    bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
                    bindInConvert: v => toDate.str14ToDate(v),
                },
                {
                    label: '实际验收日期',
                    bind: 'acceptanceDate',
                    required: acceptanceDateRequired,
                    clear: true,
                    component: DatePicker,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                        return dateFormat('YYYY-MM-DD', v) || '-'
                    },
                    bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
                    bindInConvert: v => toDate.str14ToDate(v),
                },
                {
                    label: '预计周期',
                    bind: 'planDay',
                    disabled: true,
                    component: Display,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return v + '天'
                    }
                },
                {
                    label: '计划工时',
                    bind: 'useday',
                    disabled: true,
                    component: Display,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return v + '人日'
                    }
                },
                {
                    label: '挂起时长',
                    bind: 'pauseTime',
                    type: 'number',
                    digit: 1,
                    min: 0,
                    suffix: '天',
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return v + '天'
                    }
                },
                {
                    label: '时间消耗',
                    bind: 'timeUsed',
                    disabled: true,
                    component: Display,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return _.ceil(_.toNumber(v) * 100, 2) + '%'
                    }
                },
                {
                    label: '提升比例',
                    bind: 'improvePercent',
                    disabled: true,
                    component: Display,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return _.ceil(_.toNumber(v) * 100, 2) + '%'
                    }
                },
                {
                    label: '关联版本',
                    bind: 'releaseId',
                    component: Select,
                    clear: true,
                    search: true,
                    options: associatedVersionOpt,
                    convert: v => convertOptions(v, associatedVersionOpt),
                },
                {
                    label: '实施项目组',
                    bind: 'implementationProjectTeam',
                    maxLength: 50,
                },
                {
                    label: '项目立项日期',
                    bind: 'approvalDate',
                    clear: true,
                    required: isNotYet ? false : true,
                    component: DatePicker,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                        return dateFormat('YYYY-MM-DD', v) || '-'
                    },
                    bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
                    bindInConvert: v => toDate.str14ToDate(v),
                },
                {
                    label: '项目归档日期',
                    bind: 'filingDate',
                    clear: true,
                    component: DatePicker,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                        return dateFormat('YYYY-MM-DD', v) || '-'
                    },
                    bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
                    bindInConvert: v => toDate.str14ToDate(v),
                },
                // {
                //     label: '流程符合度得分',
                //     bind: 'processComplianceScore',
                // },
                // {
                //     label: '流程符合评分日期',
                //     bind: 'processComplianceDate',
                //     clear: true,
                //     component: DatePicker,
                //     convert: v => {
                //         if (_.isNil(v)) return '-'
                //         if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                //         return dateFormat('YYYY-MM-DD', v) || '-'
                //     },
                //     bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
                //     bindInConvert: v => toDate.str14ToDate(v),
                // },
                {
                    label: '问题细分',
                    bind: 'delayproblemCategory',
                    component: Select,
                    clear: true,
                    search: true,
                    options: delayproblemCategoryOpt,
                    convert: v => convertOptions(v, delayproblemCategoryOpt),
                },
                {
                    label: '项目文档归档目录',
                    bind: 'archiveDirectory',
                    maxLength: 1000,
                    rowSpan: 3,
                    component: TextArea,
                    counter: false,
                    className: 'configuration-long-text',
                    componentClass: 'long-text'
                },
            ],
            /**
                {
                    label: 'OA核准含税价格',
                    bind: 'approvedPrice',
                    disabled: true,
                    component: Display,
                },
                {
                    label: 'OA销售承诺签约日期',
                    bind: 'commitmentSigningDate',
                    disabled: true,
                    component: Display,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                        return dateFormat('YYYY-MM-DD', v) || '-'
                    },
                },
                {
                    label: 'OA配置清单',
                    bind: 'configuration',
                    disabled: true,
                    component: Display,
                },
             */
        },
        {
            bind: 'costcInfo',
            name: '成本信息',
            children: [
                {
                    label: 'PSA项目ID',
                    bind: 'psaid',
                    disabled: true,
                    component: Display,
                },
                {
                    label: '核价单号',
                    bind: 'priceId',
                    disabled: true,
                    component: Display,
                },
                {
                    label: 'OA核准含税价格',
                    bind: 'approvedPrice', //'budgetRevTax',
                    disabled: true,
                    component: Display,
                },
                {
                    label: '销售承诺签约日',
                    bind: 'commitmentSigningDate',
                    disabled: true,
                    component: Display,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                        return dateFormat('YYYY-MM-DD', v) || '-'
                    },
                },
                {
                    label: 'OA配置清单',
                    bind: 'configuration',
                    disabled: true,
                    component: Display,
                    componentWidth: 822,
                    className: 'configuration-long-text',
                    componentClass: 'long-text-detail'
                },
                {
                    label: '销售负责人',
                    bind: 'sales',
                    disabled: true,
                    component: Display,
                    convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount')
                },
                {
                    label: '商机名称',
                    bind: 'businessName',
                    component: Display,
                    disabled: true,
                    componentWidth: 486,
                },
                {
                    label: '商机编号',
                    bind: 'salesProjectId',
                    disabled: true,
                    component: Display,
                },
                {
                    label: '合同编号',
                    bind: 'contractNum',
                    disabled: true,
                    component: Display,
                },
                {
                    label: '合同名称',
                    bind: 'contractName',
                    componentWidth: 486,
                    disabled: true,
                    component: Display,
                },
                {
                    label: '合同签订日期',
                    bind: 'signDate',
                    disabled: true,
                    component: Display,
                    convert: v => dateFormat('YYYY-MM-DD', toDate.str14ToDate(v)) || '-',
                },
                {
                    label: '合同总金额',
                    bind: 'totalContractAmount',
                    type: 'number',
                    suffix: '元',
                    digit: 2,
                    component: Display,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return N2(v) + '元'
                    }
                },
                {
                    label: '是否参与运营统计',
                    bind: 'operationStatistics',
                    component: Select,
                    options: operationStatisticsOpt,
                    clear: true,
                    convert: v => convertOptions(v, operationStatisticsOpt)
                },
                {
                    label: '已经收款金额',
                    bind: 'deliveredAmount',
                    type: 'number',
                    suffix: '元',
                    digit: 2,
                    component: Display,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return N2(v) + '元'
                    }
                },
                {
                    label: '未收款金额',
                    bind: 'undeliveredAmount',
                    type: 'number',
                    component: Display,
                    convert: v => {
                        if (_.isNil(v) || _.isNaN(v)) return '-'
                        return N2(v) + '元'
                    }
                },
                {
                    label: '未达到条件金额',
                    bind: 'amountNotMeetingConditions',
                    type: 'number',
                    component: Display,
                    convert: v => {
                        const total = _.isNaN(_.toNumber(totalContractAmount)) ? 0 : _.toNumber(totalContractAmount) //合同总金额
                        const money = _.isNaN(_.toNumber(amountMeetingConditions)) ? 0 : _.toNumber(amountMeetingConditions) //已达到条件金额
                        return `${total - money}元`
                    }
                },
                {
                    label: '已达到条件金额',
                    bind: 'amountMeetingConditions',
                    type: 'number',
                    suffix: '元',
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return N2(v) + '元'
                    }
                },
                {
                    label: '已开票金额',
                    bind: 'invoicedAmount',
                    type: 'number',
                    suffix: '元',
                    component: Display,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return N2(v) + '元'
                    }
                },
                {
                    label: '未开票金额',
                    bind: 'invoiceAmount',
                    type: 'number',
                    component: Display,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return N2(v) + '元'
                    }
                },
                {
                    label: '合同付款条款总数',
                    bind: 'contractPayment',
                    type: 'number',
                    convert: v => {
                        if (_.isNil(v) || _.isNaN(v)) return '-'
                        return v
                    }
                },
                {
                    label: '当前执行付款阶段',
                    bind: 'executionPaymentStage',
                    type: 'number',
                    convert: v => {
                        if (_.isNil(v) || _.isNaN(v)) return '-'
                        return v
                    }
                },
                {
                    label: '当前里程碑描述',
                    bind: 'historySteleDescription',
                    convert: v => {
                        if (_.isNil(v) || _.isNaN(v)) return '-'
                        return v
                    }
                },
                {
                    label: '预估总成本',
                    bind: 'budgetCost',
                    disabled: true,
                    component: Display,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return N2(v) + '元'
                    }
                },
                {
                    label: '实际成本',
                    bind: 'usedCost',
                    disabled: true,
                    component: Display,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return N2(v) + '元'
                    }
                },
                {
                    label: '未来投入',
                    bind: 'residualCost',
                    type: 'number',
                    suffix: '元',
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return N2(v) + '元'
                    }
                },
                {
                    label: '投入总成本',
                    bind: 'totalCost',
                    disabled: true,
                    component: Display,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return N2(v) + '元'
                    }
                },
                {
                    label: '节约比例',
                    bind: 'savingRatio',
                    disabled: true,
                    component: Display,
                    convert: v => {
                        if (_.isNil(v)) return '-'
                        return _.ceil(_.toNumber(v) * 100, 2) + '%'
                    }
                },
            ],
        },
        {
            bind: 'desccInfo',
            name: '描述信息',
            children: [
                {
                    label: '项目进展',
                    bind: 'progression',
                    rowSpan: 1,
                    component: TextArea,
                    maxLength: 8000,
                    counter: false,
                    // componentWidth: 792,
                    componentClass: 'long-text'
                },
                {
                    label: '客户经理跟进',
                    bind: 'managerFollow',
                    rowSpan: 1,
                    component: TextArea,
                    maxLength: 8000,
                    counter: false,
                    // componentWidth: 792,
                    componentClass: 'long-text'
                },
                {
                    label: '需上级协调工作',
                    bind: 'superiorCoordination',
                    rowSpan: 1,
                    component: TextArea,
                    maxLength: 4000,
                    counter: false,
                    // componentWidth: 792,
                    componentClass: 'long-text'
                },
                {
                    label: '合同交付内容',
                    bind: 'contractDeliveryDesc',
                    rowSpan: 1,
                    component: TextArea,
                    maxLength: 4000,
                    counter: false,
                    // componentWidth: 792,
                    componentClass: 'long-text'
                },
                {
                    label: '付款里程碑',
                    bind: 'analysisExplain',
                    rowSpan: 1,
                    component: TextArea,
                    maxLength: 10000,
                    counter: false,
                    // componentWidth: 792,
                    componentClass: 'long-text'
                },
                {
                    label: '交付策略说明',
                    bind: 'deliveTactics',
                    rowSpan: 1,
                    component: TextArea,
                    maxLength: 10000,
                    counter: false,
                    // componentWidth: 792,
                    componentClass: 'long-text'
                },
                {
                    label: '风险说明',
                    bind: 'riskExplain',
                    rowSpan: 1,
                    component: TextArea,
                    maxLength: 500,
                    counter: false,
                    // componentWidth: 792,
                    componentClass: 'long-text'
                },
                {
                    label: '问题说明',
                    bind: 'delayExplain',
                    rowSpan: 1,
                    component: TextArea,
                    maxLength: 500,
                    counter: false,
                    // componentWidth: 792,
                    componentClass: 'long-text'
                },
                {
                    label: '备注',
                    bind: 'comment',
                    rowSpan: 1,
                    component: TextArea,
                    maxLength: 10000,
                    counter: false,
                    // componentWidth: 792,
                    componentClass: 'long-text'
                },
            ],
        },
    ]
}

/* 
        {
             label: '项目ID',
             bind: 'id',
             disabled: true,
             component: Display,
         },
         {
             label: '项目名称',
             bind: 'projectName',
             required: true,
             componentWidth: 486,
         },
        {
             label: '项目经理',
             bind: 'principal',
             component: UserSelect,
             allowClear: false,
             required: true,
             convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount')
         },
         {
             label: '项目状态',
             bind: 'projectStatus',
             component: Select,
             required: true,
             options: _.get(convertCollection, 'ImplementProject.ProjectStatus'),
             convert: v => convertOptions(v, _.get(convertCollection, 'ImplementProject.ProjectStatus')),
         },
         {
             label: '项目进度',
             bind: 'projectProgress',
             disabled: true,
             component: Display,
             convert: v => {
                 if (_.isNil(v)) return '-'
                 return _.ceil(_.toNumber(v) * 100, 2) + '%'
             }
         },
        {
             label: '项目简介',
             bind: 'projectDesc',
             rowSpan: 1,
             component: TextArea,
             maxLength: 10000,
             counter: false,
             componentWidth: 792,
             componentClass: 'long-text'
         },
         */