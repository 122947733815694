import React, { useCallback, useMemo, useState } from 'react';
import './WorkTimeReportHandleRow.scss'
import moment from "moment";
import {
    EditOutlined,
    LeftOutlined,
    RightOutlined,
    SearchOutlined,
    SyncOutlined,
    SaveOutlined,
    CloseOutlined,
    CloseCircleOutlined
} from "@ant-design/icons";
import { Button, DatePicker, Popover, Tooltip } from "antd";
import PersonSelectPop from "../../../common/personSelectPop/PersonSelectPop";
import convertOptions from "../../../common/ConvertOptions";
import _ from "lodash";
import { useFuncCode } from "../../../common/commonMethod";
import clsx from "clsx";
import { isNil } from '../../../appraise/components/method';

function WorkTimeReportHandleRow(props) {
    const { selectedDate, setSelectedDate, othersReport, setOthersReport, mode, setMode, editData, initData, setEditData,
        refreshList, submit, workDaysRes, isDisplay, submitLoading } = props
    const outerAllUserRes = JSON.parse(localStorage.getItem('all_user'))
    const [popVisible, setPopVisible] = useState(false)
    const [canSelectOthers] = useFuncCode(['700101'])

    const onDateSelected = useCallback(date => {
        setSelectedDate(date)
    }, [setSelectedDate])

    const cutOffDate = useMemo(() => {
        return moment(selectedDate).endOf('isoWeek').add(28, 'days').format('YYYY-MM-DD')
    }, [selectedDate])

    const canEdit = useMemo(() => {
        return moment(cutOffDate).isSameOrAfter(moment().format('YYYY-MM-DD'))
    }, [cutOffDate])

    const canSubmit = useMemo(() => {
        const currentTotal = _.sum(_.map(_.filter(editData, o => _.toNumber(_.get(o, 'weeklyInputPercentage')) > 0 && !isNil(_.get(o, 'projectId'))), x => _.toNumber(_.get(x, 'weeklyInputPercentage')) || 0))
        const filterData = _.filter(editData, o => _.get(o, 'type') !== 'transaction')
        const isType = _.every(filterData, o => {
            if ((_.toNumber(_.get(o, 'weeklyInputPercentage')) || 0) <= 0) return true
            return (_.toNumber(_.get(o, 'weeklyInputPercentage')) || 0) > 0 && (!isNil(_.get(o, 'projectId')) && !isNil(_.get(o, 'workingProjectType')))
        })
        return (currentTotal === 100 || currentTotal === 0) && isType
    }, [editData])

    const disabledDate = useCallback((date) => {
        if (_.isNil(_.get(workDaysRes, 'leaveDate'))) {
            return date > moment().add(7, 'days').endOf('isoWeek')
        } else {
            return (date > moment().endOf('isoWeek')) || (date > moment(_.get(workDaysRes, 'leaveDate')).endOf('isoWeek'))
        }
    }, [workDaysRes])

    const nextWeekDisabled = useMemo(() => {
        const lastDay = moment(selectedDate).endOf('isoWeek').format('YYYY/MM/DD')
        const currentLastDay = moment().add(7, 'days').endOf('isoWeek').format('YYYY/MM/DD')
        return lastDay === currentLastDay
    }, [selectedDate])

    return <div className={'work-time-report-handle-row flex center-y space-between'}>
        <div className="date-selected-left-wrap flex center-y">
            <DatePicker value={selectedDate} onChange={onDateSelected} picker="week" format={formatWeekDate}
                popupClassName={'work-time-report-week-pop'} allowClear={false} style={{ width: 210 }}
                disabledDate={disabledDate}
            />
            <div className="week-switch-wrap flex center previous-week" onClick={() => {
                setSelectedDate(old => moment(old).subtract(7, 'days'))
            }}>
                <LeftOutlined />
            </div>
            <div className={clsx("week-switch-wrap flex center", { disabled: nextWeekDisabled })} onClick={() => {
                if (nextWeekDisabled) return
                setSelectedDate(old => moment(old).add(7, 'days'))
            }}>
                <RightOutlined />
            </div>
            <div className="this-week-btn flex center" onClick={() => setSelectedDate(moment())}>
                本 周
            </div>
            <div className="workday-total">
                {_.get(workDaysRes, 'numberWorkingDays') || '-'}个工作日
            </div>
            <div className={'refresh-icon'} onClick={refreshList}>
                <SyncOutlined />
            </div>
        </div>
        {
            !isDisplay &&
            <div className="date-selected-right-wrap flex center-y">
                {
                    canSelectOthers &&
                    <div className="others-report-btn">
                        <Popover content={<PersonSelectPop defaultValue={othersReport} onSelectConfirm={setOthersReport} setVisible={setPopVisible} allowClear={true} />}
                            trigger="click" overlayClassName={'user-select-pop'}
                            destroyTooltipOnHide={true} zIndex={9998} placement="bottom"
                            open={popVisible} onOpenChange={setPopVisible}
                        >
                            <Button icon={<SearchOutlined />}>
                                代提{othersReport ? `：${convertOptions(othersReport, outerAllUserRes, 'userName', 'userAccount')}` : ''}
                                {
                                    !_.isNil(othersReport) &&
                                    <CloseCircleOutlined className="others-report-cancel" onClick={e => {
                                        e.stopPropagation()
                                        setOthersReport(null)
                                    }} />
                                }
                            </Button>
                        </Popover>
                    </div>
                }
                <div className="deadline-date">
                    提报截止日期： {cutOffDate}
                </div>
                {
                    mode === 'detail' &&
                    <Button icon={<EditOutlined />} type="primary" disabled={!canEdit} onClick={() => setMode('edit')}>编辑工时</Button>
                }
                {
                    mode === 'edit' &&
                    <Button icon={<CloseOutlined />} onClick={() => {
                        setEditData(_.cloneDeep(initData))
                        setMode('detail')
                    }}>取消</Button>
                }
                {
                    mode === 'edit' &&
                    <Tooltip title={canSubmit ? null : "工时合计不等于100%或未填类型，不允许提交"} placement={'topRight'}>
                        <Button
                            icon={<SaveOutlined />}
                            type="primary"
                            onClick={submit}
                            disabled={!canSubmit || !canEdit || submitLoading}
                        >
                            提报
                        </Button>
                    </Tooltip>
                }
            </div>
        }
    </div>
}

function formatWeekDate(date) {
    const startDate = moment(date).startOf('isoWeek').format('YYYY/MM/DD')
    const endDate = moment(date).endOf('isoWeek').format('YYYY/MM/DD')
    return startDate + ' - ' + endDate
}

export default WorkTimeReportHandleRow;