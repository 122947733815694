import React, { useCallback, useContext, useMemo, useState } from 'react'
import _ from 'lodash'
import { DataGrid, Messager, Pagination } from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import useGetDateOptions from '../../../../common/view/hooks/useGetDateOptions'
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig'
import SalesCatalogueAddDialog from './components/salesCatalogueAddDialog'
import convertTableAlign from '../../../../common/view/convertTableAlign'
import useRefreshList from '../../../../common/view/hooks/useRefreshList'
import useGetTreeList from '../../../../common/view/hooks/useGetTreeList'
import { ScheduleManagementContext } from '../../../../common/Context'
import findConvert from '../../../../common/view/findConvert'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import { Box } from '../../../../common/commonComponent'
import ViewArea from '../../../../common/view/ViewArea'
import './index.scss'
import { Popconfirm } from 'antd'

const ENTER_DETAIL_FIELD = 'story.title'

const DELETE_URL = ''

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo } = props

  const customColumns = []

  return [
    { selection: true },
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: fieldItem.fieldId !== ENTER_DETAIL_FIELD,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (tableField === ENTER_DETAIL_FIELD) {
      return <div className='enter-detail-field-text'
        onClick={() => {
          setCurrentInfo({ mode: 'detail', id: o.id })
        }}
      >{showValue}</div>
    }
    return <div className={'common-display-field'}>
      {showValue || '-'}
    </div>
  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '1501'

export default function SalesCatalogue() {
  const [currentInfo, setCurrentInfo] = useState()
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [factor, setFactor] = useState()
  const { data: allowExport } = useGet(`/FuncTable/getAllowExportFlag?funcCode=` + FUNC_CODE)
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [isDisable, setIsDisable] = useState(false)
  const [selectList, setSelectList] = useState([])
  const { doFetch: deleteFetch } = usePost()

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, })

  const refresh = useCallback(() => {
    refreshList()
    setSelectList([])
  }, [refreshList])

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { setCurrentInfo } })

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, '销售产品目录')
  }, [currentViewId, fieldList, factor, downloadPostProgress])

  const delItems = useCallback(() => {
    if (isDisable) return
    setIsDisable(true)
    const ids = _.map(selectList, 'id')
    deleteFetch(DELETE_URL, ids)
      .then(() => {
        setSelectList([])
        setIsDisable(false)
        Messager.show('删除成功！', { icon: 'success' })
        refreshList()
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [isDisable, selectList, deleteFetch, refreshList])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <Popconfirm
        title='提示'
        onConfirm={delItems}
        overlayInnerStyle={{ padding: 8 }}
      >
        <TextIconBtn icon={'shanchu'} text='删除' disabled={_.isEmpty(selectList)} />
      </Popconfirm>
      <TextIconBtn icon={'daoru'} text='导入' />
      {allowExport && <TextIconBtn icon={'daochu'} text='导出' onClick={exportViewList} />}
      <TextIconBtn icon={'tianjia'} text='新增' onClick={() => setCurrentInfo({ mode: 'add' })} />
    </div>
  }, [delItems, selectList, allowExport, exportViewList])

  return (
    <div className='sales-catalogue flex-y'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList: refresh, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'销售产品目录'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} selection={selectList} onSelectionChange={setSelectList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.includes(['add', 'detail'], _.get(currentInfo, 'mode')) &&
        <SalesCatalogueAddDialog
          close={() => setCurrentInfo(null)}
          {...{ refreshList: refresh, ...currentInfo }}
        />
      }
    </div>
  )
}
