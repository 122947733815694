import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './CustomerInfo.scss'
import _ from "lodash";
import { Display, Form, FormInput, Input, TextArea, Select } from "rootnet-edit";
import UserSelect from "../../../../common/personSelectPop/UserSelect";
import convertOptions from "../../../../common/ConvertOptions";
import EditableEmailList from "../editableEmailList/EditableEmailList";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";
import { Icon } from '../../../../../components';
import { Input as AntdInput, Popover, Tooltip } from 'antd'
import { isNil } from '../../../../appraise/components/method';

const HFormInput = props => <FormInput horizontal labelWidth={110} componentWidth={180} {...props} />

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=status',
    'common/globalconst?globalConst=industry',
    'common/globalconst?globalConst=area',
    'common/globalconst?globalConst=deploymentMethod', //部署方式 versionMailTitle
    'common/globalconst?globalConst=versionMailTitle',
    'common/globalconst?globalConst=database', //客户数据库类型
]

const OPTIONS_URLS = [
    '/customer/customerList'
]

const GXH_TIP = <span style={{ display: 'inline-block' }}>
    客户个性化设定
    <Tooltip title='实施和客服维护，产研，用于使用需求分析和出厂测试的参考'>
        <span><Icon name='bangzhu' style={{ fontSize: 14, color: '#5477FF', marginLeft: 8 }} /></span>
    </Tooltip>
</span>

function CustomerInfo(props) {
    const { editFormData, setEditFormData, infoError, setInfoError, isDetail, allUserRes, configurationTopic } = props
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: optionsRes } = useGet(OPTIONS_URLS)
    const [emailSubjectData, setEmailSubjectData] = useState([])
    const [value, setValue] = useState()
    const [hovered, setHovered] = useState()

    const [statusOptions, industryOptions, areaOptions, deploymentMethodOptions, emailSubjectOpt, databaseTypeOpt] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    useEffect(() => {
        setEmailSubjectData(() => {
            if (isNil(configurationTopic)) return []
            return _.map(_.split(configurationTopic, ','), v => {
                const findItem = _.find(emailSubjectOpt, o => o.value === v)
                if (findItem) return findItem
                return { text: v, value: v }
            })
        })
    }, [emailSubjectOpt, configurationTopic])

    const changeForm = useCallback((newObj, bind) => {
        setEditFormData(oldObj => {
            let cloneObj = { ...oldObj, ...newObj }
            return cloneObj
        })
    }, [setEditFormData])

    const [customerOptions] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [d1] = optionsRes
        return [
            _.map(d1, x => ({ text: x.tCustname, value: x.tCustid, tag: `${x.tCustname}${x.tCustid}` }))
        ]
    }, [optionsRes])

    const addItem = useCallback((index) => {
        setEmailSubjectData(x => {
            const data = _.map(x, o => o)
            data.splice(index, 0, { edit: true })
            return data
        })
    }, [])

    const upDateItem = useCallback((index) => {
        setEmailSubjectData(x => {
            if (isNil(value)) return _.filter(x, (o, i) => i !== index)
            return _.map(x, (o, i) => {
                if (i === index) {
                    return {
                        text: value,
                        value: value,
                    }
                }
                return o
            })
        })
        setValue('')
    }, [value])

    const delItem = useCallback((index) => {
        setEmailSubjectData(x => _.filter(x, (o, i) => i !== index))
    }, [])

    const Content = useCallback(({ index }) => {
        const filterData = _.filter(emailSubjectOpt, o => !_.includes(_.map(emailSubjectData, 'value'), o.value))
        return _.map(_.concat(filterData, [{ text: '自定义', value: 'custom' }]), o => {
            return <div className='add-popover-item' key={o.value} onClick={() => {
                if (o.value === 'custom') addItem(index)
                else {
                    setEmailSubjectData(x => {
                        const data = _.map(x, o => o)
                        data.splice(index, 0, o)
                        return data
                    })
                }
                setHovered(false)
            }} >{o.text}</div>
        })
    }, [addItem, emailSubjectData, emailSubjectOpt])

    useEffect(() => {
        setEditFormData(x => _.assign({}, x, { configurationTopic: _.join(_.map(emailSubjectData, 'value'), ',') }))
    }, [emailSubjectData, setEditFormData])

    return <div className={'customer-info-wrap flex'}>
        <div className={'customer-info'}>
            <Form value={editFormData} onChange={changeForm} error={infoError} onError={v => setInfoError(x => _.assign({}, x, v))}>
                <HFormInput label={'系统名称'} bind={'projectName'} required component={isDetail ? Display : Input} />
                <HFormInput label={'客户名称'} bind={'tcustId'} required component={isDetail ? Display : Select} options={customerOptions} convert={v => convertOptions(v, customerOptions)} />
                <HFormInput label={'状态'} bind={'status'} required component={isDetail ? Display : Select} options={statusOptions} convert={v => convertOptions(v, statusOptions)} />
                <HFormInput label={'地区'} bind={'area'} required component={isDetail ? Display : Select} options={areaOptions} convert={v => convertOptions(v, areaOptions)} />
                <HFormInput label={'用户名'} bind={'userName'} required component={isDetail ? Display : Input} />
                <HFormInput label={'密码'} bind={'userPwd'} required component={isDetail ? Display : Input} />
                <HFormInput label={'行业'} bind={'tcusttype'} required component={isDetail ? Display : Select} options={industryOptions} convert={v => convertOptions(v, industryOptions)} />
                <HFormInput label={'部署方式'} bind={'deploymentMethod'} required component={isDetail ? Display : Select} options={deploymentMethodOptions} convert={v => convertOptions(v, deploymentMethodOptions)} />
                <HFormInput label={'客服人员'} bind={'supportUser'} component={isDetail ? Display : UserSelect} allowClear
                    convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                <HFormInput label={'第二客服'} bind={'supportUser2'} component={isDetail ? Display : UserSelect} allowClear
                    convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                <HFormInput
                    clear
                    multiple
                    bind={'databaseType'}
                    label={'客户数据库类型'}
                    options={databaseTypeOpt}
                    bindInConvert={v => _.split(v, ',')}
                    bindOutConvert={v => _.join(v, ',')}
                    component={isDetail ? Display : Select}
                    convert={arr => {
                        if (_.isEmpty(arr)) return
                        return _.join(_.map(arr, v => convertOptions(v, databaseTypeOpt)), '，')
                    }}
                />
                {/*<HFormInput label={'创建人'} bind={'createUser'} component={Display} convert={v => convertOptions(v, allUserRes,'userName','userAccount')}/>*/}
                {/*<HFormInput label={'创建时间'} bind={'createTime'} component={Display}*/}
                {/*            convert={v => {*/}
                {/*                if(_.isNil(v)) return '-'*/}
                {/*                if(_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))*/}
                {/*                return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'*/}
                {/*            }}*/}
                {/*/>*/}
                {/*<HFormInput label={'修改人'} bind={'updateUser'} component={Display} convert={v => convertOptions(v, allUserRes,'userName','userAccount')}/>*/}
                {/*<HFormInput label={'修改时间'} bind={'updateTime'} component={Display}*/}
                {/*            convert={v => {*/}
                {/*                if(_.isNil(v)) return '-'*/}
                {/*                if(_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))*/}
                {/*                return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'*/}
                {/*            }}*/}
                {/*/>*/}
                <HFormInput label={'版本内部网络地址'} bind={'internalPath'} required horizontal className={'one-line'} componentClass={'extend-component'}
                    component={isDetail ? Display : Input} labelWidth={150} componentWidth={432} />
                <HFormInput label={'版本发布主要地址'} bind={'releasePath'} required horizontal className={'one-line'} componentClass={'extend-component'}
                    component={isDetail ? Display : Input} labelWidth={150} componentWidth={432} />
                {
                    <div className='email-subject-forminput flex'>
                        <div className='email-subject-label' style={{ width: 150 }}>发版邮件主题</div>
                        <div className='email-subject-component flex'>
                            {
                                !isDetail && _.isEmpty(emailSubjectData) &&
                                <Popover
                                    overlayClassName='email-subject-popover'
                                    placement="bottom"
                                    open={hovered}
                                    trigger="click"
                                    content={<Content index={0} />}
                                    onOpenChange={setHovered}
                                >
                                    <Icon name='tianjia' className='add-icon' onClick={() => setHovered(true)} />
                                </Popover>
                            }
                            {
                                !_.isEmpty(emailSubjectData) &&
                                _.map(emailSubjectData, (o, i) => {
                                    if (o?.edit) {
                                        return <div style={{ marginLeft: 8 }} key={i}>
                                            <AntdInput
                                                autoFocus
                                                size='small'
                                                value={value}
                                                onChange={e => setValue(e.target.value)}
                                                onPressEnter={() => upDateItem(i)}
                                                onBlur={() => upDateItem(i)}
                                            />
                                        </div>
                                    }
                                    return <div key={i} className={`flex email-subject-order-item-all ${isDetail ? 'email-subject-order-item-all-detail' : ''}`}>
                                        <Popover
                                            overlayClassName='email-subject-popover'
                                            placement="bottom"
                                            open={`${i}before` === hovered}
                                            trigger="click"
                                            content={<Content index={i} />}
                                            onOpenChange={open => {
                                                if (open) setHovered(`${i}before`)
                                                else setHovered(false)
                                            }}
                                        >
                                            {!isDetail && <Icon name='tianjia' className='add-before' onClick={() => setHovered(`${i}before`)} />}
                                        </Popover>
                                        <div className='flex email-subject-order-item'>
                                            <div className='email-subject-text'>{o.text}</div>
                                            {!isDetail && <Icon name='quxiao' onClick={() => delItem(i)} />}
                                        </div>
                                        <Popover
                                            overlayClassName='email-subject-popover'
                                            placement="bottom"
                                            open={`${i}after` === hovered}
                                            trigger="click"
                                            content={<Content index={i + 1} />}
                                            onOpenChange={open => {
                                                if (open) setHovered(`${i}after`)
                                                else setHovered(false)
                                            }}
                                        >
                                            {!isDetail && < Icon name='tianjia' className='add-after' onClick={() => setHovered(`${i}after`)} />}
                                        </Popover>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                }
                <div className={'email-form-wrap flex'}>
                    <HFormInput label={'主邮件列表'} labelWidth={150} required componentWidth={0} className={'mail-form'}
                        component={Display} convert={() => ""} />
                    <EditableEmailList bind={'emailList'} {...{ setEditFormData, isDetail, editFormData }} />
                </div>
                <div className={'email-form-wrap flex'}>
                    <HFormInput label={'CC邮件列表'} labelWidth={150} required componentWidth={0} className={'mail-form'}
                        component={Display} convert={() => ""} />
                    <EditableEmailList bind={'cclist'} {...{ setEditFormData, isDetail, editFormData }} />
                </div>
                {
                    !isDetail &&
                    <HFormInput label={'客户项目风险'} bind={'projectrisk'} component={TextArea} placeholder={isDetail ? '' : ' 请输入'} labelWidth={150} maxLength={2048} className={'one-line'}
                        readOnly={isDetail} counter={!isDetail} componentClass={`memo-textarea extend-component ${isDetail ? 'memo-textarea-detail' : ''}`} />
                }
                {
                    isDetail &&
                    <div className={'flex textarea-detail-wrap'}>
                        <HFormInput label={'客户项目风险'} bind={'projectrisk'} labelWidth={150} componentWidth={0} component={Display} convert={() => ""} />
                        <div className={'flex textarea-detail-text'}>
                            {_.get(editFormData, 'projectrisk')}
                        </div>
                    </div>
                }
                {
                    !isDetail &&
                    <HFormInput label={'特别说明'} bind={'memo'} component={TextArea} placeholder={isDetail ? '' : ' 请输入'} labelWidth={150} maxLength={2048} className={'one-line'}
                        readOnly={isDetail} counter={!isDetail} componentClass={`memo-textarea extend-component ${isDetail ? 'memo-textarea-detail' : ''}`} />
                }
                {
                    isDetail &&
                    <div className={'flex textarea-detail-wrap'}>
                        <HFormInput label={'特别说明'} bind={''} labelWidth={150} componentWidth={0} component={Display} convert={() => ""} />
                        <div className={'flex textarea-detail-text'}>
                            {_.get(editFormData, 'memo')}
                        </div>
                    </div>
                }
                {
                    !isDetail &&
                    <HFormInput label={GXH_TIP} bind={'personalizedSetting'} component={TextArea} placeholder={isDetail ? '' : ' 请输入'} labelWidth={150} maxLength={2048} className={'one-line'}
                        readOnly={isDetail} counter={!isDetail} componentClass={`memo-textarea extend-component ${isDetail ? 'memo-textarea-detail' : ''}`} />
                }
                {
                    isDetail &&
                    <div className={'flex textarea-detail-wrap'}>
                        <HFormInput label={GXH_TIP} bind={''} labelWidth={150} componentWidth={0} component={Display} convert={() => ""} />
                        <div className={'flex textarea-detail-text'}>
                            {_.get(editFormData, 'personalizedSetting')}
                        </div>
                    </div>
                }
            </Form>
        </div>
    </div>
}

export default CustomerInfo;