import React, { useState, useMemo, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { FormInput, Select } from 'rootnet-edit'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Dialog, Button, DataGrid, Pagination, Messager } from 'rootnet-ui'
import findConvert from '../../../../../../../common/view/findConvert'
import convertTableAlign from '../../../../../../../common/view/convertTableAlign'
import useGetViewConfig from '../../../../../../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../../../../../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../../../../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../../../../../common/view/hooks/useGetTreeList'
import { isNil } from '../../../../../../../appraise/components/method'
import { strParams } from '../../../../../../../../utils/publicFun'
import { Box } from '../../../../../../../common/commonComponent'
import ViewArea from '../../../../../../../common/view/ViewArea'
import './index.scss'

const RELEVANCE_ISSUE = '/issue/relationIssueDevProject'
const RELEVANCE_REQ = '/implement/project/related/requirement'
const RELEVANCE_DEFECT = '/testDefect/addProjectTestDefectList'

const Header = ({ num = 0 }) => {
  return <div>
    <span>回归版本导入</span>
    {
      num > 0 && <span style={{ color: '#BBBBBB', fontWeight: 'normal', paddingLeft: 8 }}>（已选{num}个）</span>
    }
    <span style={{ color: '#BBBBBB', fontWeight: 'normal', paddingLeft: 8 }}>回归到当前项目的版本</span>
  </div>
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions } = props

  const customColumns = []

  return [
    { selection: true },
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }
  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    // const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    // if (tableField === 'View_ReturnToVersion_Task.tasktype') {
    //   o['type'] = v
    // }
    // if (tableField === 'View_ReturnToVersion_Task.storyId') {
    //   o['storyId'] = v
    // }
    return showValue || '-'
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '1623'

export default function ImportRegressionVersion(props) {
  const { id, projectId, versionList, close, refreshList: refresh, initId } = props
  const [selectedList, setSelectedList] = useState()
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const [versionId, setVersionId] = useState()
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const { doFetch } = usePost()
  const { doFetch: getSelectFetch, data: selectRes } = useGet()
  const [tipLog, setTiplog] = useState()


  useEffect(() => {
    if (_.isEmpty(fieldList) || _.isEmpty(list)) return
    const findType = _.find(fieldList, (o) => {
      const tableField = `${o.tableName}.${o.fieldId}`
      return tableField === 'View_ReturnToVersion_Task.tasktype'
    })
    const findStoryId = _.find(fieldList, (o) => {
      const tableField = `${o.tableName}.${o.fieldId}`
      return tableField === 'View_ReturnToVersion_Task.storyId'
    })
    const findFixProjectId = _.find(fieldList, (o) => {
      const tableField = `${o.tableName}.${o.fieldId}`
      return tableField === 'View_ReturnToVersion_Task.fixProjectId'
    })
    _.forEach(list, (o) => {
      if (_.includes(initId, o.id)) o['_selectable'] = false
      o['type'] = _.get(o, _.get(findType, 'columnId'))
      o['storyId'] = _.get(o, _.get(findStoryId, 'columnId'))
      const fixProjectId = _.get(o, _.get(findFixProjectId, 'columnId'))
      if (!isNil(fixProjectId)) {
        const fixProjectIdList = _.split(fixProjectId, ',')
        o['_selectable'] = !_.includes(fixProjectIdList, projectId)
      }
    })
  }, [list, fieldList, projectId, initId])

  const appendObj = useMemo(() => {
    if (isNil(versionId)) return {}
    return { factorViewSql: `(ISNULL(View_ReturnToVersion_Task.defectMark,'N')='Y' or ISNULL( View_ReturnToVersion_Task.defectMark2, 'N' ) = 'Y') and (View_ReturnToVersion_Task.releaseId = '${versionId}')` }
  }, [versionId])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const versionListOpt = useMemo(() => {
    if (_.isEmpty(selectRes)) return []
    return _.map(selectRes, v => ({ value: v, text: v }))
  }, [selectRes])

  useEffect(() => {
    getSelectFetch(`/releaseinfo/getReturnReleases?releaseIdList=${_.map(versionList, 'releaseID')}`)
  }, [versionList, getSelectFetch])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, appendObj })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns })

  const [reqList, issueList, defectList] = useMemo(() => {
    return [
      _.filter(selectedList, o => o.type === 'REQ'),
      _.filter(selectedList, o => o.type === 'ISSUE'),
      _.filter(selectedList, o => o.type === 'BUG'),
    ]
  }, [selectedList])

  const submit = useCallback(() => {
    if (isDisable) return
    setIsDisable(true)
    const issueParams = {
      id,
      userProjectId: projectId,
      issueIds: _.map(issueList, 'id')
    }
    const reqParams = {
      id,
      projectId,
      projectType: 'DEV',
      funcCode: 36,
    }
    const defectParams = {
      projectId: id,
      releaseId: versionId,
      testDefectIdList: _.map(defectList, 'id'),
    }

    Promise.all(_.compact([
      !_.isEmpty(issueParams.issueIds) && doFetch(RELEVANCE_ISSUE, issueParams),
      !_.isEmpty(_.map(reqList, 'storyId')) && doFetch(`${RELEVANCE_REQ}?${strParams(reqParams)}`, _.map(reqList, 'storyId')),
      !_.isEmpty(defectParams.testDefectIdList) && doFetch(RELEVANCE_DEFECT, defectParams)
    ]))
      .then(() => {
        Messager.show('导入成功', { icon: 'success' })
        refresh()
        setIsDisable(false)
        close()
      })
      .catch((err) => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [id, projectId, doFetch, isDisable, close, refresh, reqList, issueList, defectList, versionId])

  const tipConfirm = useCallback(() => {
    setTiplog(true)
  }, [])

  return (
    <>
      <Dialog
        cancel={close}
        header={<Header num={_.size(selectedList)} />}
        footerVisible={false}
        className={'import-regression-version-dialog'}
      >
        <div className={'import-regression-version-content flex-y'}>
          <div>
            <FormInput required horizontal component={Select} componentWidth={300} options={versionListOpt} search label='选择版本' value={versionId} onChange={(v) => {
              setSelectedList([])
              setVersionId(v)
            }} />
          </div>
          <div className="table-wrap flex-y">
            {
              !_.isNil(versionId) &&
              <>
                <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                  {...{
                    getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
                    currentViewId, setCurrentViewId, params, pageSize
                  }} />
                <Box title={'版本清单'} className='flex-y x-card-singlegrid' data={list} loading={boxLoading} error={error}>
                  <DataGrid option={options} data={dataGridList} selection={selectedList} onSelectionChange={setSelectedList} />
                  <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                    onChange={(pageNum, pageSize) => {
                      setPageSize(pageSize)
                      setParams(x => _.assign({}, x, { pageNum, pageSize }))
                    }} />
                </Box>
              </>
            }
          </div>
        </div>
        <div className="mock-footer flex center-y">
          <div className='text-hint'></div>
          <div className="btn-group flex">
            <Button normal onClick={close}>取消</Button>
            <Button primary onClick={tipConfirm} disable={_.isEmpty(selectedList)}>确认</Button>
          </div>
        </div>
      </Dialog>
      {
        tipLog &&
        <Dialog
          confirm={submit}
          header='收拢版本同步结果'
          cancel={() => setTiplog(false)}
        >
          <div className='flex'>
            <div>版本号：{versionId}</div>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <div>需求个数：{_.size(reqList)}</div>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <div>ISSUE个数：{_.size(issueList)}</div>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <div>历史缺陷个数：{_.size(defectList)}</div>
          </div>
        </Dialog>
      }
    </>
  )
}
