import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import './ProductMgt.scss'
import ProductDetail from "./productDetail/ProductDetail";
import useGetViewConfig from "../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../common/view/hooks/useRefreshList";
import useGetDateOptions from "../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../common/view/hooks/useGetTreeList";
import ViewArea from "../common/view/ViewArea";
import { Box } from "../common/commonComponent";
import { DataGrid, Pagination, Messager } from "rootnet-ui";
import _ from "lodash";
import convertTableAlign from "../common/view/convertTableAlign";
import findConvert from "../common/view/findConvert";
import Icon from "../../components/Icon";
import { Popover } from "antd";
import { TextIconBtn } from "../common/TextIconBtn";
import { isNil } from "rootnet-core/format";
import usePosition from "../common/hooks/usePosition";
import gd from "../../base/global";
import { CheckBox } from "rootnet-edit";
import { useFuncCode } from "../common/commonMethod";
import { ScheduleManagementContext } from '../common/Context';
import IssueDisabledDialog from './issueDisabledDialog';

const ENTER_DETAIL_FIELD = 'View_productInfo_tree.productId'

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, onExpandIconClick, foldList, setCurrentInfo, handlePopId, setHandlePopId, isProdManager } = props

    const customColumns = []

    return [
        { selection: true, width: 55 },
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center', stretch: false },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            // 自定义字段
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        } else {
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: `${fieldItem.tableName}.${fieldItem.fieldId}` !== ENTER_DETAIL_FIELD,
                _custom: fieldItem.custom,
                convert: (v, o) => convertEditField(v, o, fieldItem)
            }
        }
    }
    function convertEditField(v, o, fieldItem) {
        let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
        const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
        if (tableField === ENTER_DETAIL_FIELD) {
            const productManagerColumnId = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'View_productInfo_tree.productManager'), 'columnId')
            const canAddChild = isProdManager || _.get(o, productManagerColumnId) === gd.User.operator_id
            return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
                <div className="left-enter-field-wrap flex center-y">
                    {
                        _.get(o, '_isParent') && !_.get(o, '_isEmptyChildren') &&
                        <Icon name={_.includes(foldList, o.id) ? 'biaogezhankai' : 'biaogeshouqi'} className={'expand-icon'} onClick={() => onExpandIconClick(o.id)} />
                    }
                    {
                        _.get(o, '_isParent') && _.get(o, '_isEmptyChildren') &&
                        <div className={'empty-parent'} />
                    }
                    {
                        !_.get(o, '_isParent') &&
                        <div className={'empty-child'} />
                    }
                    <div className="enter-detail-field flex">
                        <div className="can-enter-text" onClick={() => {
                            setCurrentInfo({
                                id: _.get(o, 'id'),
                                mode: 'detail',
                                isSubsProduct: !_.get(o, '_isParent')
                            })
                        }}>
                            {showValue || '-'}
                        </div>
                    </div>
                </div>
                {
                    _.get(o, '_isParent') && canAddChild &&
                    <Popover placement={'bottom'} destroyTooltipOnHide={true} trigger={'click'}
                        open={_.get(o, 'id') === handlePopId}
                        onOpenChange={visible => {
                            setHandlePopId(visible ? _.get(o, 'id') : null)
                        }}
                        content={<div className={'product-handle-popover flex-y'}>
                            <div className={'product-handle-item flex center-y'} onClick={() => {
                                setCurrentInfo({
                                    mode: 'add',
                                    isSubsProduct: true,
                                    initForm: {
                                        pid: _.get(o, 'id'),
                                        overdue: 'N',
                                        singlequote: 'N',
                                        independentVerFlag: 'N',
                                        necessaryType: 'N',
                                    }
                                })
                                setHandlePopId(null)
                            }}>
                                <Icon name={'tianjia'} className={'handle-icon'} />
                                <div className="handle-text">创建子产品</div>
                            </div>
                        </div>}>
                        <div className={'cursor-pointer'} style={{ margin: '0 8px' }}>
                            <Icon name={'gengduo'} className={'more-handle-icon'} />
                        </div>
                    </Popover>
                }
            </div>
        }
        return <div className={'common-display-field'}>
            {showValue || '-'}
        </div>
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

const FUNC_CODE = '44'

function ProductMgt(props) {
    const { downloadPostProgress } = useContext(ScheduleManagementContext)
    const [currentInfo, setCurrentInfo] = useState()
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(20)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const [foldList, setFoldList] = useState([])
    const [sortConfig, setSortConfig] = useState()
    const [handlePopId, setHandlePopId] = useState()
    const [factor, setFactor] = useState()
    const [selection, setSelection] = useState([])
    const [issueDisabled, setIssueDisabled] = useState(false)
    const showAllNodesRef = useRef(true)

    const [isProdManager] = usePosition(['ProdManager'])

    const [addAuth, isIssueDisabled] = useFuncCode(['4401', '4402'])

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, showAllNodes: showAllNodesRef.current ? 'Y' : 'N', getFactor: setFactor })

    const dateOptions = useGetDateOptions()

    const onExpandIconClick = useCallback((id) => {
        setFoldList(oldList => {
            if (_.includes(oldList, id)) {
                return _.filter(oldList, x => x !== id)
            } else {
                return _.concat(oldList, [id])
            }
        })
    }, [])

    const { options, dataGridList } = useGetTreeList({
        fieldList, list, convertCollection, dateOptions,
        getOptions, getColumns, columnsAppendParams: { onExpandIconClick, foldList, setCurrentInfo, handlePopId, setHandlePopId, isProdManager },
        optionsAppendParams: {
            sort: sortConfig,
            onSort: (data, sort) => {
                if (_.isEmpty(data)) return []
                setSortConfig(sort)
                const sortAllList = data.sort((a, b) => sortFun(a, b, sort.column))
                const sortAllDirectionList = sort.direction === 'asc' ? sortAllList : _.reverse(_.clone(sortAllList))
                const sortFirstLevelDirectionList = _.filter(sortAllDirectionList, x => _.isNil(_.get(x, 'pid')))
                let handleList = []
                _.forEach(sortFirstLevelDirectionList, item => {
                    const childrenList = _.filter(sortAllDirectionList, x => _.get(x, 'pid') === item.id)
                    handleList.push(item)
                    _.forEach(childrenList, x => {
                        handleList.push(x)
                    })
                })
                return handleList
            }
        },
    })

    useEffect(() => {
        const firstLevelList = _.filter(dataGridList, x => _.isNil(_.get(x, 'pid')))
        setFoldList(_.map(firstLevelList, 'id') || [])
    }, [dataGridList])

    const showChildList = useMemo(() => {
        const initData = _.map(dataGridList, o => {
            if (!isNil(_.get(o, 'pid'))) {
                o._selectable = false
                return o
            }
            return o
        })
        return _.filter(initData, x => !_.get(x, '_children')) || []
    }, [dataGridList])

    const handleList = useMemo(() => {
        let allList
        if (_.isNil(sortConfig)) {
            allList = showChildList || []
        } else {
            const sortList = _.sortBy(showChildList || [], x => x[sortConfig.column])
            allList = sortConfig.direction === 'asc' ? sortList : _.reverse(sortList)
        }
        const firstLevelList = _.filter(allList, x => _.isNil(_.get(x, 'pid')))
        let handleList = []
        _.forEach(firstLevelList, item => {
            let parentItem = { ...item }
            const childrenList = _.filter(allList, x => _.get(x, 'pid') === item.id)
            const childrenIdList = _.map(childrenList, x => x.id)
            parentItem = { ...parentItem, childrenIdList, _isParent: true, _isEmptyChildren: _.isEmpty(childrenList) }
            handleList.push(parentItem)
            _.forEach(childrenList, x => {
                handleList.push({ ...x, _isParent: false })
            })
        })
        return handleList
    }, [showChildList, sortConfig])

    const showList = useMemo(() => {
        return _.filter(handleList, x => !_.includes(foldList, _.get(x, 'pid')))
    }, [handleList, foldList])

    const exportViewList = useCallback(() => {
        const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
        const postParams = {
            action: 'query',
            id: currentViewId,
            type: '01',
            menuCode: FUNC_CODE,
            fieldList: _.map(notCustomList, 'columnId'),
            fieldNameList: _.map(notCustomList, 'fieldName'),
            factor: factor
        }
        downloadPostProgress('/UserSetting/exportExcel', postParams, '产品管理')
    }, [currentViewId, fieldList, factor, downloadPostProgress])

    const extra = useMemo(() => {
        return <div className='extra-group flex center-y'>
            {
                isIssueDisabled && <TextIconBtn text='ISSUE禁用' onClick={() => {
                    if (_.isEmpty(selection)) return Messager.show('请选择需要操作的产品')
                    setIssueDisabled(true)
                }} />
            }
            {
                addAuth &&
                <TextIconBtn text='新增' icon='tianjia' onClick={() => {
                    setCurrentInfo({
                        mode: 'add',
                        initForm: {
                            overdue: 'N',
                            selectionFlag: 'N',
                            independentVerFlag: 'N',
                        }
                    })
                }} />
            }
            <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
        </div>
    }, [selection, addAuth, exportViewList, isIssueDisabled])

    const extraBtnArea = useMemo(() => {
        return <div className={'flex center-y'}>
            <CheckBox defaultValue={true} onChange={v => { showAllNodesRef.current = v }}
                style={{ marginTop: 6 }}
            >查询全部子级</CheckBox>
        </div>
    }, [])

    return <>
        <div className={'product-mgt fill flex-y'}>
            <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                {...{
                    getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
                    currentViewId, setCurrentViewId, params, pageSize, extraBtnArea
                }} />
            <Box title={'产品管理'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
                <DataGrid option={options} data={showList} selection={selection} onSelectionChange={setSelection} />
                <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                    onChange={(pageNum, pageSize) => {
                        setPageSize(pageSize)
                        setParams(x => _.assign({}, x, { pageNum, pageSize }))
                    }} />
            </Box>
            {
                !_.isNil(currentInfo) &&
                <ProductDetail close={() => setCurrentInfo(null)} refreshViewList={refreshList} {...{ currentInfo, isProdManager }} />
            }
        </div>
        {
            issueDisabled &&
            <IssueDisabledDialog
                idList={_.map(selection, 'id')}
                close={() => setIssueDisabled(false)}
                {...{ setSelection, refreshList }}
            />
        }
    </>
}

function sortFun(a, b, bind) {
    if (_.isNil(a[bind])) return -1
    const aStr = isNil(a[bind]) ? '' : a[bind]
    const bStr = isNil(b[bind]) ? '' : b[bind]
    if (_.isNumber(a[bind]) && _.isNumber(b[bind])) {
        return a[bind] - b[bind]
    }
    if (_.isNumber(a[bind]) ^ _.isNumber(b[bind])) {
        return (_.toNumber(a[bind]) || 0) - (_.toNumber(b[bind]) || 0)
    }
    return aStr.localeCompare(bStr)
}

export default ProductMgt;