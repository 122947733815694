import React, { useMemo, useCallback, useState, useContext, useEffect } from 'react'
import _ from 'lodash'
import { Checkbox as AntdCheckbox, Tooltip } from 'antd';
import { useGet } from 'rootnet-biz/lib/hooks'
import { Dialog, Button, Messager } from 'rootnet-ui'
import { Form, FormInput, Select, CheckBox, DatePicker } from 'rootnet-edit'
import { ScheduleManagementContext } from '../../../../../../../common/Context'
import { strParams } from '../../../../../../../../utils/publicFun'
import { isNil } from '../../../../../../../appraise/components/method'
import { dateFormat } from 'rootnet-core/dateFormat'
import './index.scss'
import { Icon } from '../../../../../../../../components';

const MODIFY_FILE_TYPE_URL = '/exportModifyFile/check/list'
const EXPORT_MODIFY_FILE_URL = '/exportModifyFile/differenceExport' //修改文件
const QUERY_DATE_URL = '/exportModifyFile/queryReleaseSealingDate' //查询上次封板时间

export default function ContrastEditFile(props) {
  const { versionList, close } = props
  const [params, setParams] = useState({})
  const [error, setError] = useState()
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [modifiedTypeList, setModifiedTypeList] = useState([])
  const { data: modifyTypeRes, loading } = useGet(MODIFY_FILE_TYPE_URL)
  const [onlyMakeList, setOnlyMakeList] = useState(true)
  const { downloadGetProgress } = useContext(ScheduleManagementContext)
  const { releaseId } = useMemo(() => params || {}, [params])
  const { doFetch } = useGet()

  useEffect(() => {
    if (!isNil(releaseId)) {
      doFetch(`${QUERY_DATE_URL}?releaseId=${releaseId}`)
        .then((res) => {
          if (_.isNil(res)) return setParams(x => (_.assign({}, x, { fileUpdateTime: null })))
          setParams(x => (_.assign({}, x, { fileUpdateTime: new Date(res) })))
        })
    }
  }, [doFetch, releaseId])

  const modifyTypeOpt = useMemo(() => {
    if (_.isEmpty(modifyTypeRes)) return []
    const key = _.keys(modifyTypeRes)
    const val = _.values(modifyTypeRes)
    return _.map(key, (v, i) => ({ label: val[i], value: v }))
  }, [modifyTypeRes])

  const versionOpt = useMemo(() => {
    if (_.isEmpty(versionList)) return []
    return _.map(versionList, o => ({ text: o.releaseName, value: o.releaseID }))
  }, [versionList])

  const check = useCallback(() => {
    return _.every(_.values(error), isNil)
  }, [error])

  const onChange = (list) => {
    setModifiedTypeList(list);
    setIndeterminate(!!list.length && _.size(list) < _.size(modifyTypeOpt));
    setCheckAll(_.size(list) === _.size(modifyTypeOpt));
  };
  const onCheckAllChange = (e) => {
    setModifiedTypeList(e.target.checked ? _.map(modifyTypeOpt, 'value') : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const confirm = useCallback(() => {
    if (!check()) return Messager.show('请填写必填项', { icon: 'error' })
    if (_.isEmpty(modifiedTypeList)) return Messager.show('请选择导出文件列表类型', { icon: 'error' })
    const fileUpdateTime = dateFormat('YYYY-MM-DD HH:MM:SS', _.get(params, 'fileUpdateTime'))
    downloadGetProgress(`${EXPORT_MODIFY_FILE_URL}?${strParams({ ...params, modifiedTypeList, onlyMakeList: onlyMakeList ? 'Y' : 'N', fileUpdateTime })}`, '导出修改文件', close)
  }, [downloadGetProgress, modifiedTypeList, onlyMakeList, close, check, params])

  return (
    <Dialog
      header='对比修改文件'
      className='contrast-edit-file-log'
      cancel={close}
      footerVisible={false}
      loading={loading}
    >
      <div className="content-panel flex-y">
        <Form value={params} onChange={setParams} error={error} onError={setError}>
          <FormInput labelWidth={187} required horizontal label='参与对比的上一个版本' bind='releaseId' component={Select} options={versionOpt} search />
          <div className='flex center-x center-y'>
            <FormInput labelWidth={179} required horizontal label='上一版本的首次发布时间' bind='fileUpdateTime' component={DatePicker} mode='datetime' clear /><Tooltip title='差异对比所使用的上一版本的研发任务信息，为所选时间当时的版本信息，默认为首次发布的时间，可改'><span><Icon name='bangzhu' style={{ fontSize: 14, color: '#5477ff' }} /></span></Tooltip>
          </div>
          <FormInput labelWidth={187} required horizontal label='参与对比的本次版本' bind='releaseIdAnother' component={Select} options={versionOpt} search />
          <div className='label-text'>选择导出文件列表类型：</div>
          <AntdCheckbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>全部</AntdCheckbox>
          <AntdCheckbox.Group value={modifiedTypeList} options={modifyTypeOpt} onChange={onChange} />
        </Form>
      </div>
      <div className="mock-footer flex center-y">
        <div>
          <CheckBox value={onlyMakeList} onChange={setOnlyMakeList}>仅导出可制作清单</CheckBox>
        </div>
        <div className="btn-group flex">
          <Button normal onClick={close}>取消</Button>
          <Button primary onClick={confirm}>确认</Button>
        </div>
      </div>
    </Dialog>
  )
}

// function filterOpt(opt, currentData) {
//   return _.filter(opt, o => o.value !== currentData)
// }
