import React, { useState, useCallback, useMemo } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Tooltip } from 'antd'
import { Messager } from 'rootnet-ui'
import { useGet } from 'rootnet-biz/lib/hooks'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Form, FormInput, Display, DatePicker, Input } from 'rootnet-edit'
import { Icon } from '../../../../../../../components'
import convertOptions from '../../../../../../common/ConvertOptions'
import UserSelect from '../../../../../../common/personSelectPop/UserSelect'
import { isNil } from '../../../../../../appraise/components/method'
import convertGlobalConstOptions from '../../../../../../common/ConvertGlobalConstOptions'
import './index.scss'
import { timeDifference } from '../../../../common/commonFun'
import DisplaySelect from '../../../../../../common/displaySelect/DisplaySelect'

const GLOBAL_CONST_OPTIONS_URLS = [
  'common/globalconst?globalConst=reviewCategory', //评审类别
  'common/globalconst?globalConst=AuditPassFlag', //评审状态
]

const HFormInput = props => <FormInput horizontal labelWidth={110} componentWidth={186} {...props} />

const initExpand = {
  baseInfo: true,
  reviewInfo: true,
  createInfo: true
}

export default function ReviewMinutesDetailRightPanel(props) {
  const { isDetail, allUserRes, editFormData, setEditFormData, infoError, setInfoError } = props
  const [expand, setExpand] = useState(initExpand)
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

  const [reviewCategoryOpt, auditPassFlagOptions,] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const changeForm = useCallback((newObj, bind) => {
    setEditFormData(oldObj => {
      let cloneObj = { ...oldObj, ...newObj }
      if (_.includes(['dOccurTimeBegin', 'dOccurTimeEnd'], bind)) {
        if (handleTime(_.get(cloneObj, 'dOccurTimeBegin')) > handleTime(_.get(cloneObj, 'dOccurTimeEnd'))) {
          if (bind === 'dOccurTimeBegin') {
            cloneObj['dOccurTimeBegin'] = oldObj['dOccurTimeBegin']
            Messager.show('开始时间不能晚于结束时间')
          } else {
            cloneObj['dOccurTimeEnd'] = oldObj['dOccurTimeEnd']
            Messager.show('结束时间不能早于开始时间')
          }
        }
      }
      return cloneObj
    })

    function handleTime(timeStr) {
      return moment(toDate.str14ToDate(timeStr)).format('HH:mm:ss')
    }
  }, [setEditFormData])

  return (
    <div className='review-minutes-detail-right-panel'>

      <div className="area-wrap base-info-wrap">
        <div className="area-header flex center-y">
          <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: expand['baseInfo'] ? 'none' : 'rotate(-90deg)' }} onClick={() => setExpand(o => _.assign({}, o, { baseInfo: !o['baseInfo'] }))} />
          基本信息
        </div>
        <div className="area-content-wrap" style={{ height: expand['baseInfo'] ? '' : 0 }}>
          <Form value={editFormData} onChange={changeForm} error={infoError} onError={v => setInfoError(x => _.assign({}, x, v))}>
            <HFormInput required label={'评审类别'} bind={'reviewCategory'} search component={isDetail ? Display : DisplaySelect} options={reviewCategoryOpt} convert={v => convertOptions(v, reviewCategoryOpt)} />
            <HFormInput label={'评审日期'} bind={'dOccurTime'} component={isDetail ? Display : DatePicker} clear required
              bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
              convert={v => {
                if (_.isNil(v)) return '-'
                if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                return dateFormat('YYYY-MM-DD', v) || '-'
              }}
            />
            <div className={'flex center-y'}>
              <HFormInput label={'评审时间'} bind={'dOccurTimeBegin'} component={isDetail ? Display : DatePicker} clear required mode='time'
                showSeconds={false} componentWidth={isDetail ? 36 : 75} placeholder={'开始'}
                bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                convert={v => {
                  if (_.isNil(v)) return '-'
                  if (_.isString(v)) return moment(toDate.str14ToDate(v)).format('HH:mm')
                  return moment(v).format('HH:mm') || '-'
                }}
              />
              至
              <HFormInput label={''} bind={'dOccurTimeEnd'} component={isDetail ? Display : DatePicker} clear required mode='time'
                showSeconds={false} componentWidth={isDetail ? 36 : 75} placeholder={'结束'}
                bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                convert={v => {
                  if (_.isNil(v)) return '-'
                  if (_.isString(v)) return moment(toDate.str14ToDate(v)).format('HH:mm')
                  return moment(v).format('HH:mm') || '-'
                }}
              />
            </div>
            <HFormInput label={'评审时长'} bind={''} component={Display} convert={() => {
              const startTime = _.get(editFormData, 'dOccurTimeBegin')
              const endTime = _.get(editFormData, 'dOccurTimeEnd')
              if (!isNil(startTime) && !isNil(endTime)) return timeDifference(toDate.str14ToDate(startTime), toDate.str14ToDate(endTime))
              return '-'
            }} />
            <HFormInput label={'会议主持'} bind={'sCaption'} component={isDetail ? Display : Input} />
            <HFormInput label={'会议地点'} bind={'sOccurPlace'} component={isDetail ? Display : Input} />
            <HFormInput label={'评审参与人'} bind={'sOurPerformer'} required component={isDetail ? Display : UserSelect} multiple convert={v => <Tooltip title={_.join(_.map(v, x => convertOptions(x, allUserRes, 'userName', 'userAccount')), '、')}>
              <div className={'ellipsis-line'}>
                {_.join(_.map(v, x => convertOptions(x, allUserRes, 'userName', 'userAccount')), '、')}
              </div>
            </Tooltip>} />
            <HFormInput label={'评审组长'} bind={'auditor'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
            <HFormInput label={'未出席人员'} bind={'absentPersonnel'} component={isDetail ? Display : UserSelect} multiple convert={v => <Tooltip title={_.join(_.map(v, x => convertOptions(x, allUserRes, 'userName', 'userAccount')), '、')}>
              <div className={'ellipsis-line'}>
                {_.join(_.map(v, x => convertOptions(x, allUserRes, 'userName', 'userAccount')), '、')}
              </div>
            </Tooltip>} />
            <HFormInput label={'未参加人数'} bind={'absenteeCount'} component={Display} />
            <HFormInput label={'问题清单数量'} bind={'issueListCount'} component={Display} />
          </Form>
        </div>
      </div>

      <div className="area-wrap base-info-wrap">
        <div className="area-header flex center-y">
          <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: expand['reviewInfo'] ? 'none' : 'rotate(-90deg)' }} onClick={() => setExpand(o => _.assign({}, o, { reviewInfo: !o['reviewInfo'] }))} />
          评审信息
        </div>
        <div className="area-content-wrap" style={{ height: expand['reviewInfo'] ? '' : 0 }}>
          <Form value={editFormData} onChange={changeForm} error={infoError} onError={v => setInfoError(x => _.assign({}, x, v))}>
            <HFormInput label={'评审结论'} bind={'auditPassFlag'} component={Display} required search options={auditPassFlagOptions} convert={v => convertOptions(v, auditPassFlagOptions)} />
            <HFormInput label={'评审决策人'} bind={'auditor'} required clear component={isDetail ? Display : UserSelect}
              convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
            <HFormInput label={'是否首次通过'} bind={'firstPass'} component={Display} convert={v => {
              if (v === 'Y') return '是'
              return '否'
            }} />
          </Form>
        </div>
      </div>

      <div className="area-wrap base-info-wrap">
        <div className="area-header flex center-y">
          <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: expand['createInfo'] ? 'none' : 'rotate(-90deg)' }} onClick={() => setExpand(o => _.assign({}, o, { createInfo: !o['createInfo'] }))} />
          创建信息
        </div>
        <div className="area-content-wrap" style={{ height: expand['createInfo'] ? '' : 0 }}>
          <Form value={editFormData} onChange={changeForm} error={infoError} onError={v => setInfoError(x => _.assign({}, x, v))}>
            <Form value={editFormData}>
              <HFormInput label={'记录人'} bind={'sAddPerson'} component={Display}
                convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
              <HFormInput label={'记录时间'} bind={'dAddTime'} component={Display}
                convert={v => {
                  if (_.isNil(v)) return '-'
                  if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                  return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                }} />
            </Form>
          </Form>
        </div>
      </div>

    </div>
  )
}

