import React, { useCallback, useMemo, useState } from 'react';
import { Icon } from "../../../../../../components";
import clsx from "clsx";
import { Tooltip } from "antd";
import { Form, FormInput, Display, Select } from 'rootnet-edit'
import AddVersion from "../../../../../versionMgt/versionMsg/components/addVersion";
import _ from 'lodash'
import convertOptions from "../../../../../common/ConvertOptions";
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import { Loader, Messager } from "rootnet-ui";
import { usePost } from 'rootnet-biz/lib/hooks';
import './DevProjectScopeVersion.scss'

const moduleTotal = {
    'all': 'releaseAllBusinessSize',
    'req': 'reqSize',
    'dev': 'devSize',
    'issue': 'issueSize',
    'baseLineDefect': 'baselineDefect',
    'testPlan': 'planSize',
    'defect': 'defectSize',
    'version': 'releaseTaskSize',
}

const moduleNoVersion = {
    'all': 'releaseNoVersionBusinessSize',
    'req': 'reqNoVersionSize',
    'dev': 'devNoVersionSize',
    'issue': 'issueNoVersionSize',
    'baseLineDefect': 'noBaselineDefect',
    'testPlan': 'planNoVersionSize',
    'defect': 'noVersionDefect',
    'version': 'releaseTaskNoVersionSize',
}

const noVersionTips = {
    'all': '需求、issue，需求、issue，所属项目为本项目，且版本未包含本项目下的版本。历史缺陷的修复项目为当前项目，修复版本不在本项目下的版本中。',
    'req': '所属项目为本项目，且版本未包含本项目下的版本',
    'dev': '所属项目为本项目，且版本未包含本项目下的版本',
    'issue': '所属项目为本项目，且版本未包含本项目下的版本',
    'baseLineDefect': '历史缺陷的修复项目为当前项目，修复版本不在本项目下的版本中。',
    'testPlan': '所属项目为本项目，且版本未包含本项目下的版本',
    'defect': '修复项目属于当前项目，修复版本未在当前项目版本下的历史缺陷',
    'version': '需求、issue，需求、issue，所属项目为本项目，且版本未包含本项目下的版本。历史缺陷的修复项目为当前项目，修复版本不在本项目下的版本中。',
}

const HFormInput = props => <FormInput horizontal labelWidth={100} componentWidth={134} {...props} />

function DevProjectScopeVersion(props) {
    const { projectId, versionLoading, selectedVersionItem, setSelectedVersionItem, refreshVersionList, versionListRes,
        versionStatusOptions, addVersionAuth, activeModule, typeOpt, natureOpt, scaleOpt, customerSystemOpt, allUserRes, isShow, isPo, isPMM, isCM, initVersionDate } = props
    const [isFold, setIsFold] = useState(false)
    const [isShowAddVersion, setIsShowAddVersion] = useState(false)
    const [isSelect, setIsSelect] = useState(false)
    const { doFetch } = usePost()

    const selectedVersion = useMemo(() => {
        return _.get(selectedVersionItem, 'releaseID')
    }, [selectedVersionItem])

    const isVersion = useMemo(() => activeModule === 'version', [activeModule])

    const versionList = useMemo(() => {
        return _.get(versionListRes, 'releaseInfoList') || []
    }, [versionListRes])

    const selectChange = useCallback((state, id) => {
        doFetch('/releaseinfo/updateState', { releaseid: id, state })
            .then(() => {
                setIsSelect(false)
                refreshVersionList()
                Messager.show('修改成功', { icon: 'success' })
            })
            .catch((err) => {
                setIsSelect(false)
                Messager.show(err._message, { icon: 'error' })
            })
    }, [doFetch, refreshVersionList])

    return <div className={clsx('dev-project-scope-version', { 'is-fold': isFold })}>
        {
            versionLoading && <Loader fill />
        }
        <div className="dev-project-version-header flex center-y space-between" onClick={() => setIsFold(x => !x)}>
            {
                !isFold &&
                <div className="dev-project-version-header-text">
                    项目范围
                </div>
            }
            <Icon name={'zhankaitiaojian'} className={'fold-icon'} style={{ transform: isFold ? 'rotate(-90deg)' : 'rotate(90deg)' }} />
        </div>
        {
            !isFold &&
            <div className="dev-project-version-type flex-y">
                <div className={clsx("version-type-item flex center-y", { active: _.isNil(selectedVersion) })} onClick={() => {
                    setSelectedVersionItem(null)
                    refreshVersionList(false)
                }}>
                    <div className="type-name">
                        所有
                    </div>
                    <div className="type-num">
                        （{isVersion ? _.get(versionListRes, 'releaseAllTaskSize', 0) : _.get(versionListRes, moduleTotal[activeModule])}）
                    </div>
                </div>
                {
                    isVersion &&
                    <div className={clsx("version-type-item flex center-y", { active: selectedVersion === 'planned-version' })}
                        onClick={() => {
                            setSelectedVersionItem({ releaseID: 'planned-version' })
                            refreshVersionList(false)
                        }}>
                        <div className="type-name">
                            已规划版本
                        </div>
                        <div className="type-num">
                            （{_.get(versionListRes, 'releaseTaskSize')}）
                        </div>
                    </div>
                }
                {
                    activeModule === 'defect' &&
                    <div className={clsx("version-type-item flex center-y", { active: selectedVersion === 'history-defect' })}
                        onClick={() => {
                            setSelectedVersionItem({ releaseID: 'history-defect' })
                            refreshVersionList(false)
                        }}>
                        <div className="type-name">
                            历史缺陷
                        </div>
                        <div className="type-num">
                            （{_.get(versionListRes, 'defectHistorySize')}）
                        </div>
                    </div>
                }
                <div className={clsx("version-type-item flex center-y", { active: selectedVersion === 'no-plan-version' })}
                    onClick={() => {
                        setSelectedVersionItem({ releaseID: 'no-plan-version' })
                        refreshVersionList(false)
                    }}>
                    <div className="type-name">
                        未规划版本
                    </div>
                    <div className="type-num">
                        （{_.get(versionListRes, moduleNoVersion[activeModule])}）
                    </div>
                    <Tooltip title={_.get(noVersionTips, activeModule)}>
                        <span>
                            <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                        </span>
                    </Tooltip>
                </div>
            </div>
        }
        {
            !isFold &&
            <div className="dev-project-version-list flex-y">
                {
                    (isShow && addVersionAuth) &&
                    <div className="add-version flex center-y" onClick={() => setIsShowAddVersion(true)}>
                        <Icon name={'zengjia'} style={{ marginRight: 8 }} />
                        <div className="add-version-text">新建版本</div>
                    </div>
                }
                <div className={'version-item-wrap flex-y'}>
                    {/* 'producttype2, producttype, sproductround, mulCustID, functionary, pouser' */}
                    {
                        _.map(versionList, item => (<React.Fragment key={item.releaseID}>

                            <div className={clsx("version-item flex-y", { active: selectedVersion === item.releaseID })}
                                onClick={() => {
                                    setSelectedVersionItem(item)
                                }}
                            >
                                <div className="version-info flex center-y space-between">
                                    <Tooltip title={item.releaseName}>
                                        <div className="version-title">
                                            {item.releaseName}
                                        </div>
                                    </Tooltip>
                                    <div className="version-info-right flex center-y">
                                        {
                                            item.releaseID !== isSelect &&
                                            <>
                                                <div
                                                    className="version-status"
                                                    onClick={() => {
                                                        if ((isPo || isPMM || isCM) && !_.includes(['R', 'L'], item.state)) {
                                                            setIsSelect(item.releaseID)
                                                        }
                                                    }}
                                                    style={{ color: convertOptions(item.state, versionStatusOptions, 'color') }}
                                                >
                                                    {convertOptions(item.state, versionStatusOptions)}
                                                </div>
                                                {
                                                    (isPo || isPMM || isCM) && !_.includes(['R', 'L'], item.state) &&
                                                    <Icon name='arrow_drop_down' />
                                                }
                                            </>
                                        }
                                        {
                                            item.releaseID === isSelect &&
                                            <Select
                                                value={item.state}
                                                options={versionStatusOptions}
                                                style={{ width: '100px' }}
                                                onChange={v => selectChange(v, item.releaseID)}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="version-progress flex center-y space-between">
                                    <div className="version-date flex center-y">
                                        {item.versionApprovalDate ? dateFormat('YYYY-MM-DD')(toDate.str14ToDate(item.versionApprovalDate)) : '-'} 至 {item.issueDate ? dateFormat('YYYY-MM-DD')(toDate.str14ToDate(item.issueDate)) : '-'}
                                    </div>
                                    <div className='flex'>
                                        <Tooltip
                                            placement='bottom' overlayClassName='version-info-tooltip-item' title={<div className='version-info-tooltip'>
                                                <Form value={item}>
                                                    <HFormInput label={'版本类型'} bind={'productType2'} component={Display} convert={v => convertOptions(v, typeOpt)} />
                                                    <HFormInput label={'版本性质'} bind={'productType'} component={Display} convert={v => convertOptions(v, natureOpt)} />
                                                    <HFormInput label={'版本规模'} bind={'sProductRound'} component={Display} convert={v => convertOptions(v, scaleOpt)} />
                                                    <HFormInput className='text-area-input-detail' label={'发布客户系统'} bind={'mulCustID'} component={Display} convert={v => {
                                                        const value = _.split(v, '^')
                                                        return _.join(_.map(value, val => _.get(_.find(customerSystemOpt, o => o.value === val), 'label')), '，')
                                                    }} />
                                                    <HFormInput label={'负责人'} bind={'functionary'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                                                    <HFormInput label={'po负责人'} bind={'poUser'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                                                    <HFormInput label={'实际发布日期'} bind={'realReleaseDate'} component={Display} convert={v => {
                                                        if (_.isNil(v)) return '-'
                                                        if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                                                        return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                                                    }} />
                                                    <HFormInput label={'inner'} bind={'versionInner'} component={Display} convert={v => v === 'Y' ? "是" : '否'} />
                                                </Form>
                                            </div>}>
                                            <span>
                                                <Icon style={{ fontSize: 14, color: '#5477FF' }} name='bangzhu' />
                                            </span>
                                        </Tooltip>
                                        <div
                                            className="version-enter flex center"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                window.open('/#/versionMsg?initId=' + item.releaseID)
                                            }}>
                                            进入
                                        </div>
                                    </div>
                                    {/*<div className="version-progress-percent" style={{color: item.format === '1.00' ? '#00A62E' : ''}}>*/}
                                    {/*    {_.floor(_.toNumber(item.format)*100)}%*/}
                                    {/*</div>*/}
                                </div>
                                {/*<div className="version-progress-bar">*/}
                                {/*    <Progress percent={_.floor(_.toNumber(item.format)*100)} size="small" showInfo={false}/>*/}
                                {/*</div>*/}
                            </div>
                        </React.Fragment>))
                    }
                </div>
            </div>
        }
        {
            isShowAddVersion &&
            <AddVersion projectDisable={true} close={() => setIsShowAddVersion(false)} refresh={refreshVersionList} defaultParams={{ projectid: projectId, versionApprovalDate: initVersionDate }} />
        }
    </div>
}

export default DevProjectScopeVersion;