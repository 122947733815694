import _ from 'lodash'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Dialog, Messager } from 'rootnet-ui'
import { useGet } from 'rootnet-biz/lib/hooks'
import { Form, FormInput, Select } from 'rootnet-edit'
import { strParams } from '../../../../../../../../utils/publicFun'
import { isNil } from '../../../../../../../appraise/components/method'
import './index.scss'

const INNER_BOARD_URL = '/userProject/syncTargetReleaseByCurrentRelease' // inner一键上车
const VERSION_URL = '/userProject/getReleaseListByProjectId'

const headerTip = '将目标版本中初次迭代验收测试通过的版本清单内容，复制加入到当前inner版本！'

export default function InnerVersionBoardDialog(props) {
  const { close, id, projectId, selectedVersion } = props
  const [isDisabled, setIsDisabled] = useState(false)
  const [params, setParams] = useState({ currentReleaseId: selectedVersion })
  const { data: versionRes, doFetch: getVersionFetch } = useGet()
  const { doFetch } = useGet()

  useEffect(() => {
    if (isNil(projectId)) return
    getVersionFetch(`${VERSION_URL}?projectId=${projectId}`)
  }, [getVersionFetch, projectId])

  const versionOpt = useMemo(() => {
    if (_.isEmpty(versionRes)) return []
    const filterSelf = _.filter(versionRes, item => item?.releaseId !== selectedVersion)
    return _.map(filterSelf, item => ({ text: item?.releaseName, value: item?.releaseId }))
  }, [versionRes, selectedVersion])

  const submit = useCallback(() => {
    if (isDisabled) return
    setIsDisabled(true)
    if (isNil(params?.targetReleaseId)) {
      setIsDisabled(false)
      Messager.show('请选择目标版本', { icon: 'error' })
      return
    }
    doFetch(`${INNER_BOARD_URL}?${strParams(_.assign({}, params, { projectSnowId: id }))}`)
      .then((res) => {
        setIsDisabled(false)
        Messager.show(`本次同步了需求：${_.get(res, 'storyCount')}个，ISSUE：${_.get(res, 'issueCount')}个，历史缺陷：${_.get(res, 'bugCount')}个。`, { icon: 'success' })
        close()
      })
      .catch(err => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [id, close, params, doFetch, isDisabled])

  return (
    <Dialog
      cancel={close}
      header={<div className='dialog-headr flex'><div className='head-text'>提示</div><div className='head-tip'>{headerTip}</div></div>}
      confirm={submit}
      confirmButtonText='上车'
      cancelButtonVisible={false}
      className='inner-version-board-dialog'
    >
      <Form value={params} onChange={setParams} >
        <FormInput horizontal label='当前Inner版本' bind='currentReleaseId' labelWidth={110} disabled={true} componentWidth={380} />
        <FormInput horizontal label='目标版本' bind='targetReleaseId' labelWidth={110} component={Select} options={versionOpt} componentWidth={380} />
      </Form>
    </Dialog>
  )
}
