import React, { useMemo } from 'react';
import './DevProjectFixDefect.scss'
import _ from "lodash";
import DevProjectScopeVersion from "../devProjectScope/devProjectScopeVersion/DevProjectScopeVersion";
import DevProjectDefect from "../devProjectDefect";

function DevProjectFixDefect(props) {
    const { versionStatusOptions, addVersionAuth, selectedVersionItem, setSelectedVersionItem, refreshVersionList,
        projectMap, versionListRes, versionLoading, isShow, canRelate, isRelevance } = props

    const selectedVersion = useMemo(() => {
        return _.get(selectedVersionItem, 'releaseID')
    }, [selectedVersionItem])

    const factorViewSql = useMemo(() => {
        let factorViewSql = `(test_defect.projectId = '${_.get(projectMap, 'projectId')}' or testDefect_project.fixProjectId = '${_.get(projectMap, 'projectId')}')`
        if (selectedVersion === 'history-defect') {
            factorViewSql += ` and test_defect.historyQuestion = 'Y'`
        } else if (selectedVersion === 'no-plan-version') {
            const versionList = _.get(versionListRes, 'releaseInfoList') || []
            factorViewSql = `(testDefect_project.fixProjectId = '${_.get(projectMap, 'projectId')}' and ISNULL( test_defect.historyQuestion, 'N' ) = 'Y' `
            if (_.isEmpty(versionList)) {
                factorViewSql += ')'
            } else {
                const versionSql = _.join(_.map(versionList, x => `isnull(test_defect.fixVersion,'') not like '%${x.releaseID}%'`), ' and ')
                factorViewSql = factorViewSql + ' and ' + versionSql + ')'
            }
        } else if (!_.isNil(selectedVersion)) {
            factorViewSql = `View_test_defect_fixVersion.fixVersion = '${selectedVersion}' or test_defect.releaseId = '${selectedVersion}'`
        }
        return factorViewSql
    }, [selectedVersion, projectMap, versionListRes])

    return <div className={'dev-project-fix-defect flex fill'}>
        <DevProjectScopeVersion projectId={_.get(projectMap, 'projectId')} activeModule={'defect'}
            {...{
                versionLoading, selectedVersionItem, setSelectedVersionItem,
                refreshVersionList, versionListRes, versionStatusOptions, addVersionAuth, isShow,
            }} />
        <div className={'dev-project-fix-defect-view flex'}>
            <DevProjectDefect module={'fixDefect'} relateViewCode={'3613'} relateTips={'历史缺陷的修复项目，增加当前项目的关联'}
                defectRelateFactorViewSql={`','+ISNULL(View_defect_custSystems.fixProjectIds,'')+',' not like '%,${_.get(projectMap, 'projectId')},%'`}
                {...{ factorViewSql, projectMap, selectedVersion, isShowAdd: isShow, selectedVersionItem, canRelate, refreshVersionList, isRelevance }} />
        </div>
    </div>
}

export default DevProjectFixDefect;