import React, { useState, useCallback, useMemo } from 'react'
import _ from 'lodash'
import ViewQueryDialog from '../../../../../../common/view/viewQueryDialog/ViewQueryDialog'
import { Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'

const ADD_URL = '/testDefect/addReleaseTestDefectList'

export default function RelevanceHistoryDefect(props) {
  const { open, close, releaseId, projectId, refresh, loading } = props
  const [isDisabled, setIsDisabled] = useState(false)
  const { doFetch } = usePost()

  const appendObj = useMemo(() => {
    return {
      factorViewSql: `(test_defect.projectId = '${projectId}' or testDefect_project.fixProjectId = '${projectId}') and test_defect.historyQuestion = 'Y' and (test_defect.fixVersion is null or test_defect.fixVersion not like '%${releaseId}%')`
    }
  }, [projectId, releaseId])

  const outerSetId = useCallback((idList) => {
    if (_.isEmpty(idList)) {
      Messager.show('无变更', { icon: 'info' })
      close()
      return
    }
    if (isDisabled) return
    setIsDisabled(true)
    doFetch(ADD_URL, { releaseId, testDefectIdList: idList })
      .then(() => {
        setIsDisabled(false)
        refresh()
        close()
        Messager.show(`执行成功`, { icon: 'success' })
      })
      .catch((err) => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, close, refresh, releaseId, isDisabled])

  return (
    <>
      {
        open &&
        <ViewQueryDialog
          bizName='历史缺陷'
          multiple={true}
          initValue={[]}
          initItemValue={[]}
          funcCode={'3622'}
          outerSetId={outerSetId}
          initLoading={loading}
          textHint='允许导入修复项目和当前版本所属项目相同的历史缺陷'
          {...{ close, appendObj }}
        />
      }
    </>
  )
}
