import React, { useState, useMemo, useReducer, useEffect, useCallback, useRef } from 'react'
import _ from 'lodash'
import { Badge, Tabs } from 'antd'
import { isNil } from 'rootnet-core/format'
import { useGet } from 'rootnet-biz/lib/hooks'
import { FormInput, Select } from 'rootnet-edit'
import { strParams } from '../../../../utils/publicFun'
import ArrowCrumbs from '../../../common/ArrowCrumbs'
import DevProjectStage from "./devProjectStage/DevProjectStage";
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import DevProjectInfo from './devProjectInfo'
import FileUpload from '../../../common/fileUpload/FileUpload'
import DevProjectScope from "./devProjectScope/DevProjectScope";
import DevProjectFixDefect from "./devProjectFixDefect/DevProjectFixDefect";
import DevProjectTestPlan from "./devProjectTestPlan/DevProjectTestPlan";
import DevProjectReqMatrix from './devProjectReqMatrix'
import DevProjectCostBudgeting from './devProjectCostBudgeting'
import './index.scss'
import DevProjectTask from './devProjectTask'
import { Loader, Messager } from "rootnet-ui";
import convertOptions from '../../../common/ConvertOptions'
import { useFuncCode } from '../../../common/commonMethod'
import DevProjectStatement from './devProjectStatement'
import DevProjectRiskFollowUpAndTodoList from './devProjectRiskFollowUpAndTodoList'

const GLOBAL_CONST_OPTIONS_URLS = [
  '/common/globalconst?globalConst=VERSIONSTATE',
  '/common/globalconst?globalConst=CasePriority', // 发生概率 风险等级
  '/common/globalconst?globalConst=Yes_Or_No',    // 是否已发生  是否展示首页
  '/common/globalconst?globalConst=riskStatus',   // 风险状态
  '/common/globalconst?globalConst=PRODUCTTYPE2', // 版本类型
  '/common/globalconst?globalConst=PRODUCTTYPE', // 版本性质
  '/common/globalconst?globalConst=ProductRound2', // 版本规模
]

const OPTIONS_URLS = [
  '/verQuery/queryCustOptions', // 客户系统
]

const USED_URL = '/userProject/getProjectWorkload'

export default function DevProjectDetail(props) {
  const { currentInfo, setCurrentInfo, allUserRes, history, goBack, firstOrder, secondOrder, isPMM, isQA, isCM: isCMRole, isALL } = props
  const [activeTab, setActiveTab] = useState(isNil(firstOrder) ? 'detail' : firstOrder)
  const [countDynamic, updateDynamic] = useReducer((x) => x + 1, 0) // 动态
  const [countOuterFile, updateOuterFile] = useReducer((x) => x + 1, 0) // 附件
  const [countDetail, upDateDetail] = useReducer((x) => x + 1, 0)//详情
  const [countStage, upDateStage] = useReducer((x) => x + 1, 0)//阶段
  const [countDev, upDateDev] = useReducer((x) => x + 1, 0)//开发自提单
  const [countTask, upDateTask] = useReducer((x) => x + 1, 0)//开发自提单
  const [countReqMatrix, upDateReqMatrix] = useReducer((x) => x + 1, 0) // 需求矩阵
  const [countDefect, upDateDefect] = useReducer((x) => x + 1, 0)//历史缺陷
  const [countTestPlan, updateTestPlan] = useReducer((x) => x + 1, 0)//测试计划
  const [countCostBudgeting, upDateCostBudgeting] = useReducer((x) => x + 1, 0)//项目成本预算
  const [fileCount, setFileCount] = useState(0)
  const [projectId, setProjectId] = useState()
  const [projectInfo, setProjectInfo] = useState()
  const [isPo, setIsPo] = useState(false)
  const [isCM, setIsCM] = useState(false)
  const [isPm, setIsPm] = useState(false)
  const { data: devProjectRes } = useGet('/UserSetting/getUniversalInterfaces?code=ID&codeName=ProjectName&tableName=UserProject')
  const { data: keyNodesRes, doFetch: getKeyNodes } = useGet()
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const [versionLoading, setVersionLoading] = useState(false)
  const [taskVersionLoading, setTaskVersionLoading] = useState(false)
  const { data: versionListRes, doFetch: getVersionList, loading: versionListLoading } = useGet()
  const { data: taskVersionListRes, doFetch: getTaskVersionList } = useGet()
  const [selectedVersionItem, setSelectedVersionItem] = useState()
  const [projectMap, setProjectMap] = useState()
  const versionListRef = useRef([])
  const [isBudget, isCostBudgeting] = useFuncCode(['3602', '3603'])

  const [isImport, setIsImport] = useState()
  const { data: isCoding, doFetch: getIsCoding } = useGet()
  const { data: usedData, doFetch: usedFetch } = useGet()

  const { id: currentId } = useMemo(() => currentInfo, [currentInfo])

  const [scopeActiveModule, setScopeActiveModule] = useState('all')//项目范围类型选择

  useEffect(() => {
    if (_.isNil(currentId)) return
    usedFetch(`${USED_URL}?id=${currentId}`)
  }, [usedFetch, currentId])

  useEffect(() => {
    if (isNil(currentId)) return
    getIsCoding(`/userProject/stage/isPermissionUpdate?${strParams({ projectId: currentId })}`)
  }, [getIsCoding, currentId])

  useEffect(() => {
    versionListRef.current = _.get(versionListRes, 'releaseInfoList') || []
  }, [versionListRes])

  useEffect(() => {
    window.addEventListener("popstate", popStateHandle, false);
    function popStateHandle(e) {
      if (!_.includes(e.target.location.href, 'initId')) {
        setCurrentInfo(null)
        // history.replace({ pathname: '/devProjectMgt' })
      }
    }
    return () => {
      window.removeEventListener('popstate', popStateHandle)
    }
  }, [setCurrentInfo, history])

  const refreshScopeVersionList = useCallback((withLoading) => {
    if (_.isNil(projectId)) return
    if (withLoading) {
      setVersionLoading(true)
    }
    getVersionList('/userProject/statistics?' + strParams({ projectId })).then(() => {
      setVersionLoading(false)
    }).catch(err => {
      setVersionLoading(false)
      Messager.show(err.Message, { icon: 'error' })
    })
  }, [getVersionList, projectId])

  const refreshTaskVersionList = useCallback((withLoading) => {
    if (_.isNil(projectId)) return
    if (withLoading) {
      setTaskVersionLoading(true)
    }
    getTaskVersionList('/userProject/statisticsTask?' + strParams({ projectId })).then(() => {
      setTaskVersionLoading(false)
    }).catch(err => {
      setTaskVersionLoading(false)
      Messager.show(err.Message, { icon: 'error' })
    })
  }, [getTaskVersionList, projectId])

  const refreshVersionList = useCallback((withLoading = true) => {
    refreshScopeVersionList(withLoading)
    refreshTaskVersionList(withLoading)
  }, [refreshScopeVersionList, refreshTaskVersionList])

  useEffect(() => {
    refreshVersionList()
  }, [refreshVersionList])

  const [customerSystemOpt] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1] = optionsRes
    return [
      _.concat([{ label: '全部客户', value: 'ALL' }], _.map(d1, o => ({ label: o.projectName, value: o.projectId }))),
    ]
  }, [optionsRes])

  const [versionStatusOptions, rankOptions, whetherOptions, riskStatusOptions, typeOpt, natureOpt, scaleOpt] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const addVersionAuth = useMemo(() => (isPMM || isCM || isPo), [isPMM, isCM, isPo])

  const { userprojectType, status } = useMemo(() => projectInfo || {}, [projectInfo])

  const keyNodesOptions = useMemo(() => {
    if (_.isEmpty(keyNodesRes)) return []
    return convertGlobalConstOptions(keyNodesRes)
  }, [keyNodesRes])

  useEffect(() => {
    // if (_.isNil(userprojectType)) return
    getKeyNodes(`/common/globalconst?globalConst=allKeyNodes`)
  }, [getKeyNodes])

  useEffect(() => {
    if (_.isNil(projectId) || _.isNil(currentId)) return
    if (_.isNil(_.get(projectMap, 'currentId')) || _.isNil(_.get(projectMap, 'projectId'))) {
      setProjectMap({
        currentId,
        projectId
      })
    } else if (_.get(projectMap, 'currentId') !== currentId && _.get(projectMap, 'projectId') !== projectId) {
      setProjectMap({
        currentId,
        projectId
      })
      setSelectedVersionItem(null)
    }
  }, [projectId, currentId, projectMap])

  const listOpt = useMemo(() => {
    if (_.isEmpty(devProjectRes)) return []
    return convertGlobalConstOptions(devProjectRes)
  }, [devProjectRes])

  const goBackList = useMemo(() => {
    return [
      { text: '研发项目列表' },
      {
        text: <FormInput search horizontal
          options={listOpt}
          component={Select}
          value={currentId}
          componentClass={'long-options'}
          onChange={v => {
            setCurrentInfo({ id: v, mode: 'detail' })
            history.replace({ pathname: '/devProjectMgt', search: strParams({ initId: v, firstOrder: activeTab }) })
          }}
        />,
        active: true
      },
    ]
  }, [activeTab, currentId, history, listOpt, setCurrentInfo])

  const operations = useMemo(() => {
    return {
      left: <div>
        <ArrowCrumbs data={goBackList} goBack={goBack} />
      </div>
    }
  }, [goBack, goBackList])

  const isImportReq = useMemo(() => _.includes(['4', '6'], status), [status])

  const isShow = useMemo(() => {
    return !(_.includes(['PAT', 'SOL'], userprojectType) && _.includes(['1', '7'], status))
  }, [userprojectType, status])

  const isFinanceCoding = useMemo(() => {
    return (!_.includes(['PAT', 'SOL'], userprojectType) && isCoding === false)
  }, [userprojectType, isCoding])

  const isRelevance = useMemo(() => !_.includes(['1', '3', '7'], status), [status]) //项目关联是否隐藏 关闭 暂缓 作废
  const isDisassociation = useMemo(() => !_.includes(['1'], status), [status]) // 取消关联 关闭
  return (
    <div className={'dev-project-detail'}>
      <Tabs
        centered
        className='dev-project-tabs'
        activeKey={activeTab}
        tabBarExtraContent={operations}
        onChange={v => {
          if (v === 'detail') {
            upDateDetail()
            updateDynamic()
          } else if (v === 'stage') {
            upDateStage()
          } else if (v === 'scope') {
            upDateDev()
            refreshVersionList()
          } else if (v === 'task') {
            if (_.includes(['no-plan-version', 'history-defect'], _.get(selectedVersionItem, 'releaseID'))) setSelectedVersionItem(null)
            upDateTask()
            refreshVersionList()
          } else if (v === 'fixDefect') {
            upDateDefect()
            refreshVersionList()
          } else if (v === 'testPlan') {
            updateTestPlan()
            refreshVersionList()
          } else if (v === 'reqMatrix') {
            upDateReqMatrix()
          } else if (v === 'costBudgeting') {
            upDateCostBudgeting()
          } else if (v === 'file') {
            updateOuterFile()
          }
          if (v !== 'fixDefect') {
            if (_.get(selectedVersionItem, 'releaseID') === 'history-defect') {
              setSelectedVersionItem(null)
            }
          }
          history.replace({ pathname: '/devProjectMgt', search: strParams({ initId: currentId, firstOrder: v }) })
          setActiveTab(v)
        }}
        items={_.compact([
          {
            key: 'detail',
            label: '详情',
            forceRender: true,
            children: <div key={countDetail} className={'tab-content-wrap flex'}>
              <DevProjectInfo {...{ currentId, allUserRes, countDynamic, updateDynamic, setProjectId, setProjectInfo, isPMM, isPo, isQA, setIsPo, keyNodesOptions, rankOptions, whetherOptions, riskStatusOptions, projectId, setIsCM, updateOuterFile, setIsImport, isShow, isCMRole, isALL, isBudget, usedData, isPm, setIsPm }} />
            </div>,
          },
          {
            key: 'stage',
            label: '计划',
            children: <div key={countStage} className={'tab-content-wrap flex'}>
              <DevProjectStage {...{ currentId, allUserRes, updateOuterFile, projectInfo, isPMM, isPo, keyNodesOptions, upDateDetail, isImport, isFinanceCoding, isShow }} />
            </div>,
          },
          {
            key: 'scope',
            label: '项目范围',
            children: <div key={countDev} className={'tab-content-wrap flex'}>
              {versionListLoading && <Loader fill />}
              {!versionListLoading && <DevProjectScope
                activeModule={scopeActiveModule} setActiveModule={setScopeActiveModule}
                {...{
                  initVersionDate: _.get(projectInfo, 'beginDate'),
                  currentId: _.get(projectInfo, 'projectID'),
                  updateDynamic, versionStatusOptions, addVersionAuth, selectedVersionItem, setSelectedVersionItem,
                  refreshVersionList, projectMap, versionListRes, versionLoading, isPo, isPMM, isCM, isQA, typeOpt,
                  natureOpt, scaleOpt, customerSystemOpt, allUserRes, isShow, versionListRef, isRelevance, isDisassociation, isImportReq,
                }} />}
            </div>
          },
          {
            key: 'task',
            label: '任务',
            children: <div key={countTask} className={'tab-content-wrap flex'}>
              <DevProjectTask
                versionLoading={taskVersionLoading}
                versionListRes={taskVersionListRes}
                {...{
                  versionStatusOptions, addVersionAuth, selectedVersionItem, setSelectedVersionItem, refreshVersionList,
                  projectMap, typeOpt, natureOpt, scaleOpt, customerSystemOpt, allUserRes, isShow, projectId, projectName: convertOptions(currentId, listOpt)
                }} />
            </div>
          },
          {
            key: 'reqMatrix',
            label: '需求矩阵',
            children: <div key={countReqMatrix} className={'tab-content-wrap flex'}>
              {versionListLoading && <Loader fill />}
              {!versionListLoading && <DevProjectReqMatrix {...{ projectId, isPo, isPMM, isShow, versionListRes }} />}
            </div>,
          },
          {
            key: 'testPlan',
            label: '测试进展',
            children: <div key={countTestPlan} className={'tab-content-wrap flex'}>
              <DevProjectTestPlan {...{
                versionStatusOptions, addVersionAuth, selectedVersionItem, setSelectedVersionItem, refreshVersionList,
                versionListRef, projectMap, versionListRes, versionLoading, typeOpt, natureOpt, scaleOpt, customerSystemOpt, allUserRes, isShow
              }} />
            </div>,
          },
          {
            key: 'fixDefect',
            label: '缺陷',
            children: <div key={countDefect} className={'tab-content-wrap flex'}>
              <DevProjectFixDefect canRelate={isPo || isCM || isPMM} {...{
                versionStatusOptions, addVersionAuth, selectedVersionItem, setSelectedVersionItem, refreshVersionList,
                projectMap, versionListRes, versionLoading, isShow, isRelevance
              }} />
            </div>,
          },
          {
            key: 'riskFollowUpAndTodoList',
            label: '风险及问题跟踪',
            children: <DevProjectRiskFollowUpAndTodoList {...{ history, projectId, isPo, isPMM, rankOptions, whetherOptions, riskStatusOptions, allUserRes, isShow, currentId, updateDynamic, secondOrder }} />
          },
          (isPMM || isPo || isPm || isCostBudgeting) && {
            key: 'costBudgeting',
            label: '项目成本预算',
            children: <div key={countCostBudgeting} className={'tab-content-wrap flex'}>
              <DevProjectCostBudgeting projectId={currentId} {...{ isPo, isPMM, isShow, isPm, projectInfo }} />
            </div>
          },
          {
            key: 'statement',
            label: '项目报表',
            children: <DevProjectStatement {...{ projectId }} />,
          },
          {
            key: 'file',
            label: <Badge count={fileCount} size="small" offset={[8, 0]} color={'#CCCCCC'}>
              附件
            </Badge>,
            forceRender: true,
            children: <div className={'tab-content-wrap file-wrap'} key={countOuterFile}>
              <FileUpload funcCode={'36'} {...{ id: currentId, mode: 'edit', setFileCount, allUserRes, isUpload: isShow, isDel: isShow }} />
            </div>,
          },
        ])}
      />
    </div>
  )
}
