import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import moment from 'moment'
import './WorkTimeReport.scss'
import WorkTimeReportHandleRow from "./workTimeReportHandleRow/WorkTimeReportHandleRow";
import WorkTimeTable from "./workTimeTable/WorkTimeTable";
import _ from 'lodash'
import { uniqKeyFor } from "rootnet-biz/es/utils";
import { useGet, usePost } from "rootnet-biz/es/hooks";
import gd from "../../../base/global";
import { Messager } from "rootnet-ui";
import { pathSearchFor, strParams } from "../../../utils/publicFun";
import { isNil } from "../../appraise/components/method";

function WorkTimeReport(props) {
    const { location } = props;
    const { initDate = new Date(), initUser = null, isDisplay = false } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
    const [initData, setInitData] = useState()
    const [editData, setEditData] = useState()
    const [selectedDate, setSelectedDate] = useState(moment(initDate))
    const [othersReport, setOthersReport] = useState(initUser)
    const [mode, setMode] = useState('detail')
    const { data: listRes, doFetch: getList, loading: detailLoading } = usePost()
    const { doFetch: submitPost } = usePost()
    const { data: workDaysRes, doFetch: getWorkDays } = useGet()
    const [submitLoading, setSubmitLoading] = useState(false)
    const { doFetch: getLastWeekList } = usePost()
    const richEditorRef = useRef()

    const initExperience = useMemo(() => {
        const experience = _.get(_.head(initData), 'experience') || ''
        return experience
    }, [initData])

    useEffect(() => {
        if (mode === 'detail') return
        setTimeout(() => {
            if (richEditorRef.current) {
                richEditorRef.current.setContent(initExperience)
            }
        }, 500)
    }, [mode, initExperience])

    useEffect(() => {
        if (mode === 'detail') return
        const projectList = _.filter(editData, x => x.type !== 'transaction')
        if (_.isEmpty(projectList)) {
            setEditData(old => _.compact(_.concat(old, {
                type: 'dev',
                uniqId: uniqKeyFor(),
            })))
        }
        const transactionList = _.filter(editData, x => x.type === 'transaction')
        if (_.isEmpty(transactionList)) {
            setEditData(old => _.compact(_.concat(old, {
                type: 'transaction',
                uniqId: uniqKeyFor(),
            })))
        }
    }, [editData, mode])

    useEffect(() => {
        const searchParams = {
            beginDate: moment(selectedDate).startOf('isoWeek').format('YYYY-MM-DD'),
            endDate: moment(selectedDate).endOf('isoWeek').format('YYYY-MM-DD'),
            userId: othersReport || gd.User.operator_id
        }
        getWorkDays('/workingHoursManagement/getWorkingHoursHeader?' + strParams(searchParams)).then(res => {
            const leaveDate = _.get(res, 'leaveDate')
            if (_.isNil(leaveDate)) return
            const isAfterLeaveDate = moment(moment(leaveDate).endOf('isoWeek').format('YYYY-MM-DD')).isSameOrAfter(searchParams.endDate)
            if (!isAfterLeaveDate) {
                setSelectedDate(moment(leaveDate))
            }
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' });
        })
    }, [getWorkDays, selectedDate, othersReport])

    const [beginDate, endDate] = useMemo(() => {
        return [
            moment(selectedDate).startOf('isoWeek').format('YYYY-MM-DD'),
            moment(selectedDate).endOf('isoWeek').format('YYYY-MM-DD'),
        ]
    }, [selectedDate])

    const refreshList = useCallback(() => {
        setSubmitLoading(true)
        const searchParams = {
            pageNum: 1,
            pageSize: 1000,
            type: "1",
            beginDate: {
                begin: beginDate + ' 00:00:00',
                end: endDate + ' 23:59:59',
            },
            endDate: {
                begin: beginDate + ' 00:00:00',
                end: endDate + ' 23:59:59',
            },
            behalfSubmit: othersReport
        }
        getList('/workingHoursManagement/selectRecordPage', searchParams)
            .then(() => {
                setSubmitLoading(false)
            })
            .catch((err) => {
                setSubmitLoading(false)
                Messager.show(err._message, { icon: 'error' });
            })
    }, [getList, othersReport, beginDate, endDate])

    useEffect(() => {
        refreshList()
    }, [refreshList])

    useEffect(() => {
        const list = _.sortBy(_.get(listRes, 'rows'), x => _.indexOf(['审核拒绝', '审核中', '审核完成'], x.stateName))
        const handleData = _.map(list, x => ({
            ...x,
            weeklyInputPercentage: _.round(x.weeklyInputPercentage * 100),
            uniqId: uniqKeyFor(),
        }))
        setEditData(_.cloneDeep(handleData))
        setInitData(handleData)
    }, [listRes])

    const submit = useCallback(() => {
        if (submitLoading) return
        let submitParams = []
        const effectiveEditData = _.filter(editData, x => {
            if (x.type === 'dev') {
                return !_.isNil(_.get(x, 'projectId')) && !isNil(_.get(x, 'weeklyInputPercentage')) && !isNil(_.get(x, 'workingProjectType'))
            }
            return !_.isNil(_.get(x, 'projectId')) && !isNil(_.get(x, 'weeklyInputPercentage'))
        })
        const addList = _.filter(effectiveEditData, x => _.isNil(_.get(x, 'id')))
        if (!_.isEmpty(addList)) {
            submitParams = _.concat(submitParams, _.map(addList, x => ({
                ...x,
                beginDate,
                endDate,
                operatorType: 'add',
                weeklyInputPercentage: x.weeklyInputPercentage / 100,
                userAccount: othersReport || gd.User.operator_id,
                actualSunday: _.toNumber(_.get(workDaysRes, 'numberWorkingDays')),
                standardSunday: _.toNumber(_.get(workDaysRes, 'standardSunday')),
            })))
        }
        const editIdList = _.map(effectiveEditData, x => _.get(x, 'id'))
        const delList = _.filter(initData, x => !_.includes(editIdList, x.id))
        if (!_.isEmpty(delList)) {
            submitParams = _.concat(submitParams, _.map(delList, x => (_.assign({}, x, {
                id: x.id,
                operatorType: 'del',
                userAccount: othersReport || gd.User.operator_id,
            }))))
        }
        const editList = _.filter(effectiveEditData, item => {
            if (_.isNil(_.get(item, 'id'))) return false
            const findInitItem = _.find(initData, x => x.id === item.id)
            if (findInitItem.stateName === '审核拒绝') return true
            if (findInitItem.projectId !== item.projectId) return true
            if (findInitItem.weeklyInputPercentage !== item.weeklyInputPercentage) return true
            if (findInitItem.description !== item.description) return true
            if (findInitItem.workingProjectType !== item.workingProjectType) return true
            return false
        })
        if (!_.isEmpty(editList)) {
            const submitEditList = _.map(editList, item => {
                const findInitItem = _.find(initData, x => x.id === item.id)
                const resetFlag = (findInitItem.stateName === '审核拒绝') ||
                    (findInitItem.projectId !== item.projectId) ||
                    (findInitItem.weeklyInputPercentage !== item.weeklyInputPercentage) ||
                    (findInitItem.workingProjectType !== item.workingProjectType)
                return {
                    ...item,
                    beginDate,
                    endDate,
                    state: '1',
                    operatorType: 'edit',
                    weeklyInputPercentage: item.weeklyInputPercentage / 100,
                    userAccount: othersReport || gd.User.operator_id,
                    actualSunday: _.toNumber(_.get(workDaysRes, 'numberWorkingDays')),
                    standardSunday: _.toNumber(_.get(workDaysRes, 'standardSunday')),
                    reFlag: resetFlag ? '0' : null
                }
            })
            submitParams = _.concat(submitParams, submitEditList)
        }

        const unEditList = _.map(_.filter(effectiveEditData, o => !_.includes(_.map(submitParams, 'id'), o.id)), o => {
            return {
                ...o,
                operatorType: null,
                weeklyInputPercentage: o.weeklyInputPercentage / 100,
                userAccount: othersReport || gd.User.operator_id,
            }
        })
        const oldExperience = _.isNil(_.get(_.head(initData), 'experience')) ? '' : _.get(_.head(initData), 'experience')
        const newExperience = richEditorRef?.current?.getContent()
        if (_.isEmpty(submitParams) && oldExperience === newExperience) {
            Messager.show('无变更');
            setMode('detail')
            setEditData(_.cloneDeep(initData))
            setSubmitLoading(false)
            return
        }
        if (richEditorRef?.current?.loading) {
            return Messager.show("图片上传中，请稍后保存")
        }
        setSubmitLoading(true)
        const allList = _.map(_.concat(submitParams, unEditList), o => _.assign({}, o, { experience: newExperience }))
        submitPost('/workingHoursManagement/operator', allList).then(() => {
            Messager.show('提交成功', { icon: 'success' });
            setMode('detail')
            setSubmitLoading(false)
            refreshList()
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' });
            setSubmitLoading(false)
        })
    }, [editData, initData, beginDate, endDate, submitPost, othersReport, refreshList, workDaysRes, submitLoading])

    const importLastWeekData = useCallback((allProjectList, fun = () => { }) => { //导入上周数据
        // 获取当前日期对象
        const currentDate = moment(selectedDate);
        const weekNum = 1 //前多少周
        const weekOfDay = parseInt(currentDate.format('E'));//计算今天是这周第几天
        const lastWeek = currentDate.subtract(weekOfDay + 7 * weekNum - 1, 'days')
        const begin = lastWeek.clone().startOf('week').format('YYYY-MM-DD') //周一日期
        const end = lastWeek.clone().endOf('week').format('YYYY-MM-DD') //周日日期
        const searchParams = {
            pageNum: 1,
            pageSize: 1000,
            type: "1",
            beginDate: {
                begin: begin + ' 00:00:00',
                end: end + ' 23:59:59',
            },
            endDate: {
                begin: begin + ' 00:00:00',
                end: end + ' 23:59:59',
            },
            behalfSubmit: othersReport
        }
        getLastWeekList('/workingHoursManagement/selectRecordPage', searchParams)
            .then(res => {
                const experience = _.get(_.head(res?.rows), 'experience') || ''
                richEditorRef.current.setContent(experience)
                const list = _.sortBy(_.get(res, 'rows'), x => _.indexOf(['审核拒绝', '审核中', '审核完成'], x.stateName))
                const handleData = _.map(list, x => {
                    let pick = ['type', 'projectName', 'workingProjectType', 'projectId', 'projectCode', 'description']
                    if (x.type === 'dev') {//status 1 关闭， 3 暂缓， 7 作废 
                        const findItem = _.find(allProjectList, o => o.projectID === x.projectCode)
                        if (_.includes(['1', '3', '7'], _.get(findItem, 'status')) || _.isNil(findItem)) {
                            pick = ['type', 'description']
                        }
                    }
                    return {
                        ..._.pick(x, pick),
                        weeklyInputPercentage: _.round(x.weeklyInputPercentage * 100),
                        uniqId: uniqKeyFor(),
                    }
                })
                setEditData(handleData)
                fun()
            })
            .catch((err) => {
                fun()
                Messager.show(err._message, { icon: 'error' });
            })

    }, [getLastWeekList, othersReport, selectedDate])

    return <div className={'work-time-report fill flex-y'}>
        <WorkTimeReportHandleRow {...{
            selectedDate, setSelectedDate, othersReport, setOthersReport, mode, setMode,
            editData, initData, setEditData, refreshList, submit, workDaysRes, isDisplay, submitLoading
        }} />
        <WorkTimeTable {...{ editData, setEditData, mode, initData, detailLoading, submitLoading, othersReport, importLastWeekData, richEditorRef, initExperience }} />
    </div>
}

export default WorkTimeReport;