import React, { useCallback, useMemo, useState } from 'react';
import _ from "lodash";
import { Badge, Popover } from 'antd'
import { DataGrid, Messager, Pagination } from "rootnet-ui";
import { useGet, usePost } from "rootnet-biz/lib/hooks";
import convertTableAlign from '../../../../../common/view/convertTableAlign';
import findConvert from '../../../../../common/view/findConvert';
import useGetViewConfig from '../../../../../common/view/hooks/useGetViewConfig';
import useRefreshList from '../../../../../common/view/hooks/useRefreshList';
import useGetDateOptions from '../../../../../common/view/hooks/useGetDateOptions';
import useGetTreeList from '../../../../../common/view/hooks/useGetTreeList';
import { Box, TextIconBtn } from '../../../../../common/commonComponent';
import { API1 } from '../../../../../../base/task';
import ViewArea from '../../../../../common/view/ViewArea';
import ProjectChangeRequest from '../projectChangeRequest'
import ProjectChangeHistory from '../projectChangeHistory'
import ReqViewSearchDialog from '../../../../../common/publicPage/publicReq/reqViewSearchDialog';
import { strParams } from '../../../../../../utils/publicFun';
import './index.scss'
import ViewQueryDialog from '../../../../../common/view/viewQueryDialog/ViewQueryDialog';
import { isNil } from '../../../../../appraise/components/method';
import ImportVersion from './components/importVersion';
import RemoveItem from './components/removeItem';
import AssociatedVersion from './components/associatedVersion';
import ImportPlannedProject from './components/importPlannedProject';
import DelVersionItem from './components/delVersionItem';
import RequirementDetailDialog from '../../../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog';
import IssueDetailDialog from '../../../../../issueMgt/components/issueDetailDialog/IssueDetailDialog';
import DefectUpdateDialog from '../../../../../testMgt/defect/controls/DefectUpdateDialog';
import ContrastEditFile from './components/contrastEditFile';
import ImportApiDialog from '../../../../../common/ImportApiDialog';
import CopyMessage from '../../../../../versionMgt/versionMsg/components/versionDetail/versionRequirementMgt/copyMessage';
import ImportRegressionVersion from './components/importRegressionVersion';
import ContrastDifference from './components/contrastDifference';
import InnerVersionBoardDialog from './components/innerVersionBoardDialog';

const RELATED_REQ_URL = '/implement/project/related/requirement'
const CANCEL_RELATED_REQ_URL = '/implement/project/related/requirement/del'

const ENTER_DETAIL_FIELD = 'View_Project_AllTask.title'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, isAll, setCurrentInfo, isVersion } = props

  const customColumns = []
  const findReq = _.get(_.find(fieldList, o => `${o.tableName}.${o.fieldId}` === 'View_Project_AllTask.storyId'), 'columnId')
  const taskType = _.get(_.find(fieldList, o => `${o.tableName}.${o.fieldId}` === 'View_Project_AllTask.taskType'), 'columnId')
  return _.compact([
    { selection: true },
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ]).concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (tableField === 'View_Project_AllTask.taskType') {
      if (v !== 'DEFECT' && isAll) o['_selectable'] = false
      if (v === 'DEFECT' && isVersion) o['_selectable'] = false
      o['taskType'] = v
      o['type'] = v
      if (v === 'REQ') {
        o['idReq'] = o[findReq]
      }
    }
    if (tableField === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field'} onClick={() => {
        setCurrentInfo({ mode: o[taskType], id: o.id })
      }}>
        {showValue}
      </div>
    }
    return showValue
  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '1622'

export default function DevProjectScopeAllInfo(props) {
  const { isPo, isPMM, isCM, untreatedNum, refreshProject, projectChangeRequestData, currentId, canRelate, module, selectedVersion, selectedVersionItem, projectType = 'DEV', projectId, id, updateDynamic, issueRelateFactorViewSql, defectRelateFactorViewSql, isRelateProject, allFilterSql, versionList, isRelevance, isDisassociation, searchAll, allList, isImportReq, isQA, versionCanRelate, refreshAllList } = props
  const [currentInfo, setCurrentInfo] = useState()
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [factor, setFactor] = useState()
  const [joinApplyProject, setJoinApplyProject] = useState()
  const [changeHistory, setChangeHistory] = useState()
  const { data: allowExport } = useGet(`/FuncTable/getAllowExportFlag?funcCode=` + FUNC_CODE)
  const { doFetch } = usePost()
  const [relateLoading, setRelateLoading] = useState(false)
  const isAll = useMemo(() => isNil(selectedVersion), [selectedVersion])
  const isNoPlanVersion = useMemo(() => selectedVersion === 'no-plan-version', [selectedVersion])
  const isVersion = useMemo(() => (!isAll && !isNoPlanVersion), [isAll, isNoPlanVersion])
  const [selection, setSelection] = useState([])

  const filterDefectSelection = useMemo(() => {
    return _.filter(selection, o => o.type !== 'DEFECT')
  }, [selection])

  const appendObj = useMemo(() => {
    return { factorViewSql: allFilterSql }
  }, [allFilterSql])

  const isInnerBoard = useMemo(() => {
    return (isPo || isPMM || isCM) && _.get(selectedVersionItem, 'versionInner') === 'Y' && _.get(selectedVersionItem, 'state') === 'T'
  }, [isPo, isCM, isPMM, selectedVersionItem])

  const relateTextHint = useMemo(() => {
    if (isNil(selectedVersion) || selectedVersion === 'no-plan-version') {
      return <span>
        查询结果为修复项目为当前项目，历史问题=是，且已过滤缺陷单中<span style={{ color: 'red' }}>发布客户</span>为空的数据，请及时编辑补充！
      </span>
    } else {
      return <span>
        修复版本为当前版本，历史问题，且已过滤缺陷单中<span style={{ color: 'red' }}>发布客户</span>为空的数据
      </span>
    }
  }, [selectedVersion])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, appendObj })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: {
      isAll, isVersion,
      setCurrentInfo
    }
  })

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = _.assign({}, {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor,
    }, appendObj)
    Messager.show('导出中...')
    API1.downloadPost('/UserSetting/exportExcel', postParams)
  }, [currentViewId, fieldList, factor, appendObj])

  const relatedReq = useCallback((idList) => {
    if (_.isEmpty(idList)) return Messager.show('无变更', { icon: 'info' })
    doFetch(`${RELATED_REQ_URL}?${strParams({ id, projectType, funcCode: '36', projectId })}`, idList)
      .then(() => {
        refreshAllList()
        updateDynamic()
        refreshList()
        Messager.show('关联成功', { icon: 'success', duration: 2000 })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [doFetch, id, projectId, projectType, refreshList, updateDynamic, refreshAllList])

  const cancelRelatedReq = useCallback((items, flag = true) => {
    if (_.isEmpty(items)) return Messager.show('请选择后在进行操作', { icon: 'error' })
    const ids = _.map(items, o => o.storyId)
    doFetch(`${CANCEL_RELATED_REQ_URL}?${strParams({ projectType, funcCode: '36', projectId })}`, ids)
      .then(() => {
        flag && updateDynamic()
        flag && refreshList()
        refreshAllList()
        Messager.show('取消关联成功', { icon: 'success', duration: 2000 })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [doFetch, projectType, refreshList, updateDynamic, projectId, refreshAllList])

  const onIssueRelate = useCallback((list) => {
    if (_.isEmpty(list)) return
    const postParams = {
      id,
      userProjectId: projectId,
      issueIds: _.map(list, 'id')
    }
    doFetch('/issue/relationIssueDevProject', postParams).then(res => {
      Messager.show('关联成功', { icon: 'success' });
      refreshList()
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' })
    })
  }, [id, projectId, doFetch, refreshList])

  const onDefectRelate = useCallback((list) => {
    if (_.isEmpty(list)) return
    if (relateLoading) return
    setRelateLoading(true)
    const idList = _.map(list, 'id')
    let url
    if (module === 'fixDefect') {
      url = '/userProject/batchRelationDefectProject'
    } else if (isRelateProject) {
      url = '/userProject/relationDefectProject'
    } else {
      url = '/releaseStory/relationDefectBaseline'
    }
    let postParams
    if (isRelateProject) {
      postParams = {
        action: 'add',
        id,
        fixProjectId: projectId,
        testDefects: _.map(idList, x => ({ id: x }))
      }
    } else {
      postParams = {
        id: _.get(selectedVersionItem, 'snowId'),
        defectMark: 'Y',
        releaseId: _.get(selectedVersionItem, 'releaseID'),
        storyIds: _.map(idList, v => ({ id: v })),
      }
    }
    doFetch(url, postParams).then(res => {
      refreshList()
      setRelateLoading(false)
      Messager.show('关联成功', { icon: 'success' });
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' })
      setRelateLoading(false)
    })
  }, [refreshList, isRelateProject, doFetch, selectedVersionItem, relateLoading, module, id, projectId])

  const dataManipulation = useCallback((par, flag = true) => {
    doFetch('/ReleaseStory/addReleaseStoryList', par)
      .then(() => {
        setSelection([])
        refreshList()
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [doFetch, refreshList])

  const extra = useMemo(() => {
    // isNoPlanVersion
    return <div className='extra-group flex center-y'>
      {isVersion && isInnerBoard && <TextIconBtn icon='' text='inner版本一键上车' onClick={() => setCurrentInfo({ mode: 'innerBoard' })} />}
      <ContrastDifferenceOperations {...{ setCurrentInfo }} />
      {//所有
        isAll && (isPo || isPMM) &&
        <>
          <Badge count={untreatedNum} size="small" offset={[8, 0]}>
            <TextIconBtn icon='' text='项目变动申请' onClick={() => setJoinApplyProject(true)} />
          </Badge>

        </>
      }
      {
        isAll && (isPo || isPMM || isQA) && <TextIconBtn icon='' text='项目变更历史' onClick={() => setChangeHistory(true)} />
      }
      {//所有
        isAll && canRelate && (isPo || isPMM) && isRelevance &&
        <RelevancyOperations {...{ isPo, isPMM, module, isRelevance, selectedVersion, selectedVersionItem, setCurrentInfo, isImportReq, selection }} />
      }
      {//未规划版本
        isNoPlanVersion && (isPo || isPMM) && isDisassociation &&
        <>
          {
            isRelevance &&
            <TextIconBtn icon='' text='导入版本' onClick={() => {
              if (_.isEmpty(selection)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
              setCurrentInfo({ mode: 'versionPlan' })
            }} />
          }
          <TextIconBtn icon='' text='移除项目' onClick={() => {
            if (_.isEmpty(selection)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
            setCurrentInfo({ mode: 'cancelProjectRelation' })
          }} />
        </>
      }
      {//选择版本后
        isVersion && versionCanRelate && isRelevance &&
        <TextIconBtn text={'复制至其他版本'} onClick={() => {
          if (_.isEmpty(filterDefectSelection)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
          setCurrentInfo({ mode: 'copyValues' })
        }} />
      }
      {//选择版本后
        isVersion && _.includes(['T', 'C'], _.get(selectedVersionItem, 'state')) && (isPo || isPMM) &&
        <>
          <TextIconBtn icon='' text='导入版本' onClick={() => setCurrentInfo({ mode: 'joinVersion' })} />
          <TextIconBtn icon='' text='移除版本' onClick={() => {
            if (_.isEmpty(selection)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
            setCurrentInfo({ mode: 'delVersion' })
          }} />
        </>
      }
      {
        allowExport === 'Y' &&
        <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      }
    </div>
  }, [exportViewList, allowExport, untreatedNum, isPo, isPMM, canRelate, module, isRelevance, selectedVersion, selectedVersionItem, isAll, isNoPlanVersion, isVersion, selection, isDisassociation, isImportReq, isQA, versionCanRelate, filterDefectSelection, isInnerBoard])

  return (
    <div className='dev-project-scope-all-info'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'所有'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} selection={selection} onSelectionChange={setSelection} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {//项目变动申请
        joinApplyProject &&
        <ProjectChangeRequest close={() => setJoinApplyProject(null)} {...{ refreshProject, projectChangeRequestData }} />
      }
      {//项目变更历史
        changeHistory &&
        <ProjectChangeHistory projectId={currentId} close={() => setChangeHistory(null)} />
      }
      {//版本规划
        _.get(currentInfo, 'mode') === 'versionPlan' &&
        <ImportVersion
          {...{ selection, setSelection, refreshList, versionList }}
          close={() => setCurrentInfo(null)}
        />
      }
      {//取消项目关联
        _.get(currentInfo, 'mode') === 'cancelProjectRelation' &&
        <RemoveItem
          {...{ selection, setSelection, refreshList, id, projectId }}
          close={() => setCurrentInfo(null)}
        />
      }
      {//关联需求
        _.get(currentInfo, 'mode') === 'relevancyReq' &&
        <ReqViewSearchDialog
          close={() => setCurrentInfo(null)}
          initId={_.map(_.filter(allList, o => o.taskType === 'REQ'), 'id')}
          FUNC_CODE={'3600'}
          {...{ relatedReq, cancelRelatedReq }}
        />
      }
      {//版本关联
        _.get(currentInfo, 'mode') === 'joinVersion' &&
        <AssociatedVersion
          searchAll={searchAll}
          defaultData={isVersion ? _.filter(allList, o => _.includes(_.split(o.releaseIds, ','), selectedVersion)) : allList}
          close={() => setCurrentInfo(null)}
          refreshList={() => {
            refreshList()
            refreshAllList()
          }}
          {...{ selectedVersion }}
        />
      }
      {//版本移除
        _.get(currentInfo, 'mode') === 'delVersion' &&
        <DelVersionItem
          releaseid={selectedVersion}
          close={() => setCurrentInfo(null)}
          refreshList={() => {
            refreshList()
            refreshAllList()
          }}
          {...{ selection, setSelection }}
        />
      }
      {//关联ISSUE
        _.get(currentInfo, 'mode') === 'relevancyIssue' &&
        <ViewQueryDialog
          initValue={[]}
          multiple={true}
          funcCode={'0005'}
          bizName={'ISSUE'}
          initItemValue={[]}
          outerSetItem={onIssueRelate}
          close={() => setCurrentInfo(null)}
          bizField={'ProductionIssue.IssueId'}
          appendObj={{ factorViewSql: issueRelateFactorViewSql }}
        />
      }
      {//关联缺陷
        _.get(currentInfo, 'mode') === 'relevancyDefect' &&
        <ViewQueryDialog
          close={() => setCurrentInfo(null)}
          initValue={[]}
          textHint={relateTextHint}
          outerSetItem={onDefectRelate}
          funcCode={'3612'}
          bizField={'test_defect.defectId'}
          bizName={'缺陷'}
          multiple={true}
          initItemValue={[]}
          appendObj={{ factorViewSql: defectRelateFactorViewSql }}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'copyValues' &&
        <CopyMessage
          {...{ projectId }}
          selectedRows={filterDefectSelection}
          actionType={_.get(currentInfo, 'mode')}
          currentId={selectedVersion}
          close={() => setCurrentInfo(null)}
          isDisabled={true}
          isDisplay={false}
          changeData={{}}
          dataManipulation={dataManipulation}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'relevancyPlannedProject' &&
        <ImportPlannedProject
          {...{ id, projectId, allList }}
          refreshList={() => {
            refreshList()
            refreshAllList()
          }}
          close={() => setCurrentInfo(null)}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'REQ' &&
        <RequirementDetailDialog
          currentInfo={{ id: _.get(currentInfo, 'id') }}
          close={() => setCurrentInfo(null)}
          refreshViewList={refreshList}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'ISSUE' &&
        <IssueDetailDialog
          currentInfo={{ id: _.get(currentInfo, 'id') }}
          close={() => setCurrentInfo(null)}
          refreshViewList={refreshList}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'DEFECT' &&
        <DefectUpdateDialog
          mode={'detail'}
          close={() => setCurrentInfo(null)}
          currentId={_.get(currentInfo, 'id')}
          refreshList={refreshList}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'contrastFile' &&
        <ContrastEditFile
          close={() => setCurrentInfo(null)}
          {...{ versionList }}
        />
      }
      {
        _.includes(['contrastScript', 'comparisonProfile'], _.get(currentInfo, 'mode')) &&
        <ContrastDifference
          {...{ versionList }}
          type={_.get(currentInfo, 'mode') === 'contrastScript' ? '1' : '0'}
          close={() => setCurrentInfo(null)}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'regressionVersion' &&
        <ImportRegressionVersion
          {...{ id, projectId, versionList, refreshList }}
          initId={_.map(_.filter(allList, o => _.includes(['ISSUE', 'REQ'], o.taskType)), 'id')}
          close={() => setCurrentInfo(null)}
        />
      }
      {
        _.includes(['reqExcel', 'issueExcel'], _.get(currentInfo, 'mode')) &&
        <ImportApiDialog
          title=''
          parameter={{
            id,
            projectId,
            type: 'DEV',
          }}
          isExecutionList={false}
          close={() => setCurrentInfo(null)}
          importUrl={'reqExcel' === _.get(currentInfo, 'mode') ? '/ReleaseStory/importStory' : '/ReleaseStory/importIssue'}
          template={'reqExcel' === _.get(currentInfo, 'mode') ? '/field/download?flag=10' : '/field/download?flag=11'}
          refresh={refreshList}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'innerBoard' &&
        <InnerVersionBoardDialog
          {...{ id, projectId, selectedVersion }}
          close={() => setCurrentInfo(null)}
        />
      }
    </div>
  )
}

function RelevancyOperations(props) {
  const { isPo, isPMM, module, isRelevance, selectedVersion, selectedVersionItem, setCurrentInfo, isImportReq, selection } = props
  const [clicked, setClicked] = useState(false)

  const onClick = useCallback((mode) => {
    setCurrentInfo({ mode })
    setClicked(false)
  }, [setCurrentInfo])

  const canRelateVersion = useMemo(() => {
    if (_.isNil(selectedVersionItem)) return true
    return !_.includes(['R', 'L'], selectedVersionItem.state)
  }, [selectedVersionItem])

  const Content = useCallback(() => {
    const showRelate = module === 'baseLineDefect' || (module === 'fixDefect' && (selectedVersion === 'no-plan-version' || _.isNil(selectedVersion)))
    return <div className='allConditions-popover'>
      {
        (isPo || isPMM) && isRelevance &&
        <>
          <div onClick={() => onClick('regressionVersion')}>回归版本导入</div>
          <div className={isImportReq ? 'item-operation-disabled' : ''} onClick={() => {
            if (isImportReq) return
            onClick('relevancyReq')
          }}>导入需求</div>
          <div className={isImportReq ? 'item-operation-disabled' : ''} onClick={() => {
            if (isImportReq) return
            onClick('reqExcel')
          }}>导入需求（EXCEL）</div>
        </>
      }
      {
        isRelevance &&
        <>
          <div onClick={() => onClick('relevancyIssue')}>导入ISSUE</div>
          <div onClick={() => onClick('issueExcel')}>导入ISSUE（EXCEL）</div>
        </>
      }
      {
        canRelateVersion && showRelate &&
        <div onClick={() => onClick('relevancyDefect')}>导入基线缺陷</div>
      }
      {
        <div onClick={() => onClick('relevancyPlannedProject')}>从预规划项目导入</div>
      }
      {
        canRelateVersion && showRelate &&
        <div onClick={() => {
          if (_.isEmpty(selection)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
          onClick('cancelProjectRelation')
        }}>移除缺陷</div>
      }
    </div>
  }, [isPo, isPMM, module, isRelevance, selectedVersion, canRelateVersion, onClick, isImportReq, selection])

  return <Popover
    trigger="click"
    open={clicked}
    placement='bottomLeft'
    content={<Content />}
    onOpenChange={(visible) => setClicked(visible)}
    overlayClassName='popoverMore'
  >
    <TextIconBtn icon='' text='规划项目' onClick={() => setClicked(x => !x)} />
  </Popover>
}

function ContrastDifferenceOperations(props) {
  const { setCurrentInfo } = props
  const [clicked, setClicked] = useState(false)

  const onClick = useCallback((mode) => {
    setCurrentInfo({ mode })
    setClicked(false)
  }, [setCurrentInfo])

  const Content = useCallback(() => {
    return <div className='allConditions-popover'>
      <div onClick={() => onClick('contrastFile')}>对比修改文件</div>
      <div onClick={() => onClick('contrastScript')}>对比修改脚本</div>
      <div onClick={() => onClick('comparisonProfile')}>对比配置文件</div>
    </div>
  }, [onClick])

  return <Popover
    trigger="click"
    open={clicked}
    placement='bottomLeft'
    content={<Content />}
  >
    <TextIconBtn icon='' text='对比差异' onClick={() => setClicked(x => !x)} />
  </Popover>
}