const issueOptions = [
  {
    "header": "分类标记",
    "bind": "ISSUESTYPE"
  },
  {
    "header": "问题分析人",
    "bind": "Principal"
  },
  {
    "header": "严重程度",
    "bind": "EffectGrade"
  },
  {
    "header": "是否影响交易",
    "bind": "TimeRange"
  },
  {
    "header": "版本编号",
    "bind": "VersionId"
  },
  {
    "header": "外挂版本",
    "bind": "exConn_ver"
  },
  {
    "header": "关联issue",
    "bind": "relatedIssue"
  },
  {
    "header": "客服人员",
    "bind": "SupportPerson"
  },
  {
    "header": "客户问题编号",
    "bind": "clientIssueNo"
  },
  {
    "header": "问题简单描述",
    "bind": "ShortDesc"
  },
  {
    "header": "分析与方案",
    "bind": "WhyAndHow"
  },
  {
    "header": "解决具体版本",
    "bind": "NewVersion"
  },
  {
    "header": "系统",
    "bind": "CustId"
  },
  {
    "header": "客户名称",
    "bind": "Customer"
  },
  {
    "header": "对接券商",
    "bind": "extConntoBrokers"
  },
  // {
  //   "header": "研发任务发布版本",
  //   "bind": "releaseid"
  // },
  {
    "header": "子产品",
    "bind": "prodsubsysid"
  },
  {
    "header": "解决时间要求",
    "bind": "PromiseDate"
  },
  {
    "header": "问题在新版本已解决",
    "bind": "Resolved"
  },
  {
    "header": "问题负责人",
    "bind": "PersonCharge"
  },
  // {
  //   "header": "引发问题研发任务",
  //   "bind": "sSourceTracer"
  // },
  {
    "header": "问题编号",
    "bind": "IssueId"
  },
  {
    "header": "发生日期",
    "bind": "OccurDate"
  },
  {
    "header": "问题性质",
    "bind": "IssueKind"
  },
  {
    "header": "问题状态",
    "bind": "IssueStatus"
  },
  {
    "header": "问题影响",
    "bind": "Influence"
  },
  {
    "header": "问题详细描述",
    "bind": "Impact"
  },
  {
    "header": "问题发生环节",
    "bind": "BizProcess"
  },
  {
    "header": "客户报告Email",
    "bind": "ReporterEmail"
  },
  {
    "header": "客户问题报告人",
    "bind": "Reporter"
  },
  {
    "header": "客户沟通记录",
    "bind": "CommunicateNote"
  },
  {
    "header": "投诉/索赔标识",
    "bind": "ClaimFlag"
  },
  {
    "header": "客户报告人其他信息",
    "bind": "ReporterDossier"
  },
  {
    "header": "产品名称",
    "bind": "productName"
  },
  {
    "header": "issue问题性质细分",
    "bind": "IssueKindDetail"
  },
  // {
  //   "header": "发生频率",
  //   "bind": "OccurFrequency"
  // },
  {
    "header": "创建人",
    "bind": "InputUser"
  },
  {
    "header": "创建时间",
    "bind": "InputDate"
  },
  {
    "header": "修复研发任务ID",
    "bind": "TracerId"
  },
  {
    "header": "修复研发任务标题",
    "bind": "tracerTitle"
  },
  {
    "header": "修复研发任务所在版本",
    "bind": "releaseName"
  },
  {
    "header": "修复版本计划发布时间",
    "bind": "issueDate"
  },
  // {
  //   "header": "认定人",
  //   "bind": "affirmPlayers"
  // },
  {
    "header": "修复版本时间发布时间",
    "bind": "RealReleaseDate"
  },
]

export default issueOptions