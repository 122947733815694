import React, { useState, useMemo, useCallback, useEffect, useContext } from 'react'
import _ from 'lodash'
import { Messager } from 'rootnet-ui'
import { Popover, Checkbox, Badge, Table } from 'antd'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import JoinApply from './joinApply'
import Options from './options'
import { strParams } from '../../../../../../utils/publicFun'
import { ScheduleManagementContext } from '../../../../../common/Context'
import { selectOption } from '../../../../../common/commonMethod'
import { CheckListExport, InventoryExport, ModifyFileExport, ScriptExport } from './exportLog'
import gd from '../../../../../../base/global'
import RelevanceReq from './relevanceReq/relevanceReq'
import RelevanceIssue from './relevanceIssue/relevanceIssue'
import DelMessage from './delMessage'
import CopyMessage from './copyMessage'
import RequirementDetailDialog from "../../../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog";
import TrackingDetailDialog from "../../../../../trackingManagementMgt/components/trackingDetailDialog";
import IssueDetailDialog from "../../../../../issueMgt/components/issueDetailDialog/IssueDetailDialog";
import ShuttleColumn from './shuttleColumn'
import DefectUpdateDialog from '../../../../../testMgt/defect/controls/DefectUpdateDialog'
import RegressionMessage from './regressionMessage'
import DevListDetailDialog from '../../../../../devListMgt/devListDetailDialog'
import { getColumns } from '../components/permission'
import './index.scss'
import ImportFromVersion from "../../importFromVersion/ImportFromVersion";
import ImportApiDialog from '../../../../../common/ImportApiDialog'
import RelevanceHistoryDefect from './relevanceHistoryDefect'
import { isNil } from '../../../../../appraise/components/method'

const CheckboxGroup = Checkbox.Group;

const JOIN_APPLY_URL = '/releaseStory/story/log'
const REQ_LIST_URL = '/releaseStory/list'
const EXPORT_INVENTORY_LIST_URL = '/releaseStory/list/export'
const OPERATION_URL = '/ReleaseStory/addReleaseStoryList'
const EXPORT_REQ_MATRIX_URL = '/releaseStory/storyMatrix/export' //导出需求矩阵表
const ALL_COLUMNS_URL = '/releaseStory/getCustomShowField' //所有列表
const EXPORT_MEETING_LIST_URL = '/releaseStory/ratMeetingList/export'
const EDIT_URL = '/ReleaseStory/update'

const OPTIONS_URLS = [
  '/viewCommon/getProductInfo', // 产品
  '/common/globalconst?globalConst=ProductLine', // 产品
  '/verQuery/queryCustOptions', // 客户系统
]

const plainOptions = [
  { label: '父需求', value: 'FREQ' },
  { label: '需求', value: 'REQ' },
  { label: 'ISSUE', value: 'ISSUE' },
  { label: '开发自提单', value: 'DEV' },
  { label: '历史缺陷', value: 'DEFECT' },
  { label: '任务', value: 'TASK' },
]

const optValues = _.map(plainOptions, o => o.value)

export default function VersionRequirementMgt(props) {
  const { currentId, apply = false, isReqCode, allUserRes, mulcustId, principal, isProjectCode, versionOpt, isDisabled,
    poUser, updateInventoryDynamic, reqDelAuth, isFuncCodeApply, isFuncCodeQaCheck, storyTypeOptions, workflowOpt,
    priorityOpt, yseOrNoOpt, snowId, isVersionYGH, projectId, isImportDefect } = props
  const [joinApply, setJoinApply] = useState(apply)
  const [typeChange, setTypeChange] = useState(optValues)
  const [typeNum, setTypeNum] = useState({})
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [expanded, setExpanded] = useState(true)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [params, setParams] = useState({ storyId: '' })
  const [exportType, setExportType] = useState()
  const [actionType, setActionType] = useState()
  const [changeData, setChangeData] = useState({})
  const [editInfo, setEditInfo] = useState()
  const [untreatedNum, setUntreatedNum] = useState(0)
  const { data: listRes, doFetch, loading } = useGet()
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { downloadGetProgress } = useContext(ScheduleManagementContext)
  const { doFetch: operationFetch } = usePost()
  const [showDetail, setShowDetail] = useState()
  const [totalWorkload, setTotalWorkload] = useState(0)
  const [openColumn, setOpenColumn] = useState(false)
  const { data: allColumns } = usePost(ALL_COLUMNS_URL)

  const { data, doFetch: getList } = useGet()
  const [searchNum, setSearchNum] = useState()

  const isPlanning = useMemo(() => {
    return (isReqCode || isProjectCode || gd.User.operator_id === principal || gd.User.operator_id === poUser)
  }, [isReqCode, isProjectCode, principal, poUser])

  useEffect(() => {
    setExpanded(true)
    setSelectedRows([])
    setExpandedRowKeys([])
    setSelectedRowKeys([])
    setParams(x => _.assign({}, ..._.map(x, (v, k) => ({ [k]: null })), !_.isNil(x?.updateTime) && { updateTime: { begin: null, end: null } }))
  }, [currentId])

  const refresh = useCallback(() => {
    getList(`${JOIN_APPLY_URL}?auditType=1&releaseStatus=C&releaseId=${currentId}`)
      .then(res => {
        setUntreatedNum(_.size(_.filter(res, o => o.auditFlag === 'U')))
      })
  }, [currentId, getList])

  useEffect(() => {
    refresh()
  }, [refresh])

  const [productTreeOptions, productOptions, customerSystemOpt] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2, d3] = optionsRes
    const productTreeOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
      text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
      value: productLine,
      _disabled: true,
      children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
    }))
    return [
      productTreeOptions,
      _.map(d1, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}`, productLine: x.productLine, independentVerFlag: x.independentVerFlag })),
      selectOption(d3, ['projectName', 'projectId']),
    ]
  }, [optionsRes])

  const dataSource = useMemo(() => {
    if (_.isEmpty(listRes)) return []
    return filterType(_.get(listRes, 'treeNodes', []), typeChange, currentId)
  }, [listRes, typeChange, currentId])

  const listExport = useCallback(() => {
    if (_.isNil(currentId)) return
    const time = _.isNil(params?.updateTime) ? {} : { beginDate: params.updateTime.begin, endDate: params.updateTime.end }
    const typeList = _.join(typeChange, ',')
    const par = _.assign({}, _.omit(params, ['updateTime']), { releaseId: currentId, typeList }, time)
    const parameter = isNil(params?.storyId) ? `${strParams(par)}&storyId=` : strParams(par)
    downloadGetProgress(`${EXPORT_INVENTORY_LIST_URL}?${parameter}`, '导出列表', () => { })
  }, [currentId, downloadGetProgress, params, typeChange])

  const exportDemandMatrixList = useCallback(() => {
    if (_.isNil(currentId)) return
    downloadGetProgress(`${EXPORT_REQ_MATRIX_URL}?${strParams({ releaseId: currentId })}`, '导出需求矩阵列表', () => { })
  }, [currentId, downloadGetProgress])

  const exportMeetingList = useCallback(() => {
    if (_.isNil(currentId)) return
    downloadGetProgress(`${EXPORT_MEETING_LIST_URL}?${strParams({ releaseId: currentId })}`, '导出RAT会议清单', () => { })
  }, [currentId, downloadGetProgress])

  const search = useCallback((par = { storyId: '' }) => {
    if (_.isNil(currentId)) return
    setSearchNum(_.size(_.keys(par)))
    const time = _.isNil(par?.updateTime) ? {} : { beginDate: par.updateTime.begin, endDate: par.updateTime.end }
    const pars = _.assign({}, _.omit(par, ['updateTime']), { releaseId: currentId }, time)
    doFetch(`${REQ_LIST_URL}?${strParams(pars)}`)
  }, [currentId, doFetch])

  const editChange = useCallback(({ newVal, oldVal, id }) => {
    if (newVal === oldVal || _.isNil(id)) { setEditInfo(null); return }
    const postParams = [
      {
        releaseid: currentId,
        storyid: id,
        regression: newVal,
      }
    ]
    operationFetch(EDIT_URL, postParams)
      .then(() => {
        updateInventoryDynamic()
        search(params)
        setEditInfo(null)
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [operationFetch, currentId, search, params, updateInventoryDynamic])

  const dataManipulation = useCallback((par, flag = true) => {
    //releaseid: currentId, type, optionType: mode, storyidList
    operationFetch(OPERATION_URL, par)
      .then(() => {
        updateInventoryDynamic()
        flag && search(params)
        flag && setChangeData({})
        if (_.includes(['delValues', 'copyValues'], actionType)) {
          setSelectedRowKeys([])
          setSelectedRows([])
        }

        Messager.show('成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [actionType, operationFetch, params, search, updateInventoryDynamic])

  const reqList = useMemo(() => {
    return getReqList(_.get(listRes, 'treeNodes', []))
  }, [listRes])

  const issueList = useMemo(() => {
    return getIssueList(_.get(listRes, 'treeNodes', []))
  }, [listRes])

  const columns = useMemo(() => {
    return getColumns({
      productOptions, customerSystemOpt, storyTypeOptions, allUserRes, setActionType,
      setChangeData, isDisabled, expanded, setExpanded, setShowDetail, workflowOpt, priorityOpt, yseOrNoOpt, editInfo, setEditInfo, editChange, isPlanning,
      allColumns, customField: _.get(listRes, 'customField', ''), isWrap: _.get(listRes, 'isWrap', false)
    })
  }, [allUserRes, customerSystemOpt, expanded, isDisabled, productOptions, storyTypeOptions, workflowOpt, listRes, allColumns, priorityOpt, yseOrNoOpt, editInfo, editChange, isPlanning])

  useEffect(() => {
    if (_.isEmpty(listRes)) return
    const tileList = tileData(parentReq(_.get(listRes, 'treeNodes', [])))
    const groupData = _.groupBy(tileList, 'type')
    const numObj = _.assign({}, ..._.map(plainOptions, o => ({ [o.value]: _.size(_.get(groupData, o.value, [])) })))
    setTypeNum(numObj)
  }, [listRes])

  useEffect(() => {
    search()
  }, [currentId, doFetch, search])

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows)
      setSelectedRowKeys(selectedRowKeys)
    },
  };

  useEffect(() => {
    if (actionType === 'delValues' || actionType === 'copyValues' || actionType === 'regressionValues') {
      if (_.size(selectedRows) === 0) {
        setActionType(null)
        Messager.show('请选择数据后再进行操作', { icon: 'error' })
      }
    }
  }, [actionType, selectedRows])

  const RightFun = useCallback(() => {
    const getNumOpt = _.map(plainOptions, o => {
      return _.assign({}, o, {
        label: o.value === 'FREQ' ? o.label : <span style={{ color: '#8A8888' }}>{`${o.label}：${_.get(typeNum, o.value, 0)}`}</span>
      })
    })
    return <div className='flex req-fun'>
      <div className='type-num'>
        <span>总工作量：{totalWorkload}</span>
      </div>
      <div className='type-change'>
        <CheckboxGroup options={getNumOpt} value={typeChange} onChange={v => {
          const filterList = _.filter(v, k => _.includes(['REQ', 'FREQ'], k))
          if (_.size(filterList) === 1 && _.includes(filterList, 'FREQ')) setTypeChange(x => {
            return _.includes(x, 'REQ') ? _.filter(v, k => k !== 'FREQ') : _.concat(v, ['REQ'])
          })
          else setTypeChange(v)
        }} />
      </div>
      {
        (isReqCode || gd.User.operator_id === principal || gd.User.operator_id === poUser) &&
        <Badge count={untreatedNum} size="small" offset={[8, 0]}>
          <TextIconBtn icon='' text='版本变动申请' onClick={() => setJoinApply(true)} />
        </Badge>
      }
      <MoreOperations {...{ setExportType, listExport, exportDemandMatrixList, exportMeetingList }} />
      {
        isPlanning &&
        <ReleasePlanning setActionType={setActionType} isDisabled={isDisabled} isVersionYGH={isVersionYGH} isImportDefect={isImportDefect} />
      }
      <TextIconBtn icon='shezhi' text='' onClick={() => setOpenColumn(true)} />
    </div>
  }, [exportDemandMatrixList, exportMeetingList, isDisabled, listExport, totalWorkload, typeChange, typeNum, untreatedNum, isPlanning, isReqCode, principal, poUser, isVersionYGH, isImportDefect])

  useEffect(() => {
    if (_.isEmpty(listRes)) return
    let filterData = parentReq(_.get(listRes, 'treeNodes', []))
    const tileList = tileData(filterData)
    filterData = tileList
    filterData = _.filter(filterData, o => _.includes(['REQ', 'ISSUE'], o.type))
    const sumWorkload = _.sum(_.map(filterData, o => _.toNumber(o.workload)))
    const sum = parseInt(sumWorkload * 100)
    setTotalWorkload(sum / 100)
  }, [listRes])

  useEffect(() => {
    if (_.isEmpty(dataSource)) return
    if (expanded) setExpandedRowKeys(getKey(dataSource))
    else setExpandedRowKeys([])
  }, [dataSource, expanded])

  return (
    <div className='version-requirement-mgt flex-y'>
      <div className='header'>
        <Options {...{ searchNum, params, setParams, search, productTreeOptions, customerSystemOpt, storyTypeOptions, workflowOpt, yseOrNoOpt, RightFun }} />
      </div>
      <div className='req-table'>
        <Table
          bordered
          size='small'
          rowKey={record => {
            if (record.type === 'TASK') return record.pid + record.storyId
            return record.storyId
          }}
          loading={loading}
          dataSource={dataSource}
          columns={columns}
          rowSelection={{ ...rowSelection, checkStrictly: false, selectedRowKeys }}
          pagination={false}
          scroll={{ y: 9999999, x: 1300 }}
          expandedRowKeys={expandedRowKeys}
          onExpand={(bool, row) => {
            if (bool) setExpandedRowKeys(x => [...x, row.storyId])
            else {
              const index = expandedRowKeys.findIndex((e) => e === row.storyId)
              const newArray = [...expandedRowKeys]
              newArray.splice(index, 1)
              setExpandedRowKeys(newArray)
            }
          }}
        />
      </div>
      {joinApply && !_.isEmpty(allUserRes) && <JoinApply {...{ refresh, allUserRes, data, refreshHome: () => search(params) }} close={() => setJoinApply(false)} />}
      {exportType === 'releaseNote' && <InventoryExport {...{ currentId, mulcustId, projectId }} close={() => setExportType(null)} />}
      {exportType === 'script' && <ScriptExport {...{ currentId, mulcustId }} close={() => setExportType(null)} />}
      {exportType === 'modifyFile' && <ModifyFileExport {...{ currentId }} close={() => setExportType(null)} />}
      {exportType === 'checklist' && <CheckListExport {...{ currentId, mulcustId }} close={() => setExportType(null)} />}
      <RelevanceReq
        appendObj={{ factorViewSql: `(View_ReleaseInfo_story_project.LeafProjectId = '${projectId}')` }}
        open={actionType === 'REQ'}
        textHint='只允许导入版本所属项目下的需求'
        loading={loading}
        data={reqList}
        releaseid={currentId}
        close={() => setActionType(null)}
        refresh={() => {
          search(params)
          updateInventoryDynamic()
        }}
      />
      <RelevanceIssue
        appendObj={{ factorViewSql: `(View_Release_Story.projectId = '${projectId}' or ISNULL(issue_devProject.userProjectId,'') = '${projectId}')` }}
        open={actionType === 'ISSUE'}
        textHint='只允许导入版本所属项目下的ISSUE'
        loading={loading}
        data={issueList}
        releaseid={currentId}
        close={() => setActionType(null)}
        refresh={() => {
          search(params)
          updateInventoryDynamic()
        }}
      />
      <RelevanceHistoryDefect
        open={actionType === 'historyDefect'}
        loading={loading}
        releaseId={currentId}
        projectId={projectId}
        close={() => setActionType(null)}
        refresh={() => {
          search(params)
          updateInventoryDynamic()
        }}
      />
      {
        (actionType === 'copy' || (actionType === 'copyValues' && _.size(selectedRows) > 0)) &&
        <CopyMessage
          {...{ projectId }}
          yseOrNoOpt={yseOrNoOpt}
          selectedRows={selectedRows}
          actionType={actionType}
          changeData={changeData}
          versionOpt={versionOpt}
          currentId={currentId}
          isDisabled={isDisabled}
          dataManipulation={dataManipulation}
          close={() => setActionType(null)}
        />
      }
      {
        (actionType === 'del' || (actionType === 'delValues' && _.size(selectedRows) > 0)) &&
        <DelMessage
          header={
            <span className='DelMessage-lz'>
              删除
              <span>（已选{actionType === 'del' ? _.size(changeData.storyidList) : _.size(selectedRows)}个）</span>
            </span>
          }
          close={() => setActionType(null)}
          currentId={currentId}
          actionType={actionType}
          changeData={changeData}
          selectedRows={selectedRows}
          dataManipulation={dataManipulation}
        />
      }
      {
        ((actionType === 'regressionValues' && _.size(selectedRows) > 0)) &&
        <RegressionMessage
          yseOrNoOpt={yseOrNoOpt}
          close={() => setActionType(null)}
          selectedRows={selectedRows}
          currentId={currentId}
          refresh={() => {
            updateInventoryDynamic()
            search(params)
          }}
        />
      }
      {
        actionType === 'version' &&
        <ImportFromVersion close={() => setActionType(null)}
          refreshList={() => {
            refresh()
            search(params)
          }}
          {...{ currentId, snowId, projectId }} />
      }
      {
        actionType === 'planVersion' &&
        <ImportFromVersion close={() => setActionType(null)}
          versionType={false}
          refreshList={() => {
            refresh()
            search(params)
          }}
          appendObj={{ versionType: '1' }}
          {...{ currentId, snowId }} />
      }
      {
        openColumn && <ShuttleColumn
          {...{ allColumns, customField: _.get(listRes, 'customField', ''), isWrap: _.get(listRes, 'isWrap', false) }}
          refresh={() => {
            refresh()
            search(params)
            setSelectedRowKeys([])
            setSelectedRows([])
          }}
          close={() => setOpenColumn(false)}
        />
      }
      {
        _.get(showDetail, 'type') === 'REQ' &&
        <RequirementDetailDialog close={() => setShowDetail(null)}
          refreshViewList={() => {
            refresh()
            search(params)
          }}
          currentInfo={{ id: _.get(showDetail, 'id') }} showChildList={[]}
          delAuth={reqDelAuth} storyTypeOptions={storyTypeOptions} />
      }
      {
        _.get(showDetail, 'type') === 'DEV' &&
        <DevListDetailDialog
          close={() => setShowDetail(null)}
          refreshViewList={() => {
            refresh()
            search(params)
          }}
          currentInfo={{ id: _.get(showDetail, 'id') }} showChildList={[]}
          delAuth={reqDelAuth} storyTypeOptions={storyTypeOptions}
        />
      }
      {
        _.get(showDetail, 'type') === 'TASK' &&
        <TrackingDetailDialog close={() => setShowDetail(null)}
          refreshViewList={() => {
            refresh()
            search(params)
          }}
          currentInfo={{ id: _.get(showDetail, 'id') }} showChildList={[]} />
      }
      {
        _.get(showDetail, 'type') === 'ISSUE' &&
        <IssueDetailDialog close={() => setShowDetail(null)}
          refreshViewList={() => {
            refresh()
            search(params)
          }}
          currentInfo={{ id: _.get(showDetail, 'id') }} showChildList={[]}
          {...{ isFuncCodeApply, isFuncCodeQaCheck }} />
      }
      {
        _.get(showDetail, 'type') === 'DEFECT' &&
        <DefectUpdateDialog
          mode={'detail'}
          close={() => setShowDetail(null)}
          currentId={_.get(showDetail, 'id')}
          refreshList={() => {
            refresh()
            search(params)
          }}
        />
      }
      {
        _.includes(['reqExcel', 'issueExcel'], actionType) &&
        <ImportApiDialog
          title=''
          parameter={{
            releaseId: currentId,
            type: 'reqExcel' === actionType ? 'REQ' : 'ISSUE',
          }}
          isExecutionList={false}
          close={() => setActionType(null)}
          importUrl={'reqExcel' === actionType ? '/ReleaseStory/importStory' : '/ReleaseStory/importIssue'}
          template={'reqExcel' === actionType ? '/field/download?flag=10' : '/field/download?flag=11'}
          refresh={() => {
            refresh()
            search(params)
          }}
        />
      }
      {
        actionType === 'historyDefectExcel' &&
        <ImportApiDialog
          title='导入历史缺陷excel'
          parameter={{
            releaseId: currentId,
          }}
          isExecutionList={false}
          close={() => setActionType(null)}
          TEMPLATE_URL={'/field/download?flag=12'}
          importUrl={'/testDefect/addReleaseTestDefectExcel'}
          refresh={() => {
            refresh()
            search(params)
          }}
        />
      }
    </div>
  )
}

function filterType(data, condition, currentId) {
  let filterList = _.cloneDeep(data)
  const tileList = tileData(filterList)
  filterList = tileList
  if (!_.includes(condition, 'FREQ')) filterList = _.filter(filterList, o => (!(o.type === 'REQ' && !_.includes(o.releaseIds, currentId))))
  if (!_.includes(condition, 'REQ')) filterList = _.filter(filterList, o => (o.type !== 'REQ'))
  if (!_.includes(condition, 'ISSUE')) filterList = _.filter(filterList, o => o.type !== 'ISSUE')
  if (!_.includes(condition, 'DEFECT')) filterList = _.filter(filterList, o => o.type !== 'DEFECT')
  if (!_.includes(condition, 'TASK')) filterList = _.filter(filterList, o => o.type !== 'TASK')
  if (!_.includes(condition, 'DEV')) filterList = _.filter(filterList, o => o.type !== 'DEV')
  if (_.size(condition) === 1 && _.includes(condition, 'TASK')) {
    const obj = {}
    _.forEach(filterList, o => {
      if (o.type === 'TASK') obj[o.storyId] = o
    })
    filterList = _.values(obj)
  }
  return treeData(filterList)
}

function parentReq(data, arr = []) {
  _.forEach(data, o => {
    if (_.isEmpty(o.subStoryList)) arr.push(o)
    else {
      if (_.includes(_.map(o.subStoryList, x => x.type), 'REQ')) parentReq(o.subStoryList, arr)
      else arr.push(o)
    }
  })
  return arr
}

function getReqList(data) {
  let filterList = tileData(data)
  filterList = _.filter(filterList, o => (o.type === 'REQ' || o.type === 'DEV'))
  return _.map(filterList, item => (_.assign({
    interiorReqNo: item.storyId,
  }, _.pick(item, ['title', 'id']))))
}

function getIssueList(data) {
  let filterList = tileData(data)
  filterList = _.filter(filterList, o => o.type === 'ISSUE')
  return _.map(filterList, item => ({
    id: item.id,
    issueId: item.storyId,
    shortDesc: item.title,
  }))
}

function treeData(data) {
  _.forEach(data, o => { delete o.children })
  let map = {}
  _.forEach(data, o => {
    if (o.type === 'TASK') {
      map[o.storyId + o.pid] = o
    } else map[o.id] = o
  })

  let val = [];
  _.forEach(data, o => {
    let parent = map[o.pid];
    if (parent) ((parent.children) || (parent.children = [])).push(o);
    else val.push(o);
  })

  return val;
}

function tileData(data, arr = []) {
  _.forEach(data, o => {
    arr.push(o)
    if (!_.isEmpty(o.subStoryList)) tileData(o.subStoryList, arr)
  })
  return arr
}

function ReleasePlanning({ setActionType, isDisabled, isVersionYGH, isImportDefect }) {
  const [clicked, setClicked] = useState(false)
  const className = useMemo(() => isDisabled ? 'item-operation-disabled' : '', [isDisabled])
  const defectClassName = useMemo(() => isImportDefect ? '' : 'item-operation-disabled', [isImportDefect])
  const onClick = useCallback((mode) => {
    setActionType(mode)
    setClicked(false)
  }, [setActionType])
  const Content = useCallback(() => {
    return <div className='allConditions-popover'>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('reqExcel')}>导入需求（EXCEL）</div>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('issueExcel')}>导入ISSUE（EXCEL）</div>
      <div className={defectClassName} onClick={() => isImportDefect ? onClick('historyDefectExcel') : () => { }}>导入历史缺陷（EXCEL）</div>
      <div className={defectClassName} onClick={() => isImportDefect ? onClick('historyDefect') : () => { }}>导入历史缺陷</div>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('REQ')}>导入需求/自提单</div>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('ISSUE')}>导入ISSUE</div>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('version')}>从版本回归</div>
      {isVersionYGH && <div className={className} onClick={() => isDisabled ? () => { } : onClick('planVersion')}>从预规划项目导入</div>}
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('delValues')}>从该版本中移除</div>
      <div onClick={() => onClick('copyValues')}>复制至其他版本</div>
      <div onClick={() => onClick('regressionValues')}>编辑回归标识</div>
    </div>
  }, [className, isDisabled, onClick, isVersionYGH, isImportDefect, defectClassName])

  return (
    <Popover
      trigger="click"
      open={clicked}
      placement='bottomLeft'
      content={<Content />}
      onOpenChange={(visible) => setClicked(visible)}
      overlayClassName='popoverMore'
    >
      <TextIconBtn icon='' text='版本规划' onClick={() => setClicked(x => !x)} />
    </Popover>
  )
}

function MoreOperations({ setExportType, listExport, exportDemandMatrixList, exportMeetingList }) {
  const [clicked, setClicked] = useState(false)

  const onClick = useCallback((mode) => {
    setExportType(mode)
    setClicked(false)
  }, [setExportType])

  const Content = useCallback(() => {
    return <div className='allConditions-popover'>
      <div onClick={listExport}>导出列表</div>
      <div onClick={() => onClick('releaseNote')}>导出releaseNote</div>
      <div onClick={() => onClick('script')}>导出脚本</div>
      <div onClick={() => onClick('modifyFile')}>导出修改文件</div>
      <div onClick={() => onClick('checklist')}>导出checklist</div>
      <div onClick={exportDemandMatrixList}>导出需求矩阵列表</div>
      <div onClick={exportMeetingList}>导出RAT会议清单</div>
    </div>
  }, [exportDemandMatrixList, listExport, onClick, exportMeetingList])

  return (
    <Popover
      trigger="click"
      open={clicked}
      placement='bottomLeft'
      content={<Content />}
      onOpenChange={(visible) => setClicked(visible)}
      overlayClassName='popoverMore'
    >
      <TextIconBtn icon='' text='导出' onClick={() => setClicked(x => !x)} />
    </Popover>
  )

}

function getKey(data, arr = [], id = 'storyId') {
  _.forEach(data, o => {
    // if (o.type === 'TASK') return
    arr.push(o[id])
    if (!_.isEmpty(o.children)) getKey(o.children, arr, id)
  })
  return arr
}