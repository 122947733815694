import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import _ from 'lodash'
import { isNil } from 'rootnet-core/format'
import { Dialog, Messager } from 'rootnet-ui'
import { toDate } from 'rootnet-core/dateFormat'
import { Form, FormInput, Select, DatePicker, Display } from 'rootnet-edit'
import TinyEditor from "../../../common/richTextEditor/TinyEditor"
import { useApi, useGet } from '../../../../utils/hook'
import { selectOption } from '../../../common/commonMethod'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import UserSelect from "../../../common/personSelectPop/UserSelect";
import convertOptions from '../../../common/ConvertOptions'
import { Icon } from '../../../../components'
import { Tooltip } from 'antd'

const ADD_RUL = '/test/plan/add'
const EDIT_URL = '/test/plan/update'
const EDIT_ECHO_URL = '/test/plan/select'
const OPT_URL = [
  '/common/userinfo?indexValidFlag=1',
  '/common/department',
  '/common/globalconst?globalConst=testDefectOriginPhase',
  '/UserSetting/getUniversalInterfaces?code=ProjectID&codeName=ProjectName&tableName=UserProject&filter=status&filterParams=0,2,3,4,5,6', // 关联项目
  '/common/globalconst?globalConst=testplanStatus',
]

const VERSION_URL = '/test_case/tracerversionplanecho' // 关联版本


export default function DisplayForm(props) {
  const { modes, close, refresh, data, defaultParams = {} } = props
  const { timestampToDate } = toDate
  const richEditorRef = useRef()
  const { id: planId } = data || {}
  const [params, setParams] = useState(defaultParams)
  const [error, setError] = useState()
  const { doFetch } = useApi()
  const { data: optData, loading } = useGet(OPT_URL)
  const [submitLoading, setSubmitLoading] = useState(false)
  const { projectId, relatedReleaseId } = useMemo(() => params || {}, [params])
  const [initProjectId, setInitProjectId] = useState()
  const { data: versionRes, doFetch: getVersion } = useGet()

  const isAdd = useMemo(() => modes === 'add', [modes])
  const header = useMemo(() => isAdd ? '新建' : '编辑', [isAdd])

  useEffect(() => {
    if (_.isNil(projectId)) return
    getVersion(`${VERSION_URL}?projectId=${projectId}`)
  }, [getVersion, projectId])

  const markNoChildren = useCallback((list) => {
    if (list.length === 0) {
      return true
    }
    _.forEach(list, item => {
      if (item.type === 'user') return _.assign(item, { _noChildren: false })
      if (item.type === 'department') _.assign(item, { _noChildren: markNoChildren(item.children) })
      if (_.every(item.children, x => x._noChildren)) _.assign(item, { _noChildren: true, children: [] })
    })
    return false
  }, [])

  const delNoChildren = useCallback((item) => {
    if (_.every(item.children, x => x._noChildren)) _.assign(item, { children: [] })
    const filterList = _.filter(item.children, x => {
      return !x._noChildren
    })
    _.assign(item, { children: filterList })
    _.forEach(item.children, x => delNoChildren(x))
  }, [])

  const getDepartmentChildren = useCallback((allUserList, allDepartmentList, department) => {
    const userList = _.filter(allUserList, x => x.department === department)
    const userOptions = _.map(userList, x => ({ value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}`, type: 'user' }))
    const childDepartmentList = _.filter(allDepartmentList, x => x.parentDept === department)
    const childDepartmentOptions = _.map(childDepartmentList, x => ({
      text: x.departMentName,
      value: x.departMentId,
      type: 'department',
      _disabled: true,
      children: getDepartmentChildren(allUserList, allDepartmentList, x.departMentId),
    }))
    return [...childDepartmentOptions, ...userOptions]
  }, [])

  const [, stageOpt, projectOpt, statusOpt] = useMemo(() => {
    const [d1, d2, d3, d4, d5] = optData || []
    const activeDepartmentList = _.filter(d2, x => x.activeFlag === 'Y')
    let userAccountTree = getDepartmentChildren(d1, activeDepartmentList, '')
    markNoChildren(userAccountTree)
    _.forEach(userAccountTree, x => delNoChildren(x))
    userAccountTree = _.filter(userAccountTree, x => !x._noChildren)
    const stageOpt = convertGlobalConstOptions(_.filter(d3, o => o.displayFlag !== 'N'))
    const projectOpt = selectOption(d4, ['displayName', 'interiorId'])
    // const editionOpt = selectOption(d5, ['ReleaseName', 'ReleaseID'])
    const statusOpt = convertGlobalConstOptions(d5)
    return [userAccountTree, stageOpt, projectOpt, statusOpt]
  }, [delNoChildren, getDepartmentChildren, markNoChildren, optData])

  const editionOpt = useMemo(() => {
    if (_.isEmpty(versionRes)) return []
    return selectOption(versionRes, ['ReleaseName', 'ReleaseID'])
  }, [versionRes])

  const projectOptions = useMemo(() => {
    if (isNil(initProjectId)) return projectOpt
    const keys = _.map(projectOpt, 'value')
    if (_.includes(keys, initProjectId)) return projectOpt
    return _.concat(projectOpt, [{ text: initProjectId, value: initProjectId }])
  }, [initProjectId, projectOpt])

  const editionOptions = useMemo(() => {
    if (_.isNil(relatedReleaseId)) return editionOpt
    const keys = _.map(editionOpt, 'value')
    if (_.includes(keys, relatedReleaseId)) return editionOpt
    return _.concat(editionOpt, [{ text: relatedReleaseId, value: relatedReleaseId }])
  }, [relatedReleaseId, editionOpt])

  useEffect(() => {
    if (isAdd) setParams(x => _.assign({}, x, { status: '1' }))
    if (!isAdd) {
      doFetch(`${EDIT_ECHO_URL}?planId=${planId}`, 'get').then(res => {
        setInitProjectId(_.get(res, 'projectId'))
        setParams(_.assign({}, res, {
          planId,
          beginDate: timestampToDate(res.beginDate),
          endDate: timestampToDate(res.endDate),
        }))
        richEditorRef.current.setContent(_.isNull(res.describe) ? '' : res.describe)
      })
    }
  }, [doFetch, isAdd, planId, timestampToDate])

  const searchList = [
    {
      required: true,
      label: '名称',
      bind: 'planName',
    },
    {
      required: true,
      label: '负责人',
      bind: 'owner',
      component: UserSelect,
      componentWidth: 522
      // tree: true,
      // options: userAccountTree,
      // search: true,
    },
    [
      {
        label: '开始时间',
        bind: 'beginDate',
        labelWidth: 80,
        clear: true,
        component: DatePicker,
      },
      {
        label: '结束时间',
        bind: 'endDate',
        clear: true,
        component: DatePicker,
      },
    ],
    {
      required: true,
      label: '状态',
      bind: 'status',
      component: Select,
      options: statusOpt,
    },
    {
      required: isAdd,
      label: '测试阶段',
      bind: 'stage',
      component: isAdd ? Select : Display,
      options: stageOpt,
      convert: v => convertOptions(v, stageOpt),
      hint: '测试计划创建成功后，【测试阶段】不允许修改，当通过关联需求、issue、缺陷导入用例时，仅关联同测试阶段类型的计划'
    },
    {
      required: true,
      label: '关联项目',
      bind: 'projectId',
      component: Select,
      options: projectOptions,
      search: true,
    },
    {
      label: '关联版本',
      bind: 'relatedReleaseId',
      clear: true,
      component: Select,
      options: editionOptions,
      search: true,
    },
    {
      label: '描述',
      bind: 'describe',
    },
  ]

  return (
    <Dialog
      header={`${header}测试计划`}
      confirm={confirm}
      cancel={close}
      loading={loading || submitLoading}
      className='testPlan-dialog'
    >
      <Form onChange={handChange} value={params} error={error} onError={setError} >
        {
          _.map(searchList, (item, i) => {
            if (item instanceof Array) {
              return <div className='merge_row' key={i}>
                {
                  _.map(item, o => (<FormInput key={o.bind} horizontal {...o} />))
                }
              </div>
            } else if (item.bind === 'describe') {
              return <div key={item.bind} className="rich-text-area flex" style={{ minHeight: 300 }}>
                <label>{item.label}</label>
                <TinyEditor unique={'plan-update-dialog'} ref={richEditorRef} />
              </div>
            }
            return <div className='params-item' key={item.bind}>
              <FormInput labelWidth={80} horizontal {...item} componentWidth={522} />
              {
                !_.isNil(item.hint) &&
                <Tooltip title={item.hint}>
                  <span className='hint-tooltip' >
                    <Icon name='bangzhu' style={{ fontSize: 14, color: '#5477FF' }} />
                  </span>
                </Tooltip>
              }
            </div>
          })
        }
      </Form>
    </Dialog>
  )
  //componentWidth={445}
  function handChange(o, key) {
    if (key === 'projectId') {
      o['relatedReleaseId'] = null
    }
    if (key === 'beginDate') {
      if (o[key] > o['endDate'] && o['endDate'] !== null) {
        o[key] = ''
        Messager.show(`开始时间不能大于结束时间`, { icon: 'info' })
      }
    }
    if (key === 'endDate') {
      if (o[key] < o['beginDate'] && o['beginDate'] !== null) {
        o[key] = ''
        Messager.show(`结束时间不能小于开始时间`, { icon: 'info' })
      }
    }
    setParams(o)
  }

  function check() {
    return _.every(_.values(error), isNil)
  }

  function confirm() {
    if (!check()) return Messager.show('请填写必填项', { icon: 'error' })
    if (submitLoading) return
    setSubmitLoading(true)
    const url = isAdd ? ADD_RUL : EDIT_URL
    const beginDate = Date.parse(new Date(params?.beginDate))
    const endDate = Date.parse(new Date(params?.endDate))
    const parameter = _.assign({}, params,
      { describe: richEditorRef.current.getContent() ? richEditorRef.current.getContent() : '' },
      beginDate && { beginDate },
      endDate && { endDate },
    )
    doFetch(url, 'post', parameter)
      .then(() => {
        setSubmitLoading(false)
        refresh()
        close()
        Messager.show(`${header}成功`, { icon: 'success' })
      })
      .catch((err) => {
        close()
        setSubmitLoading(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }

}
